import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Dropdown, Menu } from 'antd';
import { CaretDown, List, UserCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

interface Props {
    type?: 'header' | 'other';
    items: any[];
    variant?: 'default' | 'sm';
    isloggedIn?: boolean; 
    userName?: string;
    changeModal?: () => void;
}

const Sidebar = (props: Props) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const location = useLocation();
    const { t } = useTranslation();

    const usernameInitials = props?.isloggedIn 
        ? (props.userName ? props.userName.split(' ').map(name => name.charAt(0).toUpperCase()).join('') : '') 
        : '';
    const { items = [], type = 'header' } = props || {};
        
    const menu = {
        items: [
          {
            key: '1',
            label: (<Menu items={items} />)
        }
        ]
    };

    const categoriesList = () => (
        <li>
            <div
                className={
                    location.pathname.split('/').includes('category')
                        ? 'categories-item active'
                        : 'categories-item'
                }
            >
                {t('categories')}
                <span className="caret-icon">
                    <CaretDown size={16} color="#595959" weight="bold" />
                </span>
            </div>
        </li>
    );
    const buttonType = () => (
        <button className="menu-btn" aria-label='header-user-nav-btn'>
            {/* Content for mobile */}
            <div className="mobile-view">
                <UserCircle size={20} color="#091731" />
                <div className="seperator"></div>
                <List size={20} />
            </div>

            {/* Content for desktop */}
            <div className="desktop-view">
                <UserCircle size={20} color="#091731" />
                <span className="default-bold-font text-uppercase">{usernameInitials || t('signIn')}</span>
            </div>
        </button>
    );
    const visbilityHandler = () => {
        if (window.scrollY >= 0) {
            setDropdownVisible(false);
        } else {
            setDropdownVisible(dropdownVisible);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', visbilityHandler);
        return () => window.removeEventListener('scroll', visbilityHandler);
    }, [dropdownVisible]);

    return (
        (items?.length == 1 && !props?.isloggedIn) ?
        <button className="menu-btn" aria-label='header-user-nav-btn'>
            <div className="desktop-view" onClick={props?.changeModal}>
                <UserCircle size={20} color="#091731" />
                <span className="default-bold-font text-uppercase">{t('signIn')}</span>
            </div>
        </button>
        :
        <div onClick={() => setDropdownVisible(!dropdownVisible)}>
            <Dropdown
                menu={menu}
                overlayClassName="login-dropdown"
                trigger={['click']}
                placement="bottomRight"
                open={dropdownVisible}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                onOpenChange={visbilityHandler}
            >
                {type === 'header' ? buttonType() : categoriesList()}
            </Dropdown>
        </div>
    );
};

export default Sidebar;
