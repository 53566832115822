import { ALL_LANGUAGES, ROUTES } from '../../constants';
import { getDefaultLang } from '../../utils/translation';

const getLocalizedRoute = (
    path: string,
    locale: string,
    defaultLocale = getDefaultLang()
) => {
    return locale === defaultLocale ? path : `/${locale}${path}`;
};

const routesEnglish = {
    [ROUTES.home]: getLocalizedRoute('/', 'en'),
    [ROUTES.slash]: getLocalizedRoute('/', 'en'),
    [ROUTES.buyCars]: getLocalizedRoute('/buy-cars', 'en'),
    [ROUTES.buy]: getLocalizedRoute('/buy', 'en'),
    [ROUTES.sellCar]: getLocalizedRoute('/sell-car', 'en'),
    [ROUTES.carFinance]: getLocalizedRoute('/car-finance', 'en'),
    [ROUTES.carInsurance]: getLocalizedRoute('/car-insurance', 'en'),
    [ROUTES.about]: getLocalizedRoute('/about', 'en'),
    [ROUTES.faqs]: getLocalizedRoute('/faqs', 'en'),
    [ROUTES.contactUs]: getLocalizedRoute('/contact-us', 'en'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'en'),
    [ROUTES.account]: getLocalizedRoute('/account', 'en'),
    [ROUTES.press]: getLocalizedRoute('/press', 'en'),

    [ROUTES.category]: getLocalizedRoute('/category', 'en'),

    [ROUTES.privacyPolicy]: getLocalizedRoute('/privacy-policy', 'en'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/terms-of-use', 'en'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/unsubscribe-blog', 'en'),
    [ROUTES.bodyType]: getLocalizedRoute('/body-type', 'en'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/buy-cars-under', 'en'),

    [ROUTES.editProfile]: ROUTES.editProfile,
    [ROUTES.sellYourCar]: ROUTES.sellYourCar
};

const routesFrench = {
    [ROUTES.home]: getLocalizedRoute('/', 'fr'),
    [ROUTES.slash]: getLocalizedRoute('/', 'fr'),
    [ROUTES.buyCars]: getLocalizedRoute('/buy-cars', 'fr'),
    [ROUTES.buy]: getLocalizedRoute('/buy', 'fr'),
    [ROUTES.sellCar]: getLocalizedRoute('/sell-car', 'fr'),
    [ROUTES.carFinance]: getLocalizedRoute('/car-finance', 'fr'),
    [ROUTES.carInsurance]: getLocalizedRoute('/car-insurance', 'fr'),
    [ROUTES.about]: getLocalizedRoute('/about', 'fr'),
    [ROUTES.faqs]: getLocalizedRoute('/faqs', 'fr'),
    [ROUTES.contactUs]: getLocalizedRoute('/contact-us', 'fr'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'fr'),
    [ROUTES.account]: getLocalizedRoute('/account', 'fr'),
    [ROUTES.press]: getLocalizedRoute('/press', 'fr'),

    [ROUTES.category]: getLocalizedRoute('/category', 'fr'),

    [ROUTES.privacyPolicy]: getLocalizedRoute('/privacy-policy', 'fr'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/terms-of-use', 'fr'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/unsubscribe-blog', 'fr'),
    [ROUTES.bodyType]: getLocalizedRoute('/body-type', 'fr'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/buy-cars-under', 'fr'),

    [ROUTES.editProfile]: '/edit-profile',
    [ROUTES.sellYourCar]: '/sell-your-car'
};

const routesArabic = {
    [ROUTES.home]: getLocalizedRoute('/', 'ar'),
    [ROUTES.slash]: getLocalizedRoute('/', 'ar'),
    [ROUTES.buyCars]: getLocalizedRoute('/buy-cars', 'ar'),
    [ROUTES.buy]: getLocalizedRoute('/buy', 'ar'),
    [ROUTES.sellCar]: getLocalizedRoute('/sell-car', 'ar'),
    [ROUTES.carFinance]: getLocalizedRoute('/car-finance', 'ar'),
    [ROUTES.carInsurance]: getLocalizedRoute('/car-insurance', 'ar'),
    [ROUTES.about]: getLocalizedRoute('/about', 'ar'),
    [ROUTES.faqs]: getLocalizedRoute('/faqs', 'ar'),
    [ROUTES.contactUs]: getLocalizedRoute('/contact-us', 'ar'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'ar'),
    [ROUTES.account]: getLocalizedRoute('/account', 'ar'),
    [ROUTES.press]: getLocalizedRoute('/press', 'ar'),

    [ROUTES.category]: getLocalizedRoute('/category', 'ar'),

    [ROUTES.privacyPolicy]: getLocalizedRoute('/privacy-policy', 'ar'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/terms-of-use', 'ar'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/unsubscribe-blog', 'ar'),
    [ROUTES.bodyType]: getLocalizedRoute('/body-type', 'ar'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/buy-cars-under', 'ar'),

    [ROUTES.editProfile]: '/edit-profile',
    [ROUTES.sellYourCar]: '/sell-your-car'
};

const routesKinyarwanda = {
    [ROUTES.home]: getLocalizedRoute('/', 'rw'),
    [ROUTES.slash]: getLocalizedRoute('/', 'rw'),
    [ROUTES.buyCars]: getLocalizedRoute('/buy-cars', 'rw'),
    [ROUTES.buy]: getLocalizedRoute('/buy', 'rw'),
    [ROUTES.sellCar]: getLocalizedRoute('/sell-car', 'rw'),
    [ROUTES.carFinance]: getLocalizedRoute('/car-finance', 'rw'),
    [ROUTES.carInsurance]: getLocalizedRoute('/car-insurance', 'rw'),
    [ROUTES.about]: getLocalizedRoute('/about', 'rw'),
    [ROUTES.faqs]: getLocalizedRoute('/faqs', 'rw'),
    [ROUTES.contactUs]: getLocalizedRoute('/contact-us', 'rw'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'rw'),
    [ROUTES.account]: getLocalizedRoute('/account', 'rw'),
    [ROUTES.press]: getLocalizedRoute('/press', 'rw'),

    [ROUTES.category]: getLocalizedRoute('/category', 'rw'),

    [ROUTES.privacyPolicy]: getLocalizedRoute('/privacy-policy', 'rw'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/terms-of-use', 'rw'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/unsubscribe-blog', 'rw'),
    [ROUTES.bodyType]: getLocalizedRoute('/body-type', 'rw'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/buy-cars-under', 'rw'),

    [ROUTES.editProfile]: '/edit-profile',
    [ROUTES.sellYourCar]: '/sell-your-car'
};

const routesZulu = {
    [ROUTES.home]: getLocalizedRoute('/', 'zu'),
    [ROUTES.slash]: getLocalizedRoute('/', 'zu'),
    [ROUTES.buyCars]: getLocalizedRoute('/buy-cars', 'zu'),
    [ROUTES.buy]: getLocalizedRoute('/buy', 'zu'),
    [ROUTES.sellCar]: getLocalizedRoute('/sell-car', 'zu'),
    [ROUTES.carFinance]: getLocalizedRoute('/car-finance', 'zu'),
    [ROUTES.carInsurance]: getLocalizedRoute('/car-insurance', 'zu'),
    [ROUTES.about]: getLocalizedRoute('/about', 'zu'),
    [ROUTES.faqs]: getLocalizedRoute('/faqs', 'zu'),
    [ROUTES.contactUs]: getLocalizedRoute('/contact-us', 'zu'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'zu'),
    [ROUTES.account]: getLocalizedRoute('/account', 'zu'),
    [ROUTES.press]: getLocalizedRoute('/press', 'zu'),

    [ROUTES.category]: getLocalizedRoute('/category', 'zu'),

    [ROUTES.privacyPolicy]: getLocalizedRoute('/privacy-policy', 'zu'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/terms-of-use', 'zu'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/unsubscribe-blog', 'zu'),
    [ROUTES.bodyType]: getLocalizedRoute('/body-type', 'zu'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/buy-cars-under', 'zu'),

    [ROUTES.editProfile]: '/edit-profile',
    [ROUTES.sellYourCar]: '/sell-your-car'
};

const routesXhosa = {
    [ROUTES.home]: getLocalizedRoute('/', 'xh'),
    [ROUTES.slash]: getLocalizedRoute('/', 'xh'),
    [ROUTES.buyCars]: getLocalizedRoute('/buy-cars', 'xh'),
    [ROUTES.buy]: getLocalizedRoute('/buy', 'xh'),
    [ROUTES.sellCar]: getLocalizedRoute('/sell-car', 'xh'),
    [ROUTES.carFinance]: getLocalizedRoute('/car-finance', 'xh'),
    [ROUTES.carInsurance]: getLocalizedRoute('/car-insurance', 'xh'),
    [ROUTES.about]: getLocalizedRoute('/about', 'xh'),
    [ROUTES.faqs]: getLocalizedRoute('/faqs', 'xh'),
    [ROUTES.contactUs]: getLocalizedRoute('/contact-us', 'xh'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'xh'),
    [ROUTES.account]: getLocalizedRoute('/account', 'xh'),
    [ROUTES.press]: getLocalizedRoute('/press', 'xh'),

    [ROUTES.category]: getLocalizedRoute('/category', 'xh'),

    [ROUTES.privacyPolicy]: getLocalizedRoute('/privacy-policy', 'xh'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/terms-of-use', 'xh'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/unsubscribe-blog', 'xh'),
    [ROUTES.bodyType]: getLocalizedRoute('/body-type', 'xh'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/buy-cars-under', 'xh'),

    [ROUTES.editProfile]: '/edit-profile',
    [ROUTES.sellYourCar]: '/sell-your-car'
};

const routesWolof = {
    [ROUTES.home]: getLocalizedRoute('/', 'sn'),
    [ROUTES.slash]: getLocalizedRoute('/', 'sn'),
    [ROUTES.buyCars]: getLocalizedRoute('/buy-cars', 'sn'),
    [ROUTES.buy]: getLocalizedRoute('/buy', 'sn'),
    [ROUTES.sellCar]: getLocalizedRoute('/sell-car', 'sn'),
    [ROUTES.carFinance]: getLocalizedRoute('/car-finance', 'sn'),
    [ROUTES.carInsurance]: getLocalizedRoute('/car-insurance', 'sn'),
    [ROUTES.about]: getLocalizedRoute('/about', 'sn'),
    [ROUTES.faqs]: getLocalizedRoute('/faqs', 'sn'),
    [ROUTES.contactUs]: getLocalizedRoute('/contact-us', 'sn'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'sn'),
    [ROUTES.account]: getLocalizedRoute('/account', 'sn'),
    [ROUTES.press]: getLocalizedRoute('/press', 'sn'),

    [ROUTES.category]: getLocalizedRoute('/category', 'sn'),

    [ROUTES.privacyPolicy]: getLocalizedRoute('/privacy-policy', 'sn'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/terms-of-use', 'sn'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/unsubscribe-blog', 'sn'),
    [ROUTES.bodyType]: getLocalizedRoute('/body-type', 'sn'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/buy-cars-under', 'sn'),

    [ROUTES.editProfile]: '/edit-profile',
    [ROUTES.sellYourCar]: '/sell-your-car'
};

// For multiple languages
const getRoutesObj = (route: string) => ({
    [ROUTES.home]: `/${route}`,
    [ROUTES.slash]: `/${route}`,
    [ROUTES.buyCars]: `/${route}${ROUTES.buyCars}`,
    [ROUTES.buy]: `/${route}${ROUTES.buy}`,
    [ROUTES.sellCar]: `/${route}${ROUTES.sellCar}`,
    [ROUTES.carFinance]: `/${route}${ROUTES.carFinance}`,
    [ROUTES.carInsurance]: `/${route}${ROUTES.carInsurance}`,
    [ROUTES.about]: `/${route}${ROUTES.about}`,
    [ROUTES.faqs]: `/${route}${ROUTES.faqs}`,
    [ROUTES.contactUs]: `/${route}${ROUTES.contactUs}`,
    [ROUTES.blog]: `/${route}${ROUTES.blog}`,
    [ROUTES.account]: `/${route}${ROUTES.account}`,

    [ROUTES.category]: `/${route}${ROUTES.category}`,

    [ROUTES.privacyPolicy]: `/${route}${ROUTES.privacyPolicy}`,
    [ROUTES.termsOfUse]: `/${route}${ROUTES.termsOfUse}`,
    [ROUTES.unsubscribeBlog]: `/${route}${ROUTES.unsubscribeBlog}`,

    [ROUTES.editProfile]: `/${ROUTES.editProfile}`,
    [ROUTES.sellYourCar]: `/${ROUTES.sellYourCar}`
});

const routesLang = {
    [ALL_LANGUAGES.english.value]: routesEnglish,
    [ALL_LANGUAGES.french.value]: routesFrench,
    [ALL_LANGUAGES.arabic.value]: routesArabic,
    [ALL_LANGUAGES.kinyarnwanda.value]: routesKinyarwanda,
    [ALL_LANGUAGES.zulu.value]: routesZulu,
    [ALL_LANGUAGES.xhosa.value]: routesXhosa,
    [ALL_LANGUAGES.wolof.value]: routesWolof

    // FOr others routes add like below note: don't use this method as it is giving issues
    // [ALL_LANGUAGES.xhosa.value]: getRoutesObj(ALL_LANGUAGES.xhosa.value),
    // [ALL_LANGUAGES.wolof.value]: getRoutesObj(ALL_LANGUAGES.wolof.value),
};

export default routesLang;
