export const PrevArrow = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={props.className}
    >
      <path
        d="M11.25 14.625L5.625 9L11.25 3.375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NextArrow = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={props.className}
    >
      <path
        d="M6.75 3.375L12.375 9L6.75 14.625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NextChevron = (props: { className?: string }) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      className={props.className}
    >
      <path 
        d="M13.172 12L8.22205 7.04999L9.63605 5.63599L16 12L9.63605 18.364L8.22205 16.95L13.172 12Z" 
        fill="#175ADC"
      />
    </svg>
  );
}

export const HalfStar = (props: { className?: string }) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="1em" 
      height="1em" 
      viewBox="0 0 256 256"
      className={props.className}
    >
      <defs>
      <clipPath id="clipLeft">
              <rect x="0" y="0" width="128" height="256" />
        </clipPath>
        <clipPath id="clipRight">
            <rect x="128" y="0" width="128" height="256" />
        </clipPath>
    </defs>
    <g>
        <path 
            d="M239.18 97.26A16.38 16.38 0 0 0 224.92 86l-59-4.76l-22.78-55.09a16.36 16.36 0 0 0-30.27 0L90.11 81.23L31.08 86a16.46 16.46 0 0 0-9.37 28.86l45 38.83L53 211.75a16.4 16.4 0 0 0 24.5 17.82l50.5-31.08l50.53 31.08A16.4 16.4 0 0 0 203 211.75l-13.76-58.07l45-38.83a16.43 16.43 0 0 0 4.94-17.59" 
            clipPath="url(#clipLeft)" 
        />
        <path 
            d="M239.18 97.26A16.38 16.38 0 0 0 224.92 86l-59-4.76l-22.78-55.09a16.36 16.36 0 0 0-30.27 0L90.11 81.23L31.08 86a16.46 16.46 0 0 0-9.37 28.86l45 38.83L53 211.75a16.4 16.4 0 0 0 24.5 17.82l50.5-31.08l50.53 31.08A16.4 16.4 0 0 0 203 211.75l-13.76-58.07l45-38.83a16.43 16.43 0 0 0 4.94-17.59" 
            fill="lightgray" 
            clipPath="url(#clipRight)" 
        />
    </g>
    </svg>
  );
}
  