import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    CaretDown,
    FadersHorizontal,
    MagnifyingGlass,
    X
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import SecSeperator from './ui/SecSeperator';
import Selects from './ui/Select';
import RangeFilterModal from './ui/RangeFilterModal';

import { ICombineReducerType } from '../redux/reducers';
import { IBrand, ICarBodyType } from '../interface/car';
import {
    getCarAvailableBrand,
    getCarAvailablsModels
} from '../redux/actions/carModelsAction';
import { $FIXME, COUNTRY_TOP_LEVEL_DOMAIN } from '../constants';
import {
    contentAccordToCurrentLang,
    formatKm,
    Stringfy,
    useOutsideClick
} from '../utils/Helper/jsHelpers';
import {
    getAvailCarColors,
    getCarBodyTypes
} from '../redux/actions/carActions';
import text_truncate from '../utils/Helper/TextTruncate';
import { useLgMediaQuery } from '../utils/Hooks/MediaQuery';
import { api } from '../helpers/api';
import { getCarData } from '../redux/actions/carListingAction';
import {
    buyCarListLoadingDisable,
    buyCarListLoadingEnable,
    setBuyCarListPage
} from '../redux/actions/carListAction';
import { getTopLevelDomain } from '../utils/translation';
import { useTranslatedOptions } from '../utils/Hooks/TranslatedOptions';
import { decodeCompactNumber } from '../utils/Helper/PriceFormatter';

interface searchOptions {
    brand: $FIXME[];
    model: $FIXME[];
    fuelType: string;
    transmission: $FIXME[];
    bodyType: $FIXME[];
    condition: $FIXME[];
    color: $FIXME[];
    minMileage: string;
    maxMileage: string;
    minMonthlyInstallment: string;
    maxMonthlyInstallment: string;
    modelYear: $FIXME[];
    minPrice: string;
    maxPrice: $FIXME;
    search: string;
}
export const formatMoney = (n: any) => {
    return (Math.round(n * 100) / 100).toLocaleString('fr');
};

const SearchFilter = (props: any) => {
    const topLevelDomain = getTopLevelDomain();
    const { brandName, bodyTypeName, priceRange } = useParams();

    const [brandList, setBrandList] = useState<any[]>([]);
    const [modelsList, setModelsList] = useState<any[]>([]);
    const [carManuYear, setCarYearManu] = useState<any[]>([]);
    const [visibledriven, setvisibleDriven] = useState(false);
    const [visibleMonthly, setvisibleMon] = useState(false);
    const [modelYear, setModalYear] = useState<any[]>([]);
    const [bodyTypesList, setBodyTypeList] = useState<any[]>([]);
    const [visiblePriceRange, setvisiblePriceRange] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedOptions, setSelectedOptions] = useState({
        brand: searchParams.getAll('brand') ?? [],
        model: searchParams.getAll('model') ?? [],
        fuelType: searchParams.get('fuelType') ?? '',
        transmission: searchParams.getAll('transmission') ?? [],
        bodyType: searchParams.getAll('bodyType') ?? [],
        condition: searchParams.getAll('condition') ?? [],
        minMileage: searchParams.get('minMileage') ?? '',
        maxMileage: searchParams.get('maxMileage') ?? '',
        color: searchParams.getAll('color') ?? [],
        modelYear: searchParams.getAll('modelYear') ?? [],
        minPrice: searchParams.get('minPrice') ?? '',
        maxPrice: searchParams.getAll('maxPrice')
            ? searchParams.getAll('maxPrice').length > 0
                ? searchParams.getAll('maxPrice')
                : ''
            : '',
        minMonthlyInstallment: '',
        maxMonthlyInstallment: '',
        search: searchParams.get('search') ?? '',
        isNewCar: searchParams.getAll('isNewCar') ?? '',
        isElectric: searchParams.getAll('isElectric') ?? '',
        imported: searchParams.getAll('imported') ?? ''
    } as searchOptions);

    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedOptionChange, setSelectedOptionChange] = useState<number>(0);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const carBrands = useSelector(
        (state: ICombineReducerType) => state.carBrands.carAvailableBrands
    );
    const carModels = useSelector(
        (state: ICombineReducerType) => state.carModels.carAvilableModel
    );

    const bodyTypes = useSelector(
        (state: ICombineReducerType) => state.car.availableBodyTypes
    );
    const carColors = useSelector(
        (state: ICombineReducerType) => state.car.availableCarColor
    );
    const { fuelTypes, transmissionTypes } = useTranslatedOptions();
    useEffect(() => {
        getAvailCarColors(dispatch);
        if (!bodyTypes.length) {
            getCarBodyTypes(dispatch, true);
        }
    }, []);
    useEffect(() => {
        const fetchCarsData = async () => {
            if (
                !Object.values(selectedOptions).filter((item) => item?.length)
                    .length
            ) {
                setBuyCarListPage(dispatch, 2);
                buyCarListLoadingEnable(dispatch);
                const resData = await getCarData(dispatch, {
                    brands: brandName ? Stringfy([brandName]) : null,
                    bodyTypes: bodyTypeName ? Stringfy([bodyTypeName]) : null,
                    maxPrice: priceRange
                        ? decodeCompactNumber(priceRange)
                        : null,
                    sort: '-price',
                    page: 1,
                    limit: 9
                });
                if (resData.status === 200) buyCarListLoadingDisable(dispatch);
                else {
                    buyCarListLoadingDisable(dispatch);
                }
            }
        };
        fetchCarsData();
    }, [selectedOptionChange, brandName, bodyTypeName, priceRange]);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedOptions({
            ...selectedOptions,
            [e.target.name]: e.target.value
        });
        setSelectedOptionChange((prevValue) => prevValue + 1);
    };

    const onSelect = (value: string, key: string) => {
        setSelectedOptionChange((prevValue) => prevValue + 1);
        setSelectedOptions({
            ...selectedOptions,
            [key]: value
        });
    };
    const navigate = useNavigate();

    const onSingleSelect = (value: string, key: string) => {
        if (key === 'bodyType') {
            if (value) {
                navigate(`${t('/body-type', { ns: 'routes' })}/${value}`);
                setSelectedOptionChange((prevValue) => prevValue + 1);

                setSelectedOptions({
                    ...selectedOptions,
                    [key]: [value]
                });
            }
        }
        if (key === 'brand') {
            if (value) {
                navigate(`${t('/buy-cars', { ns: 'routes' })}/${value}`);
                setSelectedOptionChange((prevValue) => prevValue + 1);
                setSelectedOptions({
                    ...selectedOptions,
                    model: [],
                    [key]: [value]
                });
            }
        }
    };

    useEffect(() => {
        const brand = [brandName];
        const bodyType = [bodyTypeName];

        setSelectedOptions({
            ...selectedOptions,
            brand: brandName ? brand : searchParams.getAll('brand') ?? [],
            model: searchParams.getAll('model') ?? [],
            bodyType: bodyTypeName
                ? bodyType
                : searchParams.getAll('bodyType') ?? [],
            color: searchParams.getAll('color') ?? [],
            transmission: searchParams.getAll('transmission') ?? [],
            modelYear: searchParams.getAll('modelYear') ?? [],
            minPrice: searchParams.get('minPrice') ?? '',
            maxPrice: priceRange
                ? priceRange
                : searchParams.get('maxPrice') ?? '',
            minMileage: searchParams.get('minMileage') ?? '',
            maxMileage: searchParams.get('maxMileage') ?? '',
            fuelType: searchParams.get('fuelType') ?? ''
        });
    }, [searchParams, brandName, bodyTypeName, priceRange]);

    useEffect(() => {
        if (carBrands.filter((item: $FIXME) => item).length) {
            if (carBrands.length > 0) {
                const brandsList: Array<any> = carBrands.map(
                    (items: IBrand) => ({
                        label: items.brand,
                        value: items.slug,
                        _id: items._id
                    })
                );
                setBrandList(brandsList);
            }
        }
    }, [carBrands]);

    useEffect(() => {
        if (bodyTypes?.filter((item: $FIXME) => item).length) {
            if (bodyTypes.length > 0) {
                const bodyTypesList: any[] = bodyTypes.map(
                    (item: ICarBodyType) => ({
                        label: contentAccordToCurrentLang(item).bodyType,
                        value: item.slug,
                        _id: item._id
                    })
                );
                setBodyTypeList(bodyTypesList);
            }
        }
    }, [bodyTypes]);
    function formatMoney(n: any, iscurrency = true) {
        return iscurrency
            ? `${
                  topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza
                      ? process.env.REACT_APP_DEFAULT_CURRENCY
                      : ''
              }` +
                  ' ' +
                  (Math.round(n * 100) / 100)
                      .toLocaleString()
                      .replaceAll(',', ' ') +
                  ' ' +
                  `${
                      topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.coza
                          ? process.env.REACT_APP_DEFAULT_CURRENCY
                          : ''
                  }`
            : (Math.round(n * 100) / 100).toLocaleString().replaceAll(',', ' ');
    }

    useEffect(() => {
        let years = modelYear.map((item) => {
            return {
                label: item,
                value: item,
                _id: item
            };
        });
        setCarYearManu(years);
    }, [modelYear]);
    useEffect(() => {
        api.get('/cars/get-car-years')
            .then((res) => {
                setModalYear(
                    res.data.modelYears.map((year: string) =>
                        t('year').replace('{{X}}', year)
                    )
                );
            })
            .catch(() => {});
    }, []);

    const parametersToCheck = [
        'minPrice',
        'maxPrice',
        'bodyType',
        'fuelType',
        'color',
        'transmission',
        'modelYear'
    ];

    useEffect(() => {
        parametersToCheck.forEach((param) => {
            if (searchParams.has(param)) {
                setShowMoreFilters(true);
            }
        });
    }, [searchParams, selectedOptionChange]);

    useEffect(() => {
        if (props.resetFilter) {
            resetFilter();
            props.setResetFilter(false);
        }
    }, [props.resetFilter]);

    useEffect(() => {
        if (selectedOptions.brand.length === 0) {
            getCarAvailablsModels(dispatch);
        }
        for (let i = 0; i < selectedOptions.brand.length; i++) {
            getCarAvailableBrand(dispatch, selectedOptions.brand[i]);
        }
    }, [selectedOptions.brand]);
    useEffect(() => {
        let queryParams = JSON.parse(JSON.stringify(selectedOptions));
        let params = '';
        for (let key in queryParams) {
            if (
                queryParams[key].length === 0 ||
                queryParams[key] === undefined
            ) {
                delete queryParams[key];
            } else {
                params += `&${queryParams[key]}`;
            }
        }
        if (selectedOptionChange !== 0) {
            setSearchParams(queryParams);
        }
    }, [selectedOptionChange]);
    useEffect(() => {
        if (carModels.filter((item: $FIXME) => item).length > 0) {
            const carModelsList = carModels.map((items: $FIXME) => ({
                label: `${items.model}`,
                value: items.slug,
                _id: items._id
            }));
            setModelsList([...carModelsList]);
        }
    }, [carModels, selectedOptionChange]);

    const moreFilters = () => {
        setShowMoreFilters(!showMoreFilters);
    };
    const resetFilter = () => {
        setSelectedOptions({
            brand: [],
            model: [],
            fuelType: '',
            transmission: [],
            bodyType: [],
            condition: [],
            color: [],
            modelYear: [],
            minPrice: '',
            maxPrice: '',
            search: '',
            minMileage: '',
            maxMileage: '',
            minMonthlyInstallment: '',
            maxMonthlyInstallment: ''
        });
    };

    function resetRangeFIlter() {
        setvisibleMon(false);
        setvisiblePriceRange(false);
        setvisibleDriven(false);
    }

    const refDriven = useOutsideClick(function () {
        setvisibleDriven(false);
    });
    const refPrice = useOutsideClick(function () {
        setvisiblePriceRange(false);
    });

    const FiltersToggle = () => {
        setOpenModal(true);
    };
    const onClose = () => {
        setOpenModal(false);
    };

    return (
        <>
            <div className="search-filter padding">
                <div className="search-filter-top">
                    <form>
                        <div className="input-container">
                            <MagnifyingGlass size={24} color="#727272" />
                            <input
                                value={selectedOptions.search}
                                type="search"
                                name="search"
                                placeholder={t('searchBarPlaceholder', {
                                    ns: 'hero'
                                })}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <span
                            className="more-filter"
                            onClick={
                                useLgMediaQuery() ? FiltersToggle : moreFilters
                            }
                        >
                            {useLgMediaQuery() ? t('filter') : t('moreFilters')}
                            <FadersHorizontal size={24} color="#727272" />
                        </span>
                    </form>
                </div>
                <div className="search-filter-bottom">
                    <div className="more-filters">
                        <Selects
                            placeholder={t('brand')}
                            options={brandList}
                            onSelect={brandName ? onSingleSelect : onSelect}
                            name="brand"
                            value={selectedOptions.brand}
                            mode={brandName ? 'single' : 'multiple'}
                            maxTagCount="responsive"
                            class="select-cursor"
                        />
                        <Selects
                            placeholder={t('model')}
                            options={modelsList}
                            onSelect={onSelect}
                            showSearch={true}
                            name="model"
                            value={selectedOptions.model}
                            disabled={carBrands.length === 0}
                            mode="multiple"
                            maxTagCount="responsive"
                            class="select-cursor"
                        />

                        <div className="selects-container" ref={refDriven}>
                            <div
                                className="selects input-month"
                                onClick={() => setvisibleDriven(!visibledriven)}
                            >
                                {!!selectedOptions.maxMileage ? (
                                    <span className="show-range">
                                        {selectedOptions.minMileage}-
                                        {selectedOptions.maxMileage} KM
                                        <span
                                            className="reset-icon"
                                            onClick={() => {
                                                setSelectedOptionChange(
                                                    (prevValue) => prevValue + 1
                                                );
                                                setSelectedOptions({
                                                    ...selectedOptions,
                                                    maxMileage: '',
                                                    minMileage: ''
                                                });
                                            }}
                                        >
                                            <X size={8} color="white" />
                                        </span>
                                    </span>
                                ) : (
                                    t('mileage').charAt(0).toLocaleUpperCase() +
                                    t('mileage').slice(1)
                                )}

                                <CaretDown
                                    size={24}
                                    color="#b3b3b3"
                                    weight="bold"
                                />
                            </div>
                            <RangeFilterModal
                                showAt="right"
                                inputPrefix="KM"
                                setvisible={setvisibleDriven}
                                minValue={selectedOptions.minMileage}
                                maxValue={selectedOptions.maxMileage}
                                visible={visibledriven}
                                handleSave={(range: any) => {
                                    setSelectedOptions({
                                        ...selectedOptions,
                                        minMileage: range.min,
                                        maxMileage: range.max
                                    });
                                    resetRangeFIlter();
                                }}
                                minTitle={formatKm(0)}
                                maxTitle={formatKm(120000)}
                                defaultStep={10000}
                                defaultMin={0}
                                defaultMax={120000}
                                minInputTitle={t('minDistance')}
                                maxInputTitle={t('maxDistance')}
                                icon="/icon/dashboard.svg"
                            />
                        </div>
                    </div>
                    {showMoreFilters && (
                        <div className="more-filters">
                            <div className="selects-container" ref={refPrice}>
                                <div
                                    className="selects input-month"
                                    onClick={() => {
                                        setSelectedOptionChange(
                                            (prevValue) => prevValue + 1
                                        );
                                        setvisibleDriven(false);
                                        setvisibleMon(false);
                                        setvisiblePriceRange(
                                            !visiblePriceRange
                                        );
                                    }}
                                >
                                    {!!selectedOptions.maxPrice ? (
                                        <span className="show-range">
                                            {
                                                process.env
                                                    .REACT_APP_DEFAULT_CURRENCY
                                            }
                                            {text_truncate(
                                                formatMoney(
                                                    selectedOptions.minPrice
                                                )?.replaceAll(/[aA-zZ]/g, ''),
                                                12,
                                                '...'
                                            )}{' '}
                                            -
                                            {text_truncate(
                                                formatMoney(
                                                    priceRange
                                                        ? decodeCompactNumber(
                                                              priceRange
                                                          )
                                                        : selectedOptions.maxPrice
                                                )?.replaceAll(/[aA-zZ]/g, ''),
                                                12,
                                                '...'
                                            )}{' '}
                                            <span
                                                className="reset-icon"
                                                onClick={() => {
                                                    if (!priceRange) {
                                                        setSelectedOptionChange(
                                                            (prevValue) =>
                                                                prevValue + 1
                                                        );
                                                        setSelectedOptions({
                                                            ...selectedOptions,
                                                            minPrice: '',
                                                            maxPrice: ''
                                                        });
                                                    }
                                                }}
                                            >
                                                <X size={8} color="white" />
                                            </span>
                                        </span>
                                    ) : (
                                        t('price').charAt(0).toUpperCase() +
                                        t('price').slice(1)
                                    )}

                                    <CaretDown
                                        size={24}
                                        color="#b3b3b3"
                                        weight="bold"
                                    />
                                </div>
                                <RangeFilterModal
                                    handleSave={(range: any) => {
                                        setSelectedOptions({
                                            ...selectedOptions,
                                            minPrice: range.min,
                                            maxPrice: priceRange
                                                ? decodeCompactNumber(
                                                      priceRange
                                                  )
                                                : range.max
                                        });
                                        resetRangeFIlter();
                                        setSelectedOptionChange(
                                            (prevValue) => prevValue + 1
                                        );
                                    }}
                                    showAt="right"
                                    visible={visiblePriceRange}
                                    setvisible={setvisiblePriceRange}
                                    minTitle={formatMoney(
                                        topLevelDomain ===
                                            COUNTRY_TOP_LEVEL_DOMAIN.ma
                                            ? 5000
                                            : 100000
                                    )}
                                    maxTitle={formatMoney(45000000)}
                                    minValue={
                                        priceRange
                                            ? '0'
                                            : selectedOptions.minPrice
                                    }
                                    maxValue={
                                        priceRange
                                            ? decodeCompactNumber(
                                                  priceRange
                                              )?.toString()
                                            : selectedOptions.maxPrice
                                    }
                                    defaultMin={
                                        topLevelDomain ===
                                        COUNTRY_TOP_LEVEL_DOMAIN.ma
                                            ? 5000
                                            : 100000
                                    }
                                    defaultMax={45000000}
                                    defaultStep={10000}
                                    minInputTitle={t('minPrice')}
                                    maxInputTitle={t('maxPrice')}
                                    icon="/images/priceIcon.svg"
                                    disabled={priceRange ? true : false}
                                />
                            </div>
                            <Selects
                                options={bodyTypesList}
                                placeholder={t('bodyType')}
                                onSelect={
                                    bodyTypeName ? onSingleSelect : onSelect
                                }
                                name="bodyType"
                                value={selectedOptions.bodyType}
                                mode={bodyTypeName ? 'single' : 'multiple'}
                                maxTagCount="responsive"
                                allowClear={true}
                                class="select-cursor"
                            />
                            <Selects
                                options={fuelTypes}
                                placeholder={t('fuelType')}
                                value={selectedOptions.fuelType}
                                name="fuelType"
                                maxTagCount="responsive"
                                allowClear={true}
                                onSelect={onSelect}
                                class="select-cursor"
                            />
                            <Selects
                                options={carColors.map((item, index) => {
                                    let color =
                                        contentAccordToCurrentLang(item);
                                    return {
                                        label: color.color,
                                        backgroundcolor: item.details.find(
                                            (i) => i.lang === 'en'
                                        )?.color,
                                        value: item.slug,
                                        _id: item.slug
                                    };
                                })}
                                placeholder={t('color')}
                                name="color"
                                mode="multiple"
                                maxTagCount="responsive"
                                allowClear={true}
                                onSelect={onSelect}
                                value={selectedOptions.color}
                                colors
                                class="select-cursor"
                            />
                            <Selects
                                placeholder={t('transmission')}
                                options={transmissionTypes}
                                value={selectedOptions.transmission}
                                mode="multiple"
                                maxTagCount="responsive"
                                allowClear={true}
                                name="transmission"
                                onSelect={onSelect}
                                class="select-cursor"
                            />

                            <Selects
                                options={carManuYear}
                                placeholder={t('modelYear')}
                                value={selectedOptions.modelYear}
                                name="modelYear"
                                // mode="multiple"
                                maxTagCount="responsive"
                                allowClear={true}
                                onSelect={onSelect}
                                class="select-cursor"
                            />
                        </div>
                    )}
                </div>
            </div>
            {openModal && (
                <Modal
                    open={openModal}
                    onCancel={onClose}
                    centered={true}
                    footer={false}
                    zIndex={1050}
                >
                    <div className="search-filter-modal">
                        <SecSeperator className="filter-sep" />
                        <h2 className="title">{t('filter')}</h2>
                        <div className="more-filters">
                            <Selects
                                placeholder={t('brand')}
                                options={brandList}
                                onSelect={onSelect}
                                name="brand"
                                value={selectedOptions.brand}
                                mode="multiple"
                                maxTagCount="responsive"
                                class="select-cursor"
                            />
                            <Selects
                                placeholder={t('model')}
                                options={modelsList}
                                onSelect={onSelect}
                                showSearch={true}
                                name="model"
                                value={selectedOptions.model}
                                disabled={carBrands.length === 0}
                                mode="multiple"
                                maxTagCount="responsive"
                                class="select-cursor"
                            />

                            <div className="selects-container" ref={refDriven}>
                                <div
                                    className="selects input-month"
                                    onClick={() => {
                                        setSelectedOptionChange(
                                            (prevValue) => prevValue + 1
                                        );
                                        setvisibleDriven(!visibledriven);
                                    }}
                                >
                                    {!!selectedOptions.maxMileage ? (
                                        <span className="show-range">
                                            {selectedOptions.minMileage}-
                                            {selectedOptions.maxMileage} KM
                                            <span
                                                className="reset-icon"
                                                onClick={() => {
                                                    setSelectedOptionChange(
                                                        (prevValue) =>
                                                            prevValue + 1
                                                    );
                                                    setSelectedOptions({
                                                        ...selectedOptions,
                                                        maxMileage: '',
                                                        minMileage: ''
                                                    });
                                                }}
                                            >
                                                <X size={8} color="white" />
                                            </span>
                                        </span>
                                    ) : (
                                        t('mileage')
                                            .charAt(0)
                                            .toLocaleUpperCase() +
                                        t('mileage').slice(1)
                                    )}

                                    <span>
                                        <img
                                            src="/images/caret_down.svg"
                                            alt="caret_down"
                                        />
                                    </span>
                                </div>
                                <RangeFilterModal
                                    showAt="right"
                                    inputPrefix="KM"
                                    setvisible={setvisibleDriven}
                                    minValue={selectedOptions.minMileage}
                                    maxValue={selectedOptions.maxMileage}
                                    visible={visibledriven}
                                    handleSave={(range: any) => {
                                        setSelectedOptions({
                                            ...selectedOptions,
                                            minMileage: range.min,
                                            maxMileage: range.max
                                        });
                                        resetRangeFIlter();
                                    }}
                                    minTitle={formatKm(0)}
                                    maxTitle={formatKm(120000)}
                                    defaultMin={0}
                                    defaultMax={120000}
                                    defaultStep={10000}
                                    minInputTitle={t('minDistance')}
                                    maxInputTitle={t('maxDistance')}
                                    icon="/icon/dashboard.svg"
                                />
                            </div>

                            <div className="selects-container" ref={refPrice}>
                                <div
                                    className="selects input-month"
                                    onClick={() => {
                                        setSelectedOptionChange(
                                            (prevValue) => prevValue + 1
                                        );
                                        setvisibleDriven(false);
                                        setvisibleMon(false);
                                        setvisiblePriceRange(
                                            !visiblePriceRange
                                        );
                                    }}
                                >
                                    {!!selectedOptions.maxPrice ? (
                                        <span className="show-range">
                                            {
                                                process.env
                                                    .REACT_APP_DEFAULT_CURRENCY
                                            }
                                            {text_truncate(
                                                formatMoney(
                                                    selectedOptions.minPrice
                                                )?.replaceAll(/[aA-zZ]/g, ''),
                                                12,
                                                '...'
                                            )}{' '}
                                            -
                                            {text_truncate(
                                                formatMoney(
                                                    selectedOptions.maxPrice
                                                )?.replaceAll(/[aA-zZ]/g, ''),
                                                12,
                                                '...'
                                            )}{' '}
                                            <span
                                                className="reset-icon"
                                                onClick={() => {
                                                    setSelectedOptionChange(
                                                        (prevValue) =>
                                                            prevValue + 1
                                                    );
                                                    setSelectedOptions({
                                                        ...selectedOptions,
                                                        minPrice: '',
                                                        maxPrice: ''
                                                    });
                                                }}
                                            >
                                                <X size={8} color="white" />
                                            </span>
                                        </span>
                                    ) : (
                                        t('price').charAt(0).toUpperCase() +
                                        t('price').slice(1)
                                    )}
                                    <span>
                                        <img
                                            src="/images/caret_down.svg"
                                            alt="caret_down"
                                        />
                                    </span>
                                </div>
                                <RangeFilterModal
                                    handleSave={(range: any) => {
                                        setSelectedOptions({
                                            ...selectedOptions,
                                            minPrice: range.min,
                                            maxPrice: range.max
                                        });
                                        resetRangeFIlter();
                                    }}
                                    visible={visiblePriceRange}
                                    setvisible={setvisiblePriceRange}
                                    minTitle={formatMoney(
                                        topLevelDomain ===
                                            COUNTRY_TOP_LEVEL_DOMAIN.ma
                                            ? 5000
                                            : 100000
                                    )}
                                    maxTitle={formatMoney(30000000)}
                                    minValue={selectedOptions.minPrice}
                                    maxValue={selectedOptions.maxPrice}
                                    defaultMin={
                                        topLevelDomain ===
                                        COUNTRY_TOP_LEVEL_DOMAIN.ma
                                            ? 5000
                                            : 100000
                                    }
                                    defaultMax={30000000}
                                    defaultStep={10000}
                                    minInputTitle={t('minPrice')}
                                    maxInputTitle={t('maxPrice')}
                                    icon="/images/priceIcon.svg"
                                />
                            </div>
                        </div>
                        <div className="more-filters">
                            <Selects
                                options={bodyTypesList}
                                placeholder={t('bodyType')}
                                onSelect={onSelect}
                                name="bodyType"
                                value={selectedOptions.bodyType}
                                mode="multiple"
                                maxTagCount="responsive"
                                allowClear={true}
                                class="select-cursor"
                            ></Selects>
                            <Selects
                                options={carColors.map((item, index) => {
                                    let color =
                                        contentAccordToCurrentLang(item);
                                    return {
                                        label: color.color,
                                        backgroundcolor: item.details.find(
                                            (i) => i.lang === 'en'
                                        )?.color,
                                        value: item.slug,
                                        _id: item.slug
                                    };
                                })}
                                placeholder={t('color')}
                                name="color"
                                mode="multiple"
                                maxTagCount="responsive"
                                allowClear={true}
                                onSelect={onSelect}
                                value={selectedOptions.color}
                                class="select-cursor"
                            />
                            <Selects
                                placeholder={t('transmission')}
                                options={transmissionTypes}
                                value={selectedOptions.transmission}
                                mode="multiple"
                                maxTagCount="responsive"
                                allowClear={true}
                                name="transmission"
                                onSelect={onSelect}
                                class="select-cursor"
                            />

                            <Selects
                                options={carManuYear}
                                placeholder={t('modelYear')}
                                value={selectedOptions.modelYear}
                                name="modelYear"
                                // mode="multiple"
                                maxTagCount="responsive"
                                allowClear={true}
                                onSelect={onSelect}
                                class="select-cursor"
                            />
                            <Selects
                                options={fuelTypes}
                                placeholder={t('fuelType')}
                                value={selectedOptions.fuelType}
                                name="fuelType"
                                maxTagCount="responsive"
                                allowClear={true}
                                onSelect={onSelect}
                                class="select-cursor"
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default SearchFilter;
