import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../ui/ButtonComponent';

const ResetSuccessful = ({ handleActiveModal, handleCancel }: any) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="reset-successful">
            <span>{t('resetSuccessMsg')}</span>
            <img src="/images/check2.svg" alt="check" />
            <div className="reset-success-button-wrapper">
                <ButtonComponent
                    type="secondary"
                    className="reset-success-button"
                    onClick={() => {
                        handleCancel();
                        navigate(t('/buy-cars', { ns: 'routes' }));
                    }}
                >
                    {t('exploreCar', { ns: 'hero' })}
                </ButtonComponent>
                <ButtonComponent
                    type="primary"
                    onClick={() => handleActiveModal(1)}
                    className="reset-success-button"
                >
                    {t('signIn')}
                </ButtonComponent>
            </div>
        </div>
    );
};

export default ResetSuccessful;
