import { addImageFallback } from '../utils/Helper/ImagePlaceholder';
import { IMAGE_URL } from '../constants';

const ListingOverviewWidget = ({
    title,
    description,
    image
}: {
    title: string;
    description: String;
    image?: string;
}) => {
    return (
        <div className="brand-widget padding">
            {image && (
                <div className="brand-logo">
                    <img
                        src={IMAGE_URL + image}
                        alt={title}
                        onError={addImageFallback}
                        loading="lazy"
                    />
                </div>
            )}
            <div className="brand-desc">
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default ListingOverviewWidget;
