import { useEffect, useState } from 'react';

import { Select } from 'antd';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import { $FIXME } from '../../constants';
import { setLanguageHeader } from '../../helpers/api';
import { LanguageWithCode } from '../../utils/LanguageWithCode';
import { CaretDown } from 'phosphor-react';

export default function LanguageDropdown(props: $FIXME) {
    const [selectedIndex, setSelectedIndex] = useState(1);
    const { t, i18n } = useTranslation();

    const { Option } = Select;
    let langCode = !!localStorage.getItem('i18nextLng')
        ? localStorage.getItem('i18nextLng')
        : 'en';
    let currentLangCode = LanguageWithCode.findIndex(
        (item) => item.langCode === langCode
    );
    useEffect(() => {
        if (langCode?.length === 2) {
            setSelectedIndex(currentLangCode);
            setLanguageHeader(langCode);
        } else {
            setSelectedIndex(1);
            setLanguageHeader('fr');
            i18n.changeLanguage('fr');
        }
    }, [langCode, currentLangCode]);

    const handleChange = (item: $FIXME) => {
        i18n.changeLanguage(LanguageWithCode[item].langCode);
    };
    const _renderFlag = ({ code: countryCode, langCode }: $FIXME) => {
        return (
            <div className="flag-container">
                <ReactCountryFlag
                    countryCode={countryCode}
                    svg
                    cdnSuffix="svg"
                    aria-label="United States"
                />
                <span className="country-name text-uppercase">
                    {t(langCode)}
                </span>
            </div>
        );
    };

    return (
        <div className={`language-container ${props.class}`}>
            <ReactCountryFlag
                countryCode={LanguageWithCode[selectedIndex]?.code}
                style={{
                    marginRight: props.mobile ? '3px' : '10px',
                    marginBottom: "5px",
                }}
                svg
                cdnSuffix="svg"
                aria-label="United States"
            />
            <label className='display-contents'>
                <Select
                    value={
                        t(LanguageWithCode[selectedIndex]?.langCode)
                    }
                    bordered={false}
                    onChange={handleChange}
                    suffixIcon={
                        <CaretDown size={20} color="#ffffff" weight="bold" />
                    }
                >
                    {LanguageWithCode.map((item, index) => (
                        <Option
                            onClick={() =>
                                i18n.changeLanguage(item?.code.toLocaleLowerCase())
                            }
                            key={index}
                        >
                            {_renderFlag(item)}
                        </Option>
                    ))}
                </Select>
            </label>
        </div>
    );
}
