import { Api } from '../Api';

export const getBodyType = async (bodyType?: string) => {
    try {
        const result = await Api.get(`/car-body-types/${bodyType}`);
        return result;
    } catch (error) {
        return error;
    }
};
