export function formatNumberCompact(number: any) {
    return new Intl.NumberFormat('en', { notation: 'compact' }).format(number);
}

export function decodeCompactNumber(compactValue: any) {
    const regex = /^(\d+\.?\d*)([kKmMbBtT]?)$/;
    const match = compactValue.match(regex);

    if (!match) return null; // Invalid format

    let [_, numStr, suffix] = match;
    let num = parseFloat(numStr);

    switch (suffix.toLowerCase()) {
        case 'k':
            num *= 1000;
            break;
        case 'm':
            num *= 1000000;
            break;
        case 'b':
            num *= 1000000000;
            break;
        case 't':
            num *= 1000000000000;
            break;
    }

    return num;
}
