import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Dropdown, Menu, Space } from 'antd';
import { FireSimple, HeartStraight, ShareNetwork } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    carPageUrl,
    COUNTRY_TOP_LEVEL_DOMAIN,
    CSWIPER,
    IMAGE_URL
} from '../../constants';
import { ICar } from '../../interface/car';
import {
    addToWishList,
    removeFromWishList
} from '../../redux/actions/wishlistAction';
import { ICombineReducerType } from '../../redux/reducers';
import { addImageFallback } from '../../utils/Helper/ImagePlaceholder';
import {
    RemoveWhiteSpacesFromString,
    ReplaceWhiteSpaceWithDashString
} from '../../utils/Helper/jsHelpers';
import { getTopLevelDomain } from '../../utils/translation';
import AuthFlow from '../AuthFlow/AuthFlow';
import { NextArrow, PrevArrow } from '../icons/common.icons';
import { formatMoney } from '../SearchFilter';

interface IPropType {
    item: ICar;
    onClick?: (id: string) => void;
    border?: string;
    newCarFlag?: boolean;
}

export function getDiscountedPriceIfNotSold(price: number, discount: number) {
    const discountedPrice = price - discount;
    return formatMoney(Math.round(discountedPrice));
}

export const shareMenu = (car: ICar) => {
    const url = `${carPageUrl}/${RemoveWhiteSpacesFromString(
        car.brand?.brand
    )}/${ReplaceWhiteSpaceWithDashString(car?.model?.model)}/${
        car?.modelYear
    }/${car?.price}/${car?.uId}`;

    return (
        <Menu
            style={{ paddingLeft: 16, paddingRight: 16, borderRadius: 5 }}
            className="share-menu"
        >
            <Space
                size={16}
                direction="horizontal"
                style={{ paddingTop: 8, paddingBottom: 8 }}
                className="share-space"
            >
                <FacebookShareButton url={url} quote="Auto24">
                    <img
                        alt="facebook"
                        src="/icon/facebook.svg"
                        style={{ width: '24px', height: '24px' }}
                    />
                </FacebookShareButton>
                <LinkedinShareButton title={car.name} url={url}>
                    <img
                        alt="linkedin"
                        src="/icon/linkedin.svg"
                        style={{ width: '24px', height: '24px' }}
                    />
                </LinkedinShareButton>
                <TwitterShareButton
                    url={url}
                    title={car.name}
                    via="auto24.asterdio.xyz"
                >
                    <img
                        alt="twitter"
                        src="/icon/twitter.svg"
                        style={{ width: '24px', height: '24px' }}
                    />
                </TwitterShareButton>
                <WhatsappShareButton url={url} title={car.name}>
                    <img
                        alt="whatsapp"
                        src="/images/auto24-whatsapp.svg"
                        style={{ width: '24px', height: '24px' }}
                    />
                </WhatsappShareButton>
            </Space>
        </Menu>
    );
};
export const LikeOrNot = ({
    id,
    setAuthFlow
}: {
    id: string;
    setAuthFlow: any;
}) => {
    const [liked, setLiked] = useState(false);
    const { wishList } = useSelector(
        (state: ICombineReducerType) => state.wishList
    );
    const dispatch = useDispatch();

    const loggedIn = useSelector(
        (state: ICombineReducerType) => state.auth.isloggedIn
    );
    useEffect(() => {
        if (!loggedIn) {
            setLiked(false);
        }
    }, [loggedIn]);
    useEffect(() => {
        if (id && wishList.length) {
            const likedOrNot = !!wishList?.find((i) => i.car?._id === id);
            setLiked(likedOrNot);
        }
    }, [wishList, id]);
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                if (loggedIn) {
                    if (!liked) {
                        addToWishList(dispatch, id);
                    } else {
                        removeFromWishList(dispatch, id);
                    }
                    setLiked(!liked);
                } else {
                    setAuthFlow(true);
                }
            }}
            className={`pointer  ${liked && 'active'}`}
        >
            {!!id && (
                <HeartStraight
                    size={27}
                    color={!liked ? '#454545' : '#091731'}
                    weight={!liked ? 'regular' : 'fill'}
                />
            )}
        </div>
    );
};
const Card = ({ item, border, newCarFlag = false }: IPropType) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const topLevelDomain = getTopLevelDomain();
    let image = [];
    if (item?.images) image = JSON.parse(item?.images);
    const imageTitle = item?.name?.toLowerCase().split(' ').join('_');
    const goToCarDetails = (car: ICar) => {
        navigate(
            `../${t('/buy', { ns: 'routes' })}/${RemoveWhiteSpacesFromString(
                car.brand?.brand
            )}/${ReplaceWhiteSpaceWithDashString(car?.model?.model)}/${
                car.modelYear
            }/${car.price}/${car.uId}`
        );
    };
    const [showAuthFlow, setAuthFlow] = useState(false);
    const [activeSlide, setActiveSlide] = useState<number>(
        CSWIPER.startOfSlider
    );
    const sliderRef = useRef<any>(null);

    const handlePrev = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
        setActiveSlide((prevActiveSlide) => prevActiveSlide - 1);
    }, []);

    const handleNext = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
        setActiveSlide((prevActiveSlide) => prevActiveSlide + 1);
    }, []);

    const menuConfig = {
        items: [
            {
                key: '1',
                label: shareMenu(item)
            }
        ]
    };

    return (
        <>
            <div
                className={`card ${border ?? ''}`}
                onClick={() => {
                    !!item._id && goToCarDetails(item);
                }}
            >
                {item?.status === 'sold' && (
                    <div className={'card-status sold-tag'}>
                        <span className="card-status-text">{t('sold')}</span>
                    </div>
                )}
                {item?.status !== 'sold' &&
                    String(item?.discount) !== '0' &&
                    String(item?.discount) !== 'null' && (
                        <div className="card-status discount-tag">
                            <FireSimple
                                size={12}
                                weight="fill"
                                color="#e7a333"
                            />
                            <span className="card-status-text">
                                {t('discountOff').replace(
                                    '{{X}}',
                                    `${
                                        topLevelDomain ===
                                        COUNTRY_TOP_LEVEL_DOMAIN.coza
                                            ? process.env
                                                  .REACT_APP_DEFAULT_CURRENCY
                                            : ''
                                    } ${String(item?.discount)} ${
                                        topLevelDomain !==
                                        COUNTRY_TOP_LEVEL_DOMAIN.coza
                                            ? process.env
                                                  .REACT_APP_DEFAULT_CURRENCY
                                            : ''
                                    }`
                                )}
                            </span>
                        </div>
                    )}
                {(item?.isNewCar || item?.isElectric || item?.imported) && (
                    <div className="card-status-flex">
                        {item.isNewCar && (
                            <div className="card-status new-tag">
                                <span>{t('new')}</span>
                            </div>
                        )}
                        {topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.asterdio &&
                            item.isElectric &&
                            location.pathname.includes('/buy') && ( //Only for dev server
                                <div className="card-status new-tag">
                                    <span>Electric</span>
                                </div>
                            )}
                        {topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.asterdio &&
                            item.imported &&
                            location.pathname.includes('/buy') && ( //Only for dev server
                                <div className="card-status new-tag">
                                    <span>Imported</span>
                                </div>
                            )}
                    </div>
                )}

                <div className="card-imageContainer">
                    <Swiper
                        ref={sliderRef as any}
                        className="mySwiper"
                        modules={[Navigation]}
                        navigation={false}
                        speed={1000}
                    >
                        {image
                            .slice(0, 5)
                            .map((item: string, index: number) => {
                                const modifiedUrl =
                                    IMAGE_URL +
                                    item.replace(
                                        /(vehicles\/\d{4}\/\d{2}\/)/,
                                        '$1small/'
                                    );
                                return (
                                    <SwiperSlide key={index}>
                                        <img
                                            // src={`${IMAGE_URL}small/${item}`}
                                            src={modifiedUrl}
                                            alt={`${imageTitle}_img_${
                                                index + 1
                                            }`}
                                            onError={addImageFallback}
                                            loading="lazy"
                                        />
                                    </SwiperSlide>
                                );
                            })}

                        {activeSlide !== 0 && (
                            <button
                                type="button"
                                className="cardArrow cardArrow__left"
                                aria-label="slide-left-arrow"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    handlePrev(e);
                                }}
                            >
                                <PrevArrow />
                            </button>
                        )}

                        {activeSlide !== image.slice(0, 5).length - 1 && (
                            <button
                                type="button"
                                className="cardArrow cardArrow__right"
                                aria-label="slide-right-arrow"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    handleNext(e);
                                }}
                            >
                                <NextArrow />
                            </button>
                        )}
                    </Swiper>
                </div>

                {!!item.brand?.image && (
                    <div className="card-brand-logo">
                        <img
                            src={IMAGE_URL + item.brand.image}
                            alt={item.brand.brand}
                            onError={addImageFallback}
                            loading="lazy"
                        />
                    </div>
                )}

                <div className="card-contents">
                    <span className="card-model">{item?.name}</span>
                    <div className="card-price-buttons">
                        <div>
                            <span className="card-price">
                                {item?.status !== 'sold' &&
                                String(item?.discount) !== '0' &&
                                String(item?.discount) !== 'null' &&
                                String(item?.price) !== '0'
                                    ? `${
                                          topLevelDomain ===
                                          COUNTRY_TOP_LEVEL_DOMAIN.coza
                                              ? process.env
                                                    .REACT_APP_DEFAULT_CURRENCY
                                              : ''
                                      } ${getDiscountedPriceIfNotSold(
                                          item?.price ?? 0,
                                          item.discount ?? 0
                                      )} ${
                                          topLevelDomain !==
                                          COUNTRY_TOP_LEVEL_DOMAIN.coza
                                              ? process.env
                                                    .REACT_APP_DEFAULT_CURRENCY
                                              : ''
                                      }`
                                    : `${
                                          topLevelDomain ===
                                          COUNTRY_TOP_LEVEL_DOMAIN.coza
                                              ? process.env
                                                    .REACT_APP_DEFAULT_CURRENCY
                                              : ''
                                      } ${formatMoney(item.price)} ${
                                          topLevelDomain !==
                                          COUNTRY_TOP_LEVEL_DOMAIN.coza
                                              ? process.env
                                                    .REACT_APP_DEFAULT_CURRENCY
                                              : ''
                                      }`}
                            </span>
                            {item?.status !== 'sold' &&
                                String(item?.discount) !== '0' &&
                                String(item?.discount) !== 'null' && (
                                    <span className="card-old-price">
                                        {`${
                                            topLevelDomain ===
                                            COUNTRY_TOP_LEVEL_DOMAIN.coza
                                                ? process.env
                                                      .REACT_APP_DEFAULT_CURRENCY
                                                : ''
                                        } ${formatMoney(item.price)} ${
                                            topLevelDomain !==
                                            COUNTRY_TOP_LEVEL_DOMAIN.coza
                                                ? process.env
                                                      .REACT_APP_DEFAULT_CURRENCY
                                                : ''
                                        }`}
                                    </span>
                                )}
                        </div>
                        <span className="card-buttons">
                            <div onClick={(e) => e.stopPropagation()}>
                                <Dropdown
                                    menu={menuConfig}
                                    placement="bottomRight"
                                    trigger={['click']}
                                >
                                    <ShareNetwork size={24} color="#454545" />
                                </Dropdown>
                            </div>
                            {item?._id && (
                                <LikeOrNot
                                    id={item._id}
                                    setAuthFlow={setAuthFlow}
                                />
                            )}
                        </span>
                    </div>
                    <div className="card-features">
                        <span className="features-container">
                            <img
                                alt="manualgear"
                                src="/icon/manualgear.svg"
                                loading="lazy"
                            />

                            {item.transmission && t(item.transmission)}
                        </span>

                        <span className="features-container">
                            <img
                                alt="gaspump"
                                src="/icon/gaspump.svg"
                                loading="lazy"
                            />

                            {item.fuelType && t(item.fuelType)}
                        </span>

                        {!newCarFlag && (
                            <span className="features-container">
                                <img
                                    alt="speedometer"
                                    src="/icon/speedometer.svg"
                                    loading="lazy"
                                />
                                {formatMoney(item.mileage)} KM
                            </span>
                        )}
                    </div>

                    <div className="card-bottom">
                        <Space align="center" className="space-container">
                            <div className="card-cert">
                                <img
                                    alt="cretified"
                                    src="/icon/certified.svg"
                                    loading="lazy"
                                />

                                <span>{t('Certified')}</span>
                            </div>
                            <div className="dot"></div>

                            <div className="card-cert">
                                <img
                                    alt="inspected"
                                    src="/icon/inspected.svg"
                                    loading="lazy"
                                />

                                <span>{t('Inspected')}</span>
                            </div>

                            <div className="dot"></div>
                            <div className="card-cert">
                                <img
                                    alt="warranted"
                                    src="/icon/warranted.svg"
                                    loading="lazy"
                                />

                                <span>
                                    {item?.warranty ?? '6'} {t('months')}
                                </span>
                            </div>
                        </Space>
                    </div>
                </div>
            </div>
            {showAuthFlow && (
                <AuthFlow
                    isVisible={showAuthFlow}
                    like_id={item._id}
                    handleCancel={() => {
                        setAuthFlow(!showAuthFlow);
                    }}
                />
            )}
        </>
    );
};

export default Card;
