const SecSeperator = ({
  borderColor,
  bordercolor,
  background,
  backgroundColor,
  className,
}: any) => {
  return (
    <div className={`sec-seperator ${className}`}>
      <span
        className='seperator-first'
        style={{
          borderColor: bordercolor ? bordercolor : '$main-blue',
          backgroundColor: background ? background : '#175ADC',
        }}
      ></span>
      <span
        className='seperator-second'
        style={{
          borderColor: borderColor ? borderColor : '$main-dark-blue',
          backgroundColor: backgroundColor ? backgroundColor : '#091731',
        }}
      ></span>
    </div>
  )
}

export default SecSeperator
