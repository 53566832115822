import { CSSProperties } from 'react';

interface IProps {
    style?: CSSProperties;
    id?: string;
}

const Loading = ({ style, id }: IProps) => {
    return (
        <div className="lds-roller" style={style} id={id ?? ""}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default Loading;
