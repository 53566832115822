import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import LoadingButton from './LoadingButton';

interface Props {
    visible: boolean;
    cancelHandler: () => void;
    okHandler: () => void;
    loading?: boolean;
}

const LogoutModal = ({ visible, cancelHandler, okHandler, loading }: Props) => {
    const { t } = useTranslation();
    return (
        <Modal
            className="delete-modal logout ants-modal"
            title={t('logoutModelTitle')}
            open={visible}
            onCancel={cancelHandler}
            closable={false}
            centered={true}
        >
            <div className="modal-btn-container">
                <button className="cancel" onClick={cancelHandler}>
                    {t('cancel')}
                </button>
                {loading ? (
                    <LoadingButton buttonText="loggingout" />
                ) : (
                    <button className="logout-btn" onClick={okHandler}>
                        {t('logout')}
                    </button>
                )}
            </div>
        </Modal>
    );
};

export default LogoutModal;
