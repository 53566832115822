const metaZulu = {
    homePageTitle:
        'AUTO24.co.za - Gesertifiseerde Gebruikte Motors in Suid-Afrika | Koop & Verkoop Plaaslik',
    homePageDesc:
        "Soek jy 'n gesertifiseerde gebruikte motor in Suid-Afrika? AUTO24.co.za is jou plaaslike mark vir die koop en verkoop van kwaliteit gebruikte motors.",
    homePageKeywords:
        'Gesertifiseerde Gebruikte Motors, Koop Motors Suid-Afrika, Verkoop Motors, Motor Mark, AUTO24 Suid-Afrika',
    homePageTitleOg:
        'AUTO24.co.za - Gesertifiseerde Gebruikte Motors in Suid-Afrika | Koop & Verkoop Plaaslik',
    homePageDescOg:
        "Soek jy 'n gesertifiseerde gebruikte motor in Suid-Afrika? AUTO24.co.za is jou plaaslike mark vir die koop en verkoop van kwaliteit gebruikte motors.",
    homePageKeywordsOg:
        'Gesertifiseerde Gebruikte Motors, Koop Motors Suid-Afrika, Verkoop Motors, Motor Mark, AUTO24 Suid-Afrika',
    buyPageTitle:
        'Izimoto Ezithengiswayo eNingizimu Afrika | Thola amanani amahle ezimoto namuhla - auto24.co.za',
    buyPageDesc:
        "Verken 'n wye reeks gesertifiseerde gebruikte {{ BRAND }} motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    buyCarKeywords:
        'Koop Motors, Gesertifiseerde Gebruikte Motors, Kwaliteit Motors, Bekostigbare Motors',
    buyPageTitleOg:
        'Izimoto Ezithengiswayo eNingizimu Afrika | Thola amanani amahle ezimoto namuhla - auto24.co.za',
    buyPageDescOg:
        "Verken 'n wye reeks gesertifiseerde gebruikte {{ BRAND }} motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    buyCarKeywordsOg:
        'Koop Motors, Gesertifiseerde Gebruikte Motors, Kwaliteit Motors, Bekostigbare Motors',
    sellPageTitle:
        'Thengisa Imoto Yakho Ngokushesha & Kalula | Thola Izicelo Zokuthola Imali Namuhla - auto24.co.za',
    sellPageDesc:
        'Ufuna ukuthengisa imoto yakho? Thola isikhokhelo semali ngokushesha ku-auto24.co.za! Ngohlelo olusheshayo futhi olungaphazamiseki, ungathengisa imoto yakho namhlanje futhi uthole imali ngokushesha. Ungalindi—bona ukuthi imoto yakho iyabiza kanjani manje!',
    sellPageKeywords:
        'Verkoop Motors, Gebruikte Motor Verkope, Vinnige Verkope, Motor Evaluering',
    sellPageTitleOg:
        'Thengisa Imoto Yakho Ngokushesha & Kalula | Thola Izicelo Zokuthola Imali Namuhla - auto24.co.za',
    sellPageDescOg:
        'Ufuna ukuthengisa imoto yakho? Thola isikhokhelo semali ngokushesha ku-auto24.co.za! Ngohlelo olusheshayo futhi olungaphazamiseki, ungathengisa imoto yakho namhlanje futhi uthole imali ngokushesha. Ungalindi—bona ukuthi imoto yakho iyabiza kanjani manje!',
    sellPageKeywordOg:
        'Verkoop Motors, Gebruikte Motor Verkope, Vinnige Verkope, Motor Evaluering',
    aboutPageTitle:
        'Oor AUTO24.co.za - Jou Betroubare Plaaslike Gebruikte Motor Mark',
    aboutPageDesc:
        'Leer meer oor AUTO24.co.za, Suid-Afrika se voorste gesertifiseerde gebruikte motor mark.',
    aboutPageKeywords:
        'Oor AUTO24, Gesertifiseerde Gebruikte Motor Mark, Betroubare Motor Verkope',
    aboutPageTitleOg:
        'Oor AUTO24.co.za - Jou Betroubare Plaaslike Gebruikte Motor Mark',
    aboutPageDescOg:
        'Leer meer oor AUTO24.co.za, Suid-Afrika se voorste gesertifiseerde gebruikte motor mark.',
    aboutPageKeywordsOg:
        'Oor AUTO24, Gesertifiseerde Gebruikte Motor Mark, Betroubare Motor Verkope',
    financePageTitle:
        'Izimali Zemoto Zenziwe Zilula | 100% Ku-inthanethi & Amanani Asezingeni Eliphansi - auto24.co.za',
    financePageDesc:
        'Hlola izimali zemoto ngaphandle kokuphazamiseka ku-Auto24.co.za - 100% ku-inthanethi, amanani aphansi kanye nemigomo eshintshashintshayo. Thola imoto yakho oyiphupho namuhla!',
    financePageKeywords:
        'Motor Finansiering, AUTO24 Finansiering, Maklike Finansiering',
    financePageTitleOg:
        'Izimali Zemoto Zenziwe Zilula | 100% Ku-inthanethi & Amanani Asezingeni Eliphansi - auto24.co.za',
    financePageDescOg:
        'Hlola izimali zemoto ngaphandle kokuphazamiseka ku-Auto24.co.za - 100% ku-inthanethi, amanani aphansi kanye nemigomo eshintshashintshayo. Thola imoto yakho oyiphupho namuhla!',
    financePageKeywordOg:
        'Motor Finansiering, AUTO24 Finansiering, Maklike Finansiering',
    insurePageTitle:
        'Qinisekisa Umshwalense Wokuthenga Izimoto, Ukuvikeleka Okuphelele KwiXabiso Eliphansi - auto24.co.za',
    insurePageDesc:
        'Thola umshwalense wemoto ongcono ngamanani aphansi ku-auto24.co.za. Sikunikeza ukuvikeleka okungaphazamiseki okuhlangabezana nezidingo zakho—vikela imoto yakho namuhla!',
    insurePageKeywords:
        'Motor Versekering, AUTO24 Versekering, Voertuig Dekking',
    insurePageTitleOg:
        'Qinisekisa Umshwalense Wokuthenga Izimoto, Ukuvikeleka Okuphelele KwiXabiso Eliphansi - auto24.co.za',
    insurePageDescOg:
        'Thola umshwalense wemoto ongcono ngamanani aphansi ku-auto24.co.za. Sikunikeza ukuvikeleka okungaphazamiseki okuhlangabezana nezidingo zakho—vikela imoto yakho namuhla!',
    insurePageKeywordOg:
        'Motor Versekering, AUTO24 Versekering, Voertuig Dekking',
    blogPageTitle:
        'Amathiphu Okugcinwa Kwezimoto kanye Nezindaba Zemboni - auto24.co.za',
    blogPageDesc:
        'Vakashela auto24.co.za ukuthola amathiphu wokugcinwa kwezimoto, izitayela zemboni, kanye nezindaba ezintsha zomkhakha wezithuthi. Hlala phambili ekunakekeleni izimoto zakho nasemazingeni emisha embonini yezimoto!',
    blogPageKeywords:
        'AUTO24 Blog, Motor Wenke, Motor Nuus, Suid-Afrikaanse Motor Mark',
    blogPageTitleOg:
        'Amathiphu Okugcinwa Kwezimoto kanye Nezindaba Zemboni - auto24.co.za',
    blogPageDescOg:
        'Vakashela auto24.co.za ukuthola amathiphu wokugcinwa kwezimoto, izitayela zemboni, kanye nezindaba ezintsha zomkhakha wezithuthi. Hlala phambili ekunakekeleni izimoto zakho nasemazingeni emisha embonini yezimoto!',
    blogPageKeywordsOg:
        'AUTO24 Blog, Motor Wenke, Motor Nuus, Suid-Afrikaanse Motor Mark',
    qNaPageTitle: 'AUTO24 - Frequently asked questions ',
    contactUsPageTitle: ' AUTO24 - Contact us',
    privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
    termsNusePageTitle: 'AUTO24 - Terms & conditions',
    carDetailPageTitle: 'Buy {{make}},{{price}} R - AUTO24',
    carDetailPageDesc:
        'Buy a {{make}} certified used car at {{PRIX}} R with AUTO24, South Africa',
    buyPageDescWhenBrand:
        'Best prices and services for {{BRAND}} used cars in South Africa. Refund policy, warranty, insurance, maintenance, and all included!',
    buyCarTitle:
        'Koop Gesertifiseerde Kwaliteit Gebruikte Motors in Suid-Afrika | AUTO24.co.za',
    buyCarDesc:
        "Verken 'n wye reeks gesertifiseerde gebruikte motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    carFinanceFormPageTitle: `Thenga incoko yakho yemoto ethengisiwe nge-AUTO24.co.za`,
    carFinanceFormPageDesc: `Fumela izinhlelo ezinhle zokufaka imoto ethengiselwe ngo-AUTO24.co.za, futhi uxhumane nabathengisi bethu ukuze bakunikeze usizo manje! Izicelo ezisheshayo!`,
    carFinanceFormPageTitleOg: `Thenga incoko yakho yemoto ethengisiwe nge-AUTO24.co.za`,
    carFinanceFormPageDescOg: `Fumela izinhlelo ezinhle zokufaka imoto ethengiselwe ngo-AUTO24.co.za, futhi uxhumane nabathengisi bethu ukuze bakunikeze usizo manje! Izicelo ezisheshayo!`,
    brandPageTitle:
        '{{ BRAND }} izimoto zithengiswa South Africa | Thola intengo engcono kwi-AUTO24 Senegal',
    bodyTypePageTitle:
        '{{ BODY_TYPE }} izimoto zithengiswa South Africa | Thola intengo engcono kwi-AUTO24 Senegal',
    carsUnderPageTitle:
        'Thenga izimoto ezingaphansi kwe-{{ PRICE }} South Africa | Thola intengo engcono kwi-AUTO24 Senegal',
    carsUnderPageDesc:
        "Verken 'n wye reeks gesertifiseerde gebruikte motors under {{ PRICE }} by AUTO24.co.za. Vind jou ideale motor maklik en vinnig."
};

export default metaZulu;
