import { formatMoney } from '../../components/SearchFilter';
import { ICar } from '../../interface/car';

const GetSpecification = (car: ICar) => {
    const Specification = [
        {
            icons: '/images/enginepower.svg',
            title: 'enginepower',
            value: car.enginePower,
            omit: false
        },
        {
            icons: '/images/fuelconsumption.svg',
            title: 'fuelConsumption',
            value: car.fuelConsumption,
            omit: false
        },
        {
            icons: '/svgs/carbonemission.svg',
            title: 'emissionCO2',
            value: car.emissionCO2,
            omit: false
        }
    ];
    return Specification.filter((item) => {
        return (!!item.value && !item.omit);
    });
};

export default GetSpecification;

export const GetSpecificationWeight = (car: ICar) => {
    const Specification = [];

    if(car?.weight || car?.weight === 0) {
        Specification.push({
            icons: '/images/weight.svg',
            title: 'weight',
            value: String(car?.weight),
            unit: 'kg',
            omit: false
        })
    }

    if(
        (car?.dimension?.length || car?.dimension?.length === 0) &&
        (car?.dimension?.breadth || car?.dimension?.breadth === 0) &&
        (car?.dimension?.height || car?.dimension?.height === 0)
    ) {
        Specification.push({
            icons: '/images/length.svg',
            title: 'lengthWidthHeight',
            value: `${formatMoney(car?.dimension?.length)} mm, ${formatMoney(
                car.dimension?.breadth
            )} mm, ${formatMoney(car?.dimension?.height)} mm`,
            unit: '',
            omit: false
        });
    }

    if((car?.fullTankCapacity || car?.fullTankCapacity === 0) && !car?.isElectric) {
        Specification.push({
            icons: '/images/fueltankcapcity.svg',
            title: 'fuelTankCapacity',
            value: String(car.fullTankCapacity),
            unit: 'L',
            omit: car.isNewCar
        });
    }
    return Specification.filter(item => !item.omit);
};
