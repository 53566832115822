import axios from 'axios';
import { Dispatch } from 'redux';
import { GET_COUNTRIES, numVerifyKey } from '../../helpers/constants';
import { PhoneValidationResponse } from '../../interface/country';
import { $FIXME } from '../../constants';
import { Api } from '../Api';
import config from '../../utils/config';

export const getCountries = (dispatch: Dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append('apikey', numVerifyKey);

    var requestOptions: RequestInit = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    fetch(
        'https://api.apilayer.com/number_verification/countries',
        requestOptions
    )
        .then((response) => console.log(response.text))
        .then((result) => dispatch({ type: GET_COUNTRIES, payload: result }))
        .catch((error) => console.log('error', error));
};

export const validateNumber = async (code: string, number: string) => {
    var myHeaders = new Headers();
    myHeaders.append('apikey', numVerifyKey);

    var requestOptions: RequestInit = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    return fetch(
        `https://api.apilayer.com/number_verification/validate?number=${code}${number}`,
        requestOptions
    )
        .then((response) => response.text())
        .then((result) =>
            Promise.resolve(JSON.parse(result) as PhoneValidationResponse)
        )
        .catch((error) => Promise.reject(error));
};
export const getCurrency = (
    to: string,
    from: string,
    amount: string | number
) => {
    const options = {
        method: 'GET',
        url: `${config.backendUrl}/api/forex-conversion?toCurrency=${to}&fromCurrency=${from}&amount=${amount}&format=json`,
    };
    return axios
        .request(options)
        .then((res) => {
            let data = res.data;
            if (data.success) {
                return res.data.result;
            }
        })
        .catch((err) => console.log('error', err));
};

export const getCountryForPricingModule = async (params?: $FIXME) => {
    try {
        const result = await Api.get(`/pricing/countries`);
        return result;
    } catch (error) {
        return error;
    }
};
