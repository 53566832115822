const metaWolof = {
    homePageTitle: 'Certified used cars in Senegal with AUTO24',
    homePageDesc:
        'Used car price, new cars service! Buy reconditioned and certified used cars with 6 to 12 months warranty, all inspected by our auto experts !',
    sellPageTitle:
        'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.sn',
    sellPageDesc:
        "Looking to sell your car?Get a top cash offer instantly at auto24.sn! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
    buyPageTitle:
        'Cars for Sale in Senegal| Get the best prices on cars today-auto24.sn',
    buyPageDesc:
        'Find the best prices for used {{ BRAND }} cars to buy in Dakar, Senegal, with 100 points inspection, 5 days refund policy, 6 months warranty and more.',
    financePageTitle:
        'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.sn',
    financePageDesc:
        'Explore hassle-free car finance with Auto24.sn-100% online, competitive rates and flexible terms. Secure your dream car today!',
    aboutPageTitle: ' AUTO24, a new way to buy and sell cars in Africa',
    blogPageTitle: 'Car Maintenance Tips & Auto Industry Insights-auto24.sn',
    blogPageDesc:
        'Visit Auto24.sn for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
    qNaPageTitle: 'AUTO24 - Frequently asked questions ',
    contactUsPageTitle: ' AUTO24 - Contact us',
    privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
    termsNusePageTitle: 'AUTO24 - Terms & conditions',
    insurePageTitle:
        'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.sn',
    insurePageDesc:
        'Obtain the best car insurance policy at the best price with Auto24.sn. Here to provide stress-free coverage tailored for you - protect your car today!',
    carDetailPageTitle: 'Buy {{make}},{{price}} FCFA - AUTO24',
    carDetailPageDesc:
        'Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Senegal',
    buyPageDescWhenBrand:
        'Best prices and services for {{BRAND}} used cars in Senegal. Refund policy, warranty, insurance, maintenance, and all included!',
    brandPageTitle:
        '{{ BRAND }} xeeti fëri ci Senegal | Jëndee benn jaamu ci AUTO24 Senegal',
    bodyTypePageTitle:
        '{{ BODY_TYPE }} xeeti fëri ci Senegal | Jëndee benn jaamu ci AUTO24 Senegal',
    carsUnderPageTitle:
        'Jëndee xeeti fëri bu mujj ci {{ PRICE }} ci Senegal | Jëndee benn jaamu ci AUTO24 Senegal',
    carsUnderPageDesc:
        'Find the best prices for used cars under {{ PRICE }} to buy in Dakar, Senegal, with 100 points inspection, 5 days refund policy, 6 months warranty and more.'
};

export default metaWolof;
