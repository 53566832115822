import { Select } from 'antd';
import { CaretDown } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const Selects = (props: any) => {
    const { t } = useTranslation();

    return (
        <div className={`selects-container ${props.className} `}>
            <div
                className={`selects ${props.error && 'border-error'} ${
                    props.class
                } `}
            >
                {props.prefixicon && (
                    <div className="selects-prefixicon">{props.prefixicon}</div>
                )}
                <Select
                    placeholder={props.placeholder}
                    showArrow={true}
                    showSearch={props.showSearch}
                    optionFilterProp="children"
                    bordered={false}
                    suffixIcon={
                        <CaretDown size={24} color="#b3b3b3" weight="bold" />
                    }
                    value={props.value ? props.value : null}
                    onChange={(e) => props.onSelect(e, props.name)}
                    disabled={props.disabled}
                    mode={props.mode}
                    dropdownRender={props?.dropdownRender}
                    maxTagCount={props?.maxTagCount}
                    popupClassName="selects-dropdown"
                    defaultValue={props.defaultValue}
                    allowClear={props.mode !== 'multiple' && true}
                >
                    {props?.options?.map((items: any, index: any) => {
                        return (
                            <Option value={items.value} key={index}>
                                {props.colors && (
                                    <div
                                        className="option-color"
                                        style={{
                                            backgroundColor:
                                                items.backgroundcolor
                                        }}
                                    >
                                        {' '}
                                    </div>
                                )}
                                {t(items.label)}
                            </Option>
                        );
                    })}
                </Select>
            </div>

            {!!props.error && (
                <span className={`error-label ${!!props.error && 'fade-in'}`}>
                    {props.error}
                </span>
            )}
        </div>
    );
};

export default Selects;
