import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form, message } from 'antd';
import {
    EnvelopeSimple,
    LockSimple,
    Phone,
    SpinnerGap,
    User
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import { useForm } from 'antd/lib/form/Form';
import { $FIXME } from '../../constants';
import { defaultDailing_Code, getCountryByCode } from '../../helpers/countryCode';
import { getNumbersOnly } from '../../helpers/functions';
import { register } from '../../redux/actions/authActions';
import ButtonComponent from '../ui/ButtonComponent';
import PhoneNumberInput from '../ui/PhoneNumberInput';
import TextBox from '../ui/TextBox';
interface Props {
    handleActiveModal: (id: number) => void;
    handleCancel: () => void;
    setEmail: $FIXME;
    setNumber: $FIXME;
    setToken: $FIXME;
}

const SignUpForm = ({
    handleActiveModal,
    handleCancel,
    setEmail,
    setNumber,
    setToken
}: Props) => {
    const { t } = useTranslation();

    const signUpFormData = [
        {
            placeHolder: 'enterFullName',
            valuePrefix: 'name',
            prefix: <User weight="bold" />,
            type: 'text',
            rules: [{ required: true, message: t('pleaseInputYourName') }]
        },
        {
            placeHolder: 'enteremailAddress',
            valuePrefix: 'email',
            prefix: <EnvelopeSimple weight="bold" />,
            type: 'email',
            rules: [
                { required: true, message: t('pleaseInputYourEmail') },
                {
                    type: 'email',
                    message: t('pleaseInputValidEmail')
                }
            ]
        },
        {
            placeHolder: 'phoneNumber',
            valuePrefix: 'number',
            prefix: <Phone weight="bold" />,
            type: 'phone-number',
            rules: [
                { required: true, message: t('pleaseInputYourPhoneNumber') },
                { max: 10, message: t('maximumXdigitsisallowed', { X: 10 }) },
                { min: 10, message: t('minimumXdigit', { X: 10 }) }
            ]
        },
        {
            placeHolder: 'enterPassword',
            valuePrefix: 'password',
            prefix: <LockSimple weight="bold" />,
            type: 'password',
            rules: [
                { required: true, message: t('passwordisRequired') },
                { min: 8, message: t('minimum8chars') }
            ]
        }
    ];
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        phoneNumber: {
            code: defaultDailing_Code,
            number: ''
        }
    } as any);
    const [phoneValidErr, setPhoneValidErr] = useState({
        number: true
    });
    const dispatch = useDispatch();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form] = useForm();

    useEffect(() => {
        handleFieldChange();
    }, [formData])

    const onSelect = (value: string, key: string) => {
        setFormData((prev: any) => ({
            ...prev,
            phoneNumber: {
                ...prev.phoneNumber,
                [key]: value
            }
        }));
    };

    const handleChange = (key: any, value: any) => {
        if (key === 'number') {
            setFormData((prev: any) => ({
                ...prev,
                phoneNumber: {
                    ...prev.phoneNumber,
                    [key]: value
                }
            }));
        } else {
            setFormData((formData: any) => ({ ...formData, [key]: value }));
        }
    };

    function backendError(message: string, fieldName: string) {
        form.setFields([
            {
                name: fieldName,
                errors: [message]
            }
        ]);
        handleFieldChange();
    }

    const handleSubmit = async () => {
        setLoading(true);
        setPhoneValidErr((prevErr: any) => ({
            ...prevErr,
            number: true
        }));
        setEmail(formData.email);
        setNumber({
            code: formData.phoneNumber.code,
            number: formData.phoneNumber.number
        });
            let { email, password, name, phoneNumber } = formData;
            register(dispatch, { email, password, name, ...phoneNumber })
                .then((data) => {
                        message.success(data.msg);
                        handleCancel();
                    // }
                })
                .catch((err) => {
                    if (err.err?.email) {
                        backendError(err.err?.email, 'email');
                    } else {
                        backendError(err?.msg, 'number');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
    };
    const handleFieldChange = () => {
        const formErrors = form.getFieldsError();
        const buttonDisabledCheck = formErrors.filter(
            (field) => field.errors.length > 0
        );
        setButtonDisabled(
            buttonDisabledCheck.length !== 0 || !formData?.name || !formData?.phoneNumber?.number || phoneValidErr?.number
        );
    };
    function fullNameValidate() {
        if (formData.name) {
            let fullNameValidate =
                formData.name.split(' ').filter((item: any) => item).length > 1;
            if (!fullNameValidate) {
                backendError(t('pleaseEnterFullName'), 'name');
            }
        }
    }

    return (
        <div className="signup-form-wrapper">
            <Form
                onFinish={handleSubmit}
                onFieldsChange={handleFieldChange}
                autoComplete="false"
                form={form}
                onBlur={(e) => {
                    if (e.target.name === 'name') {
                        fullNameValidate();
                    }
                }}
            >
                {signUpFormData.map((formType: any, idx: any) => {
                    if(formType.type === 'phone-number')
                        return (
                            <PhoneNumberInput
                            key={idx}
                            onChange={(value: any, key: string) => {
                                setFormData((prev: any) => ({
                                    ...prev,
                                    phoneNumber: {
                                        ...prev.phoneNumber,
                                        [key]: value[key],
                                        code: value?.code
                                    }
                                }));
                                form.setFieldValue('number', value[key])
                            }}
                            name="number"
                            value={getNumbersOnly(`${formData?.phoneNumber?.number}`)}
                            defaultSelect={formData?.phoneNumber?.code ? getCountryByCode(formData?.phoneNumber?.code) : getCountryByCode(defaultDailing_Code)}
                            setError={setPhoneValidErr}
                            style={{
                                marginBottom: '24px'
                            }}
                            />
                        )
                    return (
                        <Form.Item
                            name={formType.valuePrefix}
                            rules={formType.rules}
                            className={
                                idx !== signUpFormData.length - 1
                                    ? 'user-input-box margin'
                                    : 'user-input-box'
                            }
                            key={idx * 10}
                        >
                            <TextBox
                                placeholder={t(formType.placeHolder)}
                                type={formType.type}
                                onChange={(data) =>
                                    handleChange(formType.valuePrefix, data)
                                }
                                onSelect={onSelect}
                                prefix={formType.prefix}
                                value={formData[formType.valuePrefix]}
                                autoComplete="false"
                                name={formType.valuePrefix}
                            />
                        </Form.Item>
                    )
                })}

                <span className="terms-condt">
                    {t('byClickingXButton', { name: t('signUp') })}{' '}
                    <Link
                        to={t('/terms-of-use', { ns: 'routes' })}
                        className="termandpolicy"
                    >
                        {t('termOfUse')}
                    </Link>{' '}
                    {t('and')}{' '}
                    <Link
                        to={t('/privacy-policy', { ns: 'routes' })}
                        className="termandpolicy"
                    >
                        {t('privacyPolicy')}
                    </Link>
                </span>
                <div>
                    <Form.Item>
                        <ButtonComponent
                            type="primary"
                            htmlType="submit"
                            disabled={loading || buttonDisabled}
                            className={`sign-up-button text-uppercase ${
                                loading ? 'loading-btn' : ''
                            }`}
                        >
                            {loading ? (
                                <>
                                    <SpinnerGap
                                        size={32}
                                        weight="bold"
                                        className="rotate"
                                    />
                                    {t('signingUp')}
                                </>
                            ) : (
                                t('signUp')
                            )}
                        </ButtonComponent>
                    </Form.Item>
                </div>
            </Form>
            <div className="sign-in-redirect">
                {t('alreadyhaveanAccount')}{' '}
                <span onClick={() => handleActiveModal(1)}>{t('signIn')}</span>
            </div>
        </div>
    );
};
export default SignUpForm;
