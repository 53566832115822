const commonZulu = {
    and: 'Futhi',
    buy: 'Thenga',
    sell: 'Thengisa',
    send: 'Thumela',
    finance: 'Ezezimali',
    about: 'Mayelana',
    blogs: 'Amabhulogi',
    blog: 'Ibhulogi',
    callUsAt: 'Sishayele',
    signIn: 'Ngena ngemvume',
    signUp: 'Bhalisela',
    or: 'Noma',
    insure: 'Umshwalense',
    enteremailAddress: 'Faka ikheli le-imeyili',
    enterPassword: 'Faka iphasiwedi',
    rememberMe: 'Ngikhumbule',
    forgotPassword: 'Ukhohlwe iphasiwedi',
    carsAvailable: 'Izimoto ezitholakalayo manje',
    cancel: 'Khansela',
    enterFullName: 'Faka igama eligcwele',
    byClickingXButton: 'By clicking {{name}} Button, you agree to our',
    termOfUse: 'Imigomo yokusebenzisa',
    privacyPolicy: 'Inqubomgomo yobumfihlo',
    alreadyhaveanAccount: 'Usunayo i-akhawunti?',
    howItWorks: 'Isebenza kanjani',
    howtoBuy: 'Kanjani ukuthenga',
    howtoSell: 'Kanjani ukuthengisa',
    exploreBlogs: 'Hlola amabhulogi',
    car: 'Imoto',
    search: 'Sesha',
    Certified: 'Eqinisekisiwe',
    Inspected: 'Ihlowe',
    ev: 'Ugesi',
    hybrid: 'Ingxubevange',
    phev: 'PHEV',
    petrol: 'Uphethiloli',
    diesel: 'Udizili',
    carburant: 'Carburant',
    other: 'Okunye',
    automatic: 'Okuzenzakalelayo',
    pleaseprovideusyourdetails: 'Sicela usinikeze imininingwane yakho',
    pleaseprovideusyourcardetails:
        'Sicela usinikeze imininingwane yemoto yakho',
    desiredPleaseprovideusyourcardetails:
        'Sicela usinikeze imininingwane yemoto oyifunayo, khetha okungenani eyodwa',
    phonenumberisnotvalid: 'Inombolo yocingo ayivumelekile',
    internalError: 'Iphutha langaphakathi. Sicela uzame futhi noma usithinte.',
    knowaboutyourcarbetterandgetevaluation:
        'Yazi ngemoto yakho kangcono futhi uthole ukulinganiswa kwedili elingcono',
    cardetailssendSuccessfully: 'Imininingwane yemoto ithunyelwe ngempumelelo',
    thanyouforprovidingusdetail:
        'Siyabonga ngokusinika imininingwane. Sizokufonela maduze.',
    selectbrand: 'Khetha uhlobo',
    selectcountry: 'Select country',
    enterMileage: 'Enter Mileage',
    minMileage: 'Minimum Mileage',
    maxMileage: 'Maximum Mileage',
    selectmodel: 'Khetha imodeli',
    selectYear: 'Khetha unyaka',
    selectorenterbrand: 'Khetha noma ufake ibhrendi',
    selectorentermodel: 'Khetha noma ufake imodeli',
    selectorenterbodyType: 'Khetha noma faka uhlobo lomzimba',
    brand: 'Uphawu',
    model: 'Imodeli',
    modelYear: 'unyaka oyimodeli',
    selectFuelType: 'Khetha uhlobo lukaphethiloli',
    selectACondition: 'Khetha umbandela',
    phoneNumber: 'Inombolo yocingo',
    loanTitle: 'Sikunikeza okunikezwayo okuhle kakhulu, thola inzalo engu-10%',
    loanHappyCusSubtitle:
        'Siyawakhathalela amakhasimende ethu kanye nenjabulo yawo okusikhuthaza ukuthi sihlinzeke ngamasevisi amaningi',
    loanButton: 'Bala imali engiyibolekile',
    calculateButton: 'Calculate',
    blogTitle: 'Bheka ngaphambi kokuthenga imoto',
    blogSubtitle:
        'Lezi izinto okumele uzazi ngaphambi kokuthenga noma yiluphi uhlobo lwemoto',
    viewAllCars: 'Buka zonke izimoto',
    availableNowOn: 'Iyatholakala manje',
    account: 'i-Akhawunti',
    myAccount: 'i-Akhawunti yami',
    ourBrands: 'Imikhiqizo yethu',
    contactUs: 'Xhumana nathi',
    contactInformation: 'Ulwazi lokuxhumana',
    aboutUs: 'Mayelana nathi',
    addressLine: 'Ulayini we kheli',
    contact: 'Contact',
    whyChooseAuto: 'Why Choose AUTO24?',
    allcarshavebeenthoroughlyinspectedandreconditionedHowweguaranteecarquality:
        'Zonke izimoto zihlolisiwe futhi zalungiswa kabusha. Siqinisekisa kanjani izinga lemoto',
    imInterested: 'Nginentshisekelo',
    buyOnline: 'Buy Online',
    downPayment: 'Inkokhelo yokubambisa',
    hotDeal: 'Isivumelwana esishisayo',
    lowPrice: 'Intengo ephansi',
    likeNew: 'Njengokusha',
    moneybackSubtitle: "You don't like the car? We refund you",
    warrentyTitle: 'Iwaranti yezinyanga eziyisi-6',
    warrentySubtitle:
        'Inwetshwa ibe yizinyanga eziyi_12 enjinini, ebhokisini legiya kanye naku-powertrain',
    inspectionPointsSubtitle:
        'Ihlolwe ithimba lethu lochwepheshe bezimoto kuwo wonke ama-engeli',
    roadSideAssistanceTitle: 'Usizo lonyaka owodwa',
    roadSideAssistanceSubtitle:
        'Usizo lomgwaqo olufakwe kuzo zonke izimoto zethu, unyaka owodwa',
    deliveryTitle: 'Ukulethwa ekhaya',
    deliverySubtitle: 'We can deliver your car anywhere in South Africa',
    xDeliverySubtitle: 'We can deliver your car anywhere in {{country}}',
    Summary: 'Isifinyezo',
    engine: 'Injini',
    enginePower: 'Injini',
    fuelType: 'Uhlobo lwamafutha',
    bodyType: 'Uhlobo lomzimba',
    numberofkeys: 'Inombolo yezihluthulelo',
    exteriorColor: 'Umbala wangaphandle',
    interiorColor: 'Umbala wangaphakathi',
    engineSize: 'Usayizi wenjini',
    fiscalPower: 'Amandla ezimali',
    specifications: 'Imininingwane',
    lengthWidthHeight: 'Ubude, ububanzi & ukuphakama',
    weight: 'Isisindo',
    emissionCO2: 'Ukukhishwa kwe-CO2',
    fuelConsumption: 'Ukusetshenziswa kukaphethiloli',
    enginepower: 'Amandla enjini',
    fuelTankCapacity: 'Umthamo wethangi likaphethiloli',
    'performance&Economy': 'Ukusebenza',
    features: 'Izici',
    'weight&Dimensions': 'Isisindo & izilinganiso',
    showMoreFeatures: 'Khombisa izici ezengeziwe',
    showLess: 'Khombisa okuncane',
    serviceHistory: 'Umlando wesevisi',
    whyAUTO24: 'Kungani i-AUTO24',
    serviceCenter: 'Isikhungo senkonzo',
    Kilometers: 'Km',
    lookingForaCarQtellUs: 'Ufuna imoto? Sitshele!',
    sellPageTitle: 'Thengisa imoto yakho ngezinyathelo ezimbili ezilula',
    sellPageSubtitle:
        'Unganikeza imininingwane ngemoto yakho futhi sizokufonela ukuze uthole amadili angeziwe',
    findCarPricePageTitle: 'Find The Price of Your Car',
    findCarPricePageSubtitle:
        'You can provide details about your car and we will call you back for futher deals.',
    whyFinanceYourCarwithAuto24: 'Kungani ukhokhela ibanga lakho nge-AUTO24',
    financeSubtitle:
        'Sikunikeza iminikelo yezimali engcono kakhulu enenzalo ephansi kanye nezinkokhelo eziphansi',
    competitiveRates: 'Amanani okuncintisana',
    competitiveDesc: 'I-APR kusuka ku-5% (inkomba)',
    competitiveDescMA: 'Amathuba esikweletu samahhala ngaphansi kwemibandela',
    flexiableDuration: 'Ubude besikhathi obuvumelana nezimo',
    flexiableDes:
        'Khokhela imoto yakho ngezitolimende zanyanga zonke eziyi-12 kuya kwezingu-72',
    Percent100OnlineApproach: 'Indlela ye-intanethi engu-100%',
    Percent100OnlineApproachDesc: 'Sayina wonke amadokhumenti akho ekhaya',
    loanCalculatorTitle: 'Umshini wokubala wokubolekwa kwemoto',
    loadCalculatorSubtitle:
        'Isilinganiso senzalo sanyanga zonke singahluka njengoba sibambisene nezikhungo ezahlukene zezimali',
    interestRate: 'Isilinganiso senzalo',
    years: 'Iminyaka',
    ourFinancingPartners: 'Uzakwethu wezezimali',
    ourInsurePartners: 'Uzakwethu womshwalense',
    ourPartners: 'Ozakwethu',
    copyrightfooter: 'Copyright Â© Africar Group Pty Ltd - All rights reserved',
    insureSubtitle:
        'Ujabulele umshwalense ongcono kakhulu ngentengo engcono kakhulu',
    aboutFinanceSub: 'Naba abanye bozakwethu bethu bezemali',
    selectMileageRange: 'Khetha ibanga lemayela',
    selectTransmissionType: 'Khetha uhlobo lokudlulisela',
    backToHome: 'Ngibuyele ekhaya',
    getACallBack: 'Uthole ucingo emuva',
    personalDetails: 'Imininingwane ekhombisa wena',
    carDetails: 'Imininingwane yemoto',
    ourBrandsSubtitle:
        'Sihlotshanisa nemoto yohlobo oluphezulu esiwine imiklomelo eminingi ngokusebenza kahle kakhulu',
    enterCarDetails: 'Enter Car Details',
    ourTeam: 'Amaqembu ethu',
    findCarPrice: 'Isivikelo sokuthengisa',
    teamMembers: 'Abantu bethu',
    ourTeamSubtitle: 'Sazi kangcono ngemicabango yamaqembu ethu',
    latestPosts: 'Okuthunyelwe kwakamuva',
    lastestPostSubtitle:
        'Nanka amabhulogi akamuva avela kubasebenzisi abahlukene',
    featuredPosts: 'Okuthunyelwe okufakiwe',
    featuredPostSubtitle:
        'Nanka amabhulogi afakiwe avela kubasebenzisi abahlukene',
    newsLetterTitle: 'Ungaphuthelwa yindaba',
    newsLetterSubtitle:
        'Bhalisela ibhulogi ye-AUTO24 ukuze uhlale unolwazi mayelana nayo yonke into ngezimoto',
    buySellTitle: 'Usukulungele?',
    buySellSubtitle:
        "Whether it's selling your used car or buying your next ride, we can do it all.",
    sellYourCar: 'Thengisa imoto yakho',
    buyaCar: 'Ukuthenga imoto',
    footerInfo:
        'AUTO24.ci is a subsidiary of Africar Group Pty Ltd, registered in Ivory Coast under the IDU CI-2022-0027564 O.',
    FAQ: 'FAQ',
    buyCars: 'Thenga izimoto',
    sellCars: 'Dayisa izimoto',
    categories: 'Izigaba',
    topStories: 'Izindaba eliphezulu',
    pleaseInputYourPassword: 'Sicela ufake iphasiwedi yakho!',
    donthaveanaccount: 'Anginayo i-akhawunti',
    resetPassword: 'Setha kabusha iphasiwedi',
    editProfile: 'Hlela iphrofayili',
    createNewPassword: 'Dala iphasiwedi entsha',
    passwordresetsuccessfully: 'Ukusetha kabusha iphasiwedi ngempumelelo',
    pleaseInputYourName: 'Sicela ufake igama lakho!',
    pleaseInputYourEmail: 'Sicela ufake ikheli lakho le-imeyili',
    pleaseInputValidEmail: 'Sicela ufake ikheli le-imeyili elivumelekile!',
    pleaseInputYourPhoneNumber: 'Sicela ufake inombolo yakho yocingo!',
    maximumXdigitsisallowed: 'Maximum {{X}} digits is allowed',
    minimumXdigit: 'Minimum {{X}} digits is allowed',
    passwordisRequired: 'Iphasiwedi iyadingeka!',
    minimum8chars: 'Ubuncane bezinhlamvu eziyi-8 buyadingeka',
    usersuccessfullyregistered: 'Umsebenzisi ubhaliswe ngempumelelo!',
    shortBio: 'I-bio emfushane',
    Save: 'Gcina',
    doyouwantDeleteAccount: 'Do you want to permanently delete your account?',
    delete: 'Susa',
    saveChanges: 'Londoloza izinguquko?',
    youcanstillchangetheseinformation: 'Usengakwazi ukushintsha lolu lwazi',
    changessavedsuccessfully: 'Izinguquko zilondolozwe ngempumelelo',
    yourchangessavedsuccessfully: 'Izinguquko zako zilondolozwe ngempumelelo',
    deleteyourAccount: 'Susa i-akhawunti yakho?',
    deleteAccountsubtitile:
        'Once you delete your account your all data will be lost and you cant  get access to your account.',
    accountdeletedsuccessfully: 'I-akhawunti isuswe ngempumelelo',
    youcanstillexpolrecarwithouthavinganaccount:
        'Usengakwazi ukuhlola izimoto ngaphandle kokuba ne-akhawunti',
    logout: 'Phuma',
    xInspectionPoints: '{{number}} inspection points',
    xlistingPageResultTitle: '{{number}} Cars Found',
    xlistingPageResultsTitle: '{{number}} Cars Found',
    sortBy: 'Hlunga nge:',
    filterBy: 'Hlunga nge:',
    lowestPriceFirst: 'Intengo ephansi kuqala',
    highestMileageFirst: 'Imayela eliphezulu kakhulu kuqala',
    lowestMileageFirst: 'Imayela eliphansi kakhulu kuqala',
    price: 'Intengo',
    mileage: 'Imayela',
    selectBodyType: 'Khetha uhlobo lomzimba',
    filter: 'Izihlungi',
    moreFilters: 'Izihlungi ezengeziwe',
    color: 'Umbala',
    transmission: 'Ukudluliswa',
    highestPriceFirst: 'Intengo ephezulu kuqala',
    provideUsYourDetail: 'Sinikeze imininingwane yakho',
    interestcartitle: 'Uzimisele ukuthenga le moto?',
    interestcarsubtitle:
        'Khetha inketho eyodwa,ezinye zizoqhubeka nokutholakala ohlelweni lwakho lokuthenga',
    bookThisCar: 'Bhuka le moto',
    bookThisCarSubtitle:
        'Qhubeka uthenge le moto bese uyibhukha khona manje. Qeda ukuthenga kwakho ezinsukwini ezingu-7. 10% ingxenye ebuyiselwayo',
    scheduleAVisit: 'Hlela ukuvakashelwa',
    scheduleAVisitSubtitle:
        'Vakashela isitolo sethu ukuze ubuke imoto oyithandayo. Isazotholakala kwabanye abathengi',
    popularblog: 'Amabhulogi adumile',
    popularblogSub: 'Nanka amabhulogi adumile avela kubasebenzinsi abahlukene',
    category: 'Isigaba',
    carOverview: 'Uhlolojikelele lwemoto',
    Detailssentsuccessfully: 'Imininingwane ithunyelwe ngempumelelo',
    DetailChangeSubtitle:
        'Siyabonga ngokusinika imininingwane yakho. Sizokufonela maduze',
    continue: 'Qhubeka',
    carnotfoundSubtitle:
        'Ayikho imoto etholakalayo ngemibandela ekhethiwe. Sisize ngemininingwane yakho futhi sizokusiza uthole imoto efanele',
    logoutModelTitle: 'Uqinisekile ukuthi ufuna ukuphuma?',
    french: 'IsiFulentshi',
    QuickLinks: 'Izixhumanisi ezisheshayo',
    similarResults: 'Similar Results ',
    copyrightInfo:
        "AUTO24.CI is a subsidiary of Africar Group Pty Ltd, registered under the unique identification number CI-2022-0027564 O in the Republic of Cote d'Ivoire",
    recentlyAdded: 'Okusanda kungezwa',
    recentlyAddedSubtitle: 'Lapha ungabona izimoto ezahlukene esanda wanezela',
    newCarList: 'Izimoto ezisha',
    newCarListSubtitle: `Lapha ungakwazi ukubona izimoto ezisha ezincane`,
    electricCarList: `Izimoto zesitshudeni`,
    electricCarListSubtitle: `Lapha ungakwazi ukubona izimoto ezintsha ezincane eziselekhtriki`,
    importedCarList: `Izimoto ezinzuzo`,
    importedCarListSubtitle: `Lapha ungakwazi ukubona izimoto ezinzuzo ezincane`,
    cheapestCarList: 'Imoto ezinzima ezincane phezu kwama {{X}}',
    cheapestCarListSubtitle:
        'Lapha ungakwazi ukubona imoto ezithokozisa ezahlukene phansi kwama {{X}}',
    hereyoucanseedifferentsimilarcars:
        'Lapha ungabona izimoto ezahlukene ezifanayo',
    carNotFound: 'Imoto ayitholakali',
    whatsappUrlText: 'Sawubona! Ngiyayithanda le moto',
    latestService: 'Isevisi yakamuva',
    resetSuccessMsg:
        'Manje ungangena ngemvume ngephasiwedi yakho entsha noma usengahlola izimoto',
    bothPasswordMustMatch: 'Womabili amaphasiwedi kumele afane',
    mustBeAtleast8Characters: 'Kufanele okungenani ibe nezinhlamvu ezingu-8',
    sendResetLink: 'Thumela isixhumanisi sokuphumula',
    returnTo: 'Buyela ku',
    enterEmailToGetResetLinkInfo:
        'Faka i-imeyili ehlobene ne akhawunti yakho futhi sizokuthumela i-imeyili enemiyalelo yokusetha kabusha iphasiwedi yakho',
    resetPasswordModalTitle:
        'Uqinisekile ukuthi ufuna ukusetha kabusha iphasiwedi yakho?',
    yourPasswordChangedSuccessfully: 'Iphasiwedi yakho ishintshwe ngempumelelo',
    confirmPassword: 'Qinisekisa iphasiwedi',
    carViewsXTimes: 'Viewed {{number}} times today',
    shortDescription: 'Incazelo emfushane',
    carImages: 'Izithombe zemoto',
    uploadImage: 'Layisha isithombe',
    browse: 'Phequlula',
    listAnother: 'Bhala enye',
    listCarRequestSentSucessfully:
        'Isicelo sohlu lwemoto sithunyelwe ngempumelelo',
    basicDetails: 'Imininingwane eyisisekelo',
    manual: 'Imanuwali',
    postYouHaveLiked: 'okuthunyelwe okuthandile',
    enterOldPassword: 'Faka iphasiwedi endala',
    enterNewPassword: 'Iphasiwedi entsha',
    confirmNewPassword: 'Qinisekisa iphasiwedi entsha',
    passwordChangeSuccessfully: 'Iphasiwedi ishintshwe ngempumelelo',
    verificationTitle:
        'Sikuthumelele isixhumanisi sokuqinisekisa ekhelini lakho le-imeyili',
    viewLink: 'Buka isixhumanisi',
    resendingLink: 'Thumela kabusha isixhumanisi',
    tokenExpired: 'Uphawu lwakho luphelelwe yisikathi sicela udale entsha',
    tokenExpiredTitle:
        'Isixhumanisi sokuniqisekisa i-akhawunti yakho siphelelwe yisikathi',
    callBackSubtitle:
        "Enter your name and phone number we'll get back to you soon",
    provideUsYourDetails: 'Sinikeze imininingwane yakho',
    showCars: 'Khombisa izimoto',
    accountVerifiedSuccessfully: 'I-akhawunti iqinisekiswe ngempumelelo',
    accountVerifiedSuccessfullySubtitle:
        'Your account has been verified successfully. Do you want to login?',
    engineSound: 'Umsindo wenjini',
    showAllImage: 'Khombisa zonke izithombe',
    ourBenefits: 'Izinzuzo zethu',
    inspectionPoints: 'Amaphuzu okuhlola ayi-100 ngochwepheshe bethu bezimoto',
    warranties:
        'Iwaranti yezinyanga eziyisi-6 (injini, ibhokisi legiya ne-ekseli)',
    maintenances: '1 year maintenance package with AUTO24.co.za',
    assistance: 'Izinyanga eziyi-12 usizo lokudonsa nokudiliza',
    multiCurrency: 'Multi-currency payment DH, €, $, £',
    moneyBack: 'Isiqinisekiso sokubuyiselwa imali sezinsuku ezi-5',
    financing: 'Financing ',
    dragAndDrop: 'Hudula bese uphonsa isithombe noma',
    imageFormat: 'Amafomethi asekelwayo:JPEG,PNG',
    sellNow: 'Thengisa manje',
    confirm: 'Qinisekisa',
    didntFindQuestion: "Didn't find the question you're looking for?",
    yourMessage: 'Umlayezo wakho',
    sendMessage: 'Thumela umlayezo',
    loanTenure: 'Isikhathi sokubolekwa imali',
    selectLoanTenure: 'Select Loan Tenure',
    interestInput: 'Enter amount between ({{X}} and {{Y}})',
    months: 'Izinyanga',
    from: 'Kusuka',
    upto: 'Kuya ku',
    rateOfInterest: 'Izinga lenzalo',
    tenure: 'Isikhathi sokuhlala',
    monthlyEmi: 'EMI ngenyanga',
    loadMore: 'Layisha okuningi',
    contactSubtitle: 'Unemibuzo? Ukhululekile ukusithumelela umlayezo',
    inspector: 'Umhloli',
    inspectorSubtitle: 'Cela umhloli',
    insurance: 'Umshwalense',
    insuranceSubtitle: 'Cela umshwalense ongcono kakhulu',
    financeSubtitles: 'Cela imali engcono kakhulu',
    diagnostic: 'Zokuxilonga',
    minPrice: 'Intengo encane',
    maxPrice: 'Intengo enkulu',
    minDistance: 'Ibanga elincane',
    maxDistance: 'Ibanga elikhulu',
    min: 'Min',
    max: 'Max',
    diagonosticSubtitle: 'Cela ukuxilonga',
    VINVerify: 'Qinisekisa i-VIN',
    VINVerifySubtitle: 'Cela ukuqinisekiswa kwe-VIN okusheshayo\n',
    learnMore: 'Funda kabanzi',
    speedRegular: 'Isilawuli sesivinini',
    airCondition: 'Isimo somoya',
    sequential: 'Ngokulandelana',
    CDPlayer: 'Isidlali se-CD',
    reversingCamera: 'Ikhamera ehlehlayo',
    reversingRadar: 'I-radar ehlehlayo',
    GPSNavigation: 'GPS yokuzulazula',
    parkAssist: 'Usizo lwepaki',
    startAndStop: 'Qala futhi ume',
    airbag: 'Isikhwama somoya',
    ABS: 'ABS',
    computer: 'Ikhompuyutha',
    rims: 'Amarimu',
    sunroof: 'Uphahla lwelanga',
    electricMirrors: 'Izibuko zikagesi',
    electricWindows: 'Amawindi kagesi',
    dateFirstUse: 'Usuku lokuqala ukusetshenziswa',
    seats: 'Izihlalo',
    certifiedUsedCars: 'Izimoto ezisetshenzisiwe eziqinisekisiwe',
    certifiedUsedCarsSubtitle:
        'Thenga izimoto esezike zasentshenziswa ngokwethembeka nangokusobala',
    inpectionsPoints: 'Amaphuzu okuhlola ayi-100',
    inpectionsPointsSubtitle: 'Zonke izimoto zethu zihlolwe kahle',
    refundPolicy: 'Inqubomgomo yembuyiselo yezinsuku ezi-5',
    refundPolicySubtitle: "You don't like it, get refunded!",
    warrentySubtitles:
        'Injini, ibhokisi yegiya kanye ne-powertrain, izinyanga eziyisi-6 ziqinisekisiwe',
    excellentCondition: 'Isimo esihle kakhulu',
    goodCondition: 'Isimo esihle',
    averageCondition: 'Isimo esimaphakathi',
    damagedCar: 'Imoto eyonakele',
    lessThan10: 'Ngaphansi kuka-10 000 km',
    betweenXAndY: 'Between {{X}} and {{Y}} km',
    moreThan100: 'Ngaphezu kuka-10 000 km',
    serviceTitle: 'Ukuthenga noma ukuthengisa? Singakusiza',
    serviceSubtitle:
        'Explore our services and make your car buying / selling experience seamless ',
    verifyAccount: 'Qinisekisa i-akhawunti',
    verifyNumber: 'Qinisekisa inombolo',
    otpVerificationSubtitle:
        'Enter the 5 digit code sent to {{code}} {{number}}',
    otpVerificationEmail: 'Enter the 5 digit code sent to {{email}}',
    didntGetTheCode: "Didn't get the code?",
    resendCode: 'Thumela kabusha ikhodi',
    bluetooth: 'Bluetooth',
    home: 'Ekhaya',
    lookingForBestInsurance: 'Ufuna umshwalense ongcono kakhulu?',
    ourCustomerRepresentativesWillGetBackInTouchWithYou:
        'Abamele amakhasimende ethu bazophinde baxhumane nawe',
    upload: 'Layisha',
    profileUpload: 'Layisha isithombe esisha',
    remove: 'Susa',
    xIsrequired: '{{name}} is required',
    number: 'Inombolo',
    backToCarDetail: 'Buyela ekhasini lemininingwane',
    backToTheCarDescription: 'Buyela encazelweni yemoto',
    backToTheCarListing: 'Buyela ohlwini lwezimoto',
    phoneVerifiedSuccesfully: 'Ifoni iqinisekiswe ngempumelelo',
    phoneVerifiedSubtitle:
        'Your phone number has been verified successfully. Do you want to login',
    signWithPhone: 'Ngena ngemvume ngefoni',
    signWithEmail: 'Ngena ngemvume nge-imeyile',
    newPriceFrom: 'Intengo yemoto entsha kusuka',
    theySpeakAboutUs: 'Bakhuluma ngathi',
    theySpeakAboutUsSubTitle:
        'Ukukhethwa kwabahloli babezindaba bamazwe ngamazwe abakhumuluma nge-AUTO24',
    carLoanSubTitle: 'Inkokhelo yenyanga zonke elinganiselwe',
    carLoanTotalRepayment: 'Inani eliphelele lokubuyisela',
    forgotTokenExpired:
        'Isixhumanisi sokusetha kabusha iphasiwedi siphelelwe yisikathi',
    discover: 'Thola imoto',
    paperWork: 'Amaphepha ezimoto asesikhathini samnje futhi aqinisekisiwe',
    verifyhistory: 'Siqinisekisa umlando wazo zonke izimoto esizithengayo',
    buyBack: 'Sithenga futhi imoto yakho endala',
    additionalPhotos: 'Izithombe ezengeziwe',
    technicalVisit: 'Isitifiketi sokuvakasha kobuchwepheshe',
    keysPhotos: 'Izithombe zokhiye',
    seeKeyPhotos: 'Bona isithombe',
    pleaseTryAfterSomeTime: 'Ngicela uzame emva kwesikhathi',
    favourites: 'Izintandokazi',
    electric: 'Kagesi',
    autonomy: 'Ukuzimela',
    productionYear: 'Unyaka wokukhiqiza',
    payWithOtherCurrencytitle: 'Khokha ngolunye uhlobo lwemali nge-AUTO24',
    payWithOtherCurrencySubtitle:
        'Nge-AUTO24,ungakhokha kwenye yama-akhawunti ethu ebhange lamazwe ngamazwe kunoma yiziphi izinhlobo zemali ezingezansi. Nali inani ongayidinga ukuze ulidlulisele',
    convertedPrice: 'Intengo eguquliwe',
    favouritesNotFoundSubtitle:
        "You haven't added any cars to favourites. Explore cars and add your favourites",
    noFavouritesFound: 'Azikho izintandokazi ezitholakele',
    frontCrankOilSeal: 'Uphawu lwamafutha e-crank angaphambili',
    engineOverHaul: 'Ukulungiswa kwenjini',
    rearCrankOilSeal: 'Uphawu lwamafutha e-crank yasemuva',
    engineTuneUp: 'Lungisa injini',
    engineMount: 'I-mount ye injini',
    oilPanGasket: 'I-oil pan gasket',
    valveCoverGasket: 'I-valve cover gasket',
    oilFilterBracketOring: 'Ubakaki wokuhlunga uwoyela oring',
    intakeManifoldGasket: 'I-intake manifold gasket',
    waterLeak: 'Ukuvuza kwamanzi',
    radiator: 'Irediyetha',
    engineTimingCoverGasket: 'Injini yesikhathi ikhava gasket',
    waterPump: 'Umthombo wamanzi',
    belts: 'Amabhande',
    fuelFilter: 'Isihlungi sikaphethiloli',
    airFilter: 'Isihlungi somoya',
    underChassis: 'Ngaphansi kwe-chassis',
    tieRod: 'Induku yokubopha',
    ballJointLandR: 'Ibhola elihlangene L&R',
    idlerArm: 'Ingalo evilaphayo',
    pitmanArm: 'Ingalo ye-pitman',
    steeringPump: 'Iphampu yokuqondisa',
    steeringHoses: 'Amapayipi okuqondisa',
    steeringBox: 'Ibhokisi lokuqondisa',
    steeringRackAndPinion: 'I-steering rack kanye ne-pinion',
    frontShockLandR: 'Ukushaqeka ngaphambili L&R',
    rearShockLandR: 'Ukushaqeka kwangemuva L&R',
    stabilizerLinkage: 'Ukuxhumana kwe Isizinzisi',
    crossJointPropellerShaft: 'Isiphambano se propeller shaft',
    upperArmBushing: 'Ukuqhumana kwengalo engaphezulu',
    rearLowerArmBushing: 'Ngemuva kwengalo engezansi',
    suspensionSpring: 'Intwasahlobo yokumiswa',
    breakSystem: 'Uhlelo lokuphumula',
    brakePad: 'Iphedi yebrake',
    brakeDiscRotor: 'I-brake disc rotor',
    wheelBearing: 'Isondo elithwalayo',
    brakeMasterCLY: 'I-brake master CLY',
    backBooster: 'I-booster yasemuva',
    brakeWheelCall: 'Ukukhala kwesondo eliphukayo',
    handBrakeCable: 'Ikhebulu le-brake yesandla',
    brakeHoses: 'Amapayipi we-brake',
    rearAxlesAndDriveShafts: 'Ama ekseli angemuva kanye namashafts okushayela',
    frontAxle: 'Iekseli yangaphambili',
    rearAxle: 'Iekseli yangemuva',
    axleDifference: 'Umehluko weekseli',
    differenceFrontOrRear: 'Difference Front Or Rear',
    axleSealFrontOrRear: 'Axle Seal Front Or Rear',
    oilPumpGasket: 'Oil Pump Gasket',
    frontOilSeal: 'Front Oil Seal',
    rearOilSeal: 'Rear Oil Seal',
    transmissionMount: 'Transmission Mount',
    gearStickSouta: 'Gear Stick Souta',
    oilCollerPipe: 'Oil Cooler Pipe',
    gearPerformance: 'Gear Performance',
    liquidSystem: 'Liquid System',
    engineOil: 'Engine Oil',
    transmissionOil: 'Transmission Oil',
    brakeFluid: 'Brake Fluid',
    engineCoolant: 'Engine Coolant',
    washerFluid: 'Washer Fluid',
    electricitySystem: 'Electricity System',
    battery: 'Battery',
    alternator: 'Alternator',
    starler: 'Starler',
    lights: 'Lights',
    ac: 'A/C',
    centerLock: 'Center Lock',
    wiperBlades: 'Wiper Blades',
    troubleCode: 'Trouble Code',
    computerCods: 'Computer Cods',
    acCondensor: 'A/C Condenser',
    acRadiator: 'A/C Radiator',
    interior: 'Interior',
    seatAndBelts: 'Seats And Belts',
    interiorControlsAndSwitches: 'Interior Controls And Switches',
    sunRoofAndWindows: 'Sun Roof And Windows',
    fuelAndTemperatureGauge: 'Fuel And Temperature Gauge',
    dashboardAndGauges: 'Dashboard And Gauges',
    radioOrMusicSystem: 'Radio Or Music System',
    sRSAirbags: 'SRS Airbags',
    steeringWheelTiltOrlock: 'Steering Wheel Tilt Or Lock',
    mirrors: 'Mirrors',
    bodyInspection: 'Body Inspection',
    inspectionDate: 'Inspection Date',
    carInspectionReport: 'Car Inspection Report',
    showPicture: 'Show Picture',
    pageNotFound: 'Page Not Found',
    pageRemoved: "This page doesn't exist or was removed!",
    askQuestionOnWhatsapp: 'Questions? Ask us on whatsapp!',
    whatsappsPlaceholder: 'Ask anything!',
    customerSupport: 'Customer Support',
    needHelpChatWithUs: 'Need Help? Chat with us on Whatsapp',
    instaTitle: 'Follow us on Instagram',
    instaSubTitle:
        'Get updated with our latest offers, products and news instantly!',
    questionSentSuccessfully: 'Question Sent Successfully',
    questionSentSuccessfullySubtitle:
        'Your question has been sent successfully. We will get back to you for your answer',
    unSubscribeMessage:
        'You have been successfully unsubscribed from the Auto24 Blogs.',
    numberMustBeUnique: 'Number must be unique',
    pleaseEnterFullName: 'Sicela ufake igama eligcwele',
    saving: 'Saving',
    sending: 'Sending',
    signingIn: 'Signing In',
    signingUp: 'Signing Up',
    loggingout: 'Logging out',
    deleting: 'Deleting',
    querySubmittedSuccessfully: 'Query submitted successfully',
    somethingWentWrong: 'Something went wrong!',
    removedFromWishlist: 'Removed from wishlist !!',
    addedToWishlist: 'Added to wishlist !!',
    subscribedSuccessfully: 'Subscribed successfully',
    alreadySubscribed: 'Already subscribed',
    messageIsRequired: 'Message is required',
    fullName: 'Full Name',
    phoneNumberVerifiedSuccess:
        'Phone number verified successfully, Please login.',
    verifyEmail:
        'Please verify your email for Auto24, Verification link has been sent to your email',
    sold: 'Kuthengisiwe',
    new: 'Okusha',
    used: 'Esetshenzisiwe',
    popular: 'Idumile',
    makes: 'Imikhiqizo',
    carTypes: 'Izinhlobo zezimoto',
    explore: 'Phanda',
    followUS: 'Landela thina',
    none: 'Lutho',
    all: 'Zonke',
    discount: 'Discount',
    careers: 'Imisebenzi',
    year: 'Year {{X}} and above',
    discountOff: '{{X}} Remise',
    financeYourCarWith: 'Finance Your Car With',
    creditButton: 'CREDIT GRATUIT',
    loanPaymentSimulator: 'Isibali-mali sesikweletu semoto',
    loanPaymentContent:
        'Sikwenzela kube lula ukuba ube nemoto oyifunayo. Masikusize uthole isikweletu, kusukela ekufakweni kwemibhalo kuya ekutholeni ukuqinisekiswa kwesikweletu ngokushesha.',
    vehiclePrice: 'Vehicle price',
    noOfMonthlyPayments: 'Number of monthly payments',
    monthlyPaymentAmount: 'Monthly payment amount',
    creditRequestedAmount: 'Credit Requested Amount',
    overallCostOfCredit: 'Isikweletu esiphelele',
    simulatorContribution: 'Simulator contribution',
    percentageOfContribution: 'Percentage of contribution',
    applicationFees: 'Application fees',
    monthlyInsurance: 'Monthly insurance',
    noOfOwners: 'Number Of Owners',
    condition: 'Condition',
    maisonMere: 'Maison Mere',
    imported: 'Imported',
    tamperedOdometer: 'Tampered Odometer',
    carIssue: 'Car Issues',
    evaluateAgain: 'Evaluate Again',
    marketValue: 'Market Value',
    downloadAuto24App: `Thwebula i-App ye-Auto24`,
    browseMore: `Shintsha, thenga, uceke ngeqiniso - unqubomgomo wakho wezimoto ovuthayo`,
    saveTimeAndEffort: 'Thenga Isikhathi Nengqalasizinda',
    saveTimeAndEffortSubtitle: `Vumelan' ukuthi sithengele isikhathi esimangalisayo njengoba sizimela konke, sikwazi ukwenza ukuthengisa ngokuphelele futhi ngokuphepha`,
    freeNoCost: 'Mahhala Ngaphandle kwezindlela',
    freeNoCostSubtitle: `Phuma ezinhleleni, i-photography, imali yokubeka ishadi, kanye nezici zokwenza - sikhulelwe`,
    competetivePrice: 'Inani elikahle',
    competetivePriceSubtitle:
        'Thola inani elikhulu ngaphandle kokuphindaphinda nathi; siziqinisekisa konke okwenza ukuthengiselana nokuzikhululwa',
    carTitle: `Izici Zomshini`,
    carDesc: `Nceda usiphikise izici mayelana nomshini wakho`,
    personalDetail: `Izici Zamntu`,
    personalDetailDesc: `Nceda usiphikise izici mayelana nawe`,
    sellCarHere: `Thengisa Imoto`,
    sellCarHereDesc: `Ungakwazi ukuthenga isu elihle kanye nathi sizobhala imoto yakho`,
    buySellUsedCar: `Thenga futhi thengisa izimoto ezisebenzile kwi`,
    auto24Press: `i-Auto24 i-Press`,
    auto24PressDesc1: `I-Auto24 ihlanganisiwe kwezindaba ezahlukene emhlabeni wonke.`,
    auto24PressDesc2: `I-Auto24 ikwazile futhi kufinyelelwe kulezinhlelo eParis/France, Lisbon/Portugal, Barcelona/Spain, Sydney/Australia, London/UK, Kigali/Rwanda, Bangkok/Thailand, Abidjan/Ivory Coast, Johannesburg/South Africa, Dakar/Senegal, Nairobi/Kenya, Casablanca/Morocco…`,
    mediaExposure: `Ukuboniswa kwethu kwi-Media`,
    mediaExposureDesc: `Lapha phezulu kukhethwe ukuboniswa kwethu kwi-Media`,
    press: `Isihloko`,
    enterValidPhoneNumber: 'Faka inombolo yomakhalekhukhwini evumelekile',
    sellCar: 'Thengisa imoto',
    findUsGoogle: 'Sithole kuGoogle Map',
    locateUs: 'Sithole',
    whereFindUs: 'Lapho ungathola khona?',
    aroundLocationText: 'Ungasithola kulezi zindawo',
    facebook: 'Facebook',
    instagram: 'Instagram',
    tiktok: 'Tiktok',
    twitter: 'Twitter',
    youtube: 'Youtube',
    linkedin: 'LinkedIn',
    carsUnderPriceDescription:
        'I-Auto24 iyindawo yakho ekahle yokuhlola izinhlobo ezahlukene zezimoto ezivela emhlabeni jikelele. Kungakhathaliseki ukuthi ufuna imoto encane yasedolobheni, i-SUV efanele umndeni, noma i-sedan kanokusho, inkundla yethu ikuxhumanisa nezinketho ezihambelana nazo zonke izidingo nezintandokazi zakho. Yonke imikhiqizo yezimoto evezwe ku-Auto24 yaziwa ngobungcweti bayo, ubuchwepheshe bayo, nokwethenjelwa kwayo, ikunikeza ukuthula kwengqondo ekufuneni kwakho imoto ekahle.'
};

export default commonZulu;
