import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AutoComplete, Modal, Tabs } from 'antd';
import {
    CalendarBlank,
    Car,
    CircleWavyQuestion,
    GasPump,
    User
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import LoadingButton from './ui/LoadingButton';
import Selects from './ui/Select';

import { $FIXME } from '../constants';
import { CAR_CONDITION, getYearList, mileageRange } from '../helpers/constants';
import { defaultDailing_Code, getCountryByCode } from '../helpers/countryCode';
import { getNumbersOnly } from '../helpers/functions';
import { IBrand } from '../interface/car';
import { getCarBrandsList } from '../redux/actions/carBrandsAction';
import { fetchCarModelByModel } from '../redux/actions/carModelsAction';
import { createSellingCar } from '../redux/actions/sellCarAction';
import { ICombineReducerType } from '../redux/reducers';
import { fullNameValidate, toSlug } from '../utils/Helper/jsHelpers';
import { formatMoney } from './SearchFilter';
import FourCircleSvg from './ui/icons/FourCircleSvg';
import MeterSvg from './ui/icons/MeterSvg';
import TransmissionSvg from './ui/icons/TransmissionSvg';
import PhoneNumberInput from './ui/PhoneNumberInput';
import { useTranslatedOptions } from '../utils/Hooks/TranslatedOptions';

export const getMileageOptions = (t: $FIXME) =>
    mileageRange.map((item) => {
        if (typeof item.label === 'object') {
            return {
                label: t(item.label.key, {
                    X: formatMoney(item.label.X),
                    Y: formatMoney(item.label.Y)
                }),
                value: item.value
            };
        } else {
            return {
                label: t(item.label),
                value: item.value
            };
        }
    });

const SellDetails = () => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [brandList, setBrandList] = useState<any[]>([]);
    const [modelsList, setModelsList] = useState<any[]>([]);
    const [load, setLoad] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<any>({
        brandName: '',
        modelName: '',
        fuelType: '',
        transmission: '',
        condition: '',
        mileage: '',
        year: ''
    });
    let { user } = useSelector((state: ICombineReducerType) => state.auth);

    const [userData, setUserData] = useState({
        fullName: user?.name ?? '',
        phoneNumber: {
            code: user?.phoneNumber?.code
                ? String(user?.phoneNumber?.code)
                : defaultDailing_Code,
            number: user?.phoneNumber?.number
                ? String(getNumbersOnly(`${user?.phoneNumber?.number}`))
                : ''
        }
    } as { fullName?: string; phoneNumber?: { number?: string; code?: string } });
    const [errors, setError] = useState({} as any);
    const [phoneValidErr, setPhoneValidErr] = useState({
        number: true
    });

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { carModels, loading } = useSelector(
        (state: ICombineReducerType) => state.carModels
    );

    const { TabPane } = Tabs;

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const emptySelectedOptions = Object.keys(selectedOptions).filter(
        (k, val) => {
            return !selectedOptions[k];
        }
    );
    const [disabled, setDisabled] = useState(true);

    const goToNextTab = (key: string) => {
        if (emptySelectedOptions.length) {
        } else {
            setActiveTab(key);
        }
    };

    const { carBrands } = useSelector(
        (state: ICombineReducerType) => state.carBrands
    );

    useEffect(() => {
        if (!carBrands.length) getCarBrandsList(dispatch);
    }, []);
    useEffect(() => {
        if (carBrands.length > 0) {
            const brandsList: Array<any> = carBrands.map((items: IBrand) => ({
                label: items.brand,
                value: items.brand,
                _id: items._id
            }));
            setBrandList(brandsList);
        }
    }, [carBrands]);
    useEffect(() => {
        if (selectedOptions.brandName) {
            fetchCarModelByModel(dispatch, toSlug(selectedOptions.brandName));
        }
    }, [selectedOptions.brandName]);
    useEffect(() => {
        if (carModels.length > 0) {
            const carModelsList = carModels.map((items) => ({
                label: `${items.model}`,
                value: items.model,
                _id: items._id
            }));
            setModelsList(carModelsList);
        } else {
            setModelsList([]);
        }
    }, [carModels]);

    const handleInputChange = (e: any) => {
        setUserData({
            ...userData,
            fullName: e.target.value
        });
    };
    const onSelect = (value: string, key: string) => {
        delete errors[key];
        setError(errors);
        if (key === 'brand') {
            setModelsList([]);
            setSelectedOptions({ ...selectedOptions, [key]: value, model: '' });
        } else setSelectedOptions({ ...selectedOptions, [key]: value });
    };
    const handleSubmit = async () => {
        if (!userData.phoneNumber?.number) {
            setError((prev: any) => ({
                ...prev,
                phoneNumber: t('xIsrequired', { name: t('number') })
            }));
        }
        if (!userData.fullName) {
            setError((prev: any) => ({
                ...prev,
                fullName: t('xIsrequired', { name: t('fullName') })
            }));
            return;
        }

        if (userData?.phoneNumber?.code && userData?.phoneNumber?.number) {
            setLoad(true);
            setError((prev: any) => ({
                ...prev
            }));
            const res = await createSellingCar(dispatch, {
                ...selectedOptions,
                ...userData
            });
            setLoad(false);
            if (res) {
                setIsModalVisible(true);
                setSelectedOptions({
                    brandName: '',
                    modelName: '',
                    fuelType: '',
                    transmission: '',
                    condition: '',
                    mileage: '',
                    year: ''
                });
                setUserData({
                    fullName: user?.name ?? '',
                    phoneNumber: {
                        code: user?.phoneNumber?.code
                            ? String(user?.phoneNumber?.code)
                            : defaultDailing_Code,
                        number: user?.phoneNumber?.number
                            ? String(getNumbersOnly(`${user?.phoneNumber?.number}`))
                            : '',
                    }
                });
                setIsModalVisible(true);
                setActiveTab('1');
            }
        }
    };

    let { loadingSellData } = useSelector(
        (state: ICombineReducerType) => state.sellingCars
    );
    const {fuelTypes,transmissionTypes} = useTranslatedOptions()
    useEffect(() => {
        setSelectedOptions((prv: any) => ({
            ...prv,
            modelName: ''
        }));
    }, [selectedOptions.brandName]);
    const goToHome = () => {
        setIsModalVisible(false);
        navigate(t('/home', { ns: 'routes' }));
    };
    function setFieldsError() {
        if (!emptySelectedOptions.length) setDisabled(false);
        else setDisabled(true);
    }
    useEffect(() => {
        if (userData.fullName) {
            if (fullNameValidate(userData.fullName)) {
                setError({});
            }
        }
    }, [userData]);

    useEffect(() => {
        setFieldsError();
    }, [selectedOptions]);

    return (
        <div className="sell-details">
            <Tabs
                defaultActiveKey="1"
                activeKey={activeTab}
                onChange={goToNextTab}
                className="sell-details-tab"
            >
                <TabPane tab={`1. ${t('carDetails')}`} key="1">
                    <div className="sell-top">
                        <div className='selects-container'>
                            <div
                                className={`certification-input ${
                                    errors.brandName && 'border-error'
                                }  `}
                            >
                                <div className="selects-prefixicon">
                                    <Car size={24} color="#727272" />
                                </div>
                                <AutoComplete
                                    placeholder={t('selectorenterbrand')}
                                    options={brandList}
                                    value={selectedOptions.brandName}
                                    filterOption={(inputValue, option) =>
                                        option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={(data) =>
                                        setSelectedOptions({
                                            ...selectedOptions,
                                            brandName: data.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase())
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className='selects-container'>
                            <div
                                className={`certification-input ${
                                    errors.modelName && 'border-error'
                                }  `}
                            >
                                <div className="selects-prefixicon">
                                    <FourCircleSvg stroke="#727272" />
                                </div>
                                <AutoComplete
                                    placeholder={t('selectorentermodel')}
                                    disabled={!selectedOptions.brandName || loading}
                                    className={
                                        !selectedOptions.brandName ? '' : 'select-cursor'
                                    }
                                    options={modelsList}
                                    value={selectedOptions.modelName}
                                    filterOption={(inputValue, option) =>
                                        option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={(data) =>
                                        setSelectedOptions({
                                            ...selectedOptions,
                                            modelName: data
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <Selects
                            prefixicon={
                                <CalendarBlank size={24} color="#727272" />
                            }
                            placeholder={t('selectYear')}
                            weight="regular"
                            size="16"
                            onSelect={onSelect}
                            name="year"
                            error={errors.year}
                            value={selectedOptions.year}
                            options={getYearList().map(
                                (item: any, index: number, arry) => {
                                    return index + 1 === arry.length
                                        ? {
                                              label: t('other'),
                                              value: 'other'
                                          }
                                        : {
                                              label: item,
                                              value: item
                                          };
                                }
                            )}
                            showSearch={true}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={<MeterSvg />}
                            placeholder={t('selectMileageRange')}
                            size="16"
                            weight="regular"
                            options={getMileageOptions(t)}
                            onSelect={onSelect}
                            name="mileage"
                            error={errors.mileage}
                            value={selectedOptions.mileage}
                            showSearch={true}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={<TransmissionSvg stroke="#727272" />}
                            placeholder={t('selectTransmissionType')}
                            size="16"
                            weight="regular"
                            options={transmissionTypes}
                            onSelect={onSelect}
                            name="transmission"
                            error={errors.transmission}
                            value={selectedOptions.transmission}
                            showSearch={true}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={<GasPump size={24} color="#727272" />}
                            placeholder={t('selectFuelType')}
                            size="16"
                            weight="regular"
                            options={fuelTypes}
                            onSelect={onSelect}
                            error={errors.fuelType}
                            name="fuelType"
                            value={selectedOptions.fuelType}
                            showSearch={true}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={
                                <CircleWavyQuestion size={24} color="#727272" />
                            }
                            placeholder={t('selectACondition')}
                            weight="regular"
                            size="16"
                            onSelect={onSelect}
                            name="condition"
                            error={errors.condition}
                            value={selectedOptions.condition}
                            showSearch={true}
                            options={CAR_CONDITION.map((item) => ({
                                label: t(item.name),
                                value: item.value
                            }))}
                            class="select-cursor"
                        />
                    </div>
                    <div className="sell-bottom">
                        <button
                            className="bottom-btn button-primary text-uppercase"
                            disabled={disabled}
                            onClick={() => goToNextTab('2')}
                        >
                            {t('continue')}
                        </button>
                    </div>
                </TabPane>
                <TabPane tab={`2. ${t('personalDetails')}`} disabled key="2">
                    <div>
                        <div className="personal-details">
                            <label>
                                <User size={24} color="#727272" />
                            </label>
                            <input
                                onBlur={() => {
                                    if (userData.fullName) {
                                        if (
                                            !fullNameValidate(userData.fullName)
                                        ) {
                                            setError({
                                                fullName: t(
                                                    'pleaseEnterFullName'
                                                )
                                            });
                                        }
                                    }
                                }}
                                type="text"
                                placeholder={t('enterFullName')}
                                name="fullName"
                                value={userData.fullName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <span className="error-label fade-in">
                                {errors.fullName}
                            </span>
                        </div>
                    </div>
                    <PhoneNumberInput
                        onChange={(value: any, key: string) => {
                            setUserData((prev) => ({
                                ...prev,
                                phoneNumber: {
                                    ...prev.phoneNumber,
                                    [key]: String(getNumbersOnly(value[key])),
                                    code: value?.code
                                }
                            }));
                        }}
                        name="number"
                        value={String(getNumbersOnly(`${userData?.phoneNumber?.number}`))}
                        defaultSelect={
                            userData?.phoneNumber?.code
                                ? getCountryByCode(userData?.phoneNumber?.code)
                                : getCountryByCode(defaultDailing_Code)
                        }
                        setError={setPhoneValidErr}
                    />
                    {load || loadingSellData ? (
                        <div style={{ marginTop: '16px' }}>
                            <LoadingButton buttonText="sending" />
                        </div>
                    ) : (
                        <button
                            disabled={
                                !!userData.fullName &&
                                fullNameValidate(userData.fullName) &&
                                !phoneValidErr?.number
                                    ? false
                                    : true
                            }
                            className="bottom-btn button-primary text-uppercase"
                            onClick={handleSubmit}
                            style={{
                                marginTop: '16px'
                            }}
                        >
                            {t('getACallBack')}
                        </button>
                    )}
                    <Modal
                        className="sell-modal success-sell ants-modal"
                        title={t('cardetailssendSuccessfully')}
                        open={isModalVisible}
                        onCancel={handleCancel}
                        closable={false}
                        centered={true}
                    >
                        <p className="subtitle">
                            {t('thanyouforprovidingusdetail')}
                        </p>
                        <div className="done-icon">
                            <img src="/images/done.webp" alt="done" />
                        </div>
                        <div className="modal-btn-container">
                            <button
                                className="back-to-home text-uppercase"
                                onClick={() => goToHome()}
                            >
                                {t('backToHome')}
                            </button>
                            <button className="close text-uppercase" onClick={handleCancel}>
                                {t('cancel')}
                            </button>
                        </div>
                    </Modal>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default SellDetails;
