import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Slider } from 'antd';

import { returnOnlyNumber } from '../../utils/Helper/jsHelpers';
import { formatMoney } from '../SearchFilter';
export interface IRangeFilter {
    defaultMin: number;
    defaultMax: number;
    defaultStep?: number;
    minTitle: string;
    maxTitle: string;
    minValue: string;
    maxValue: string;
    visible: boolean;
    icon: string;
    minInputTitle: string;
    maxInputTitle: string;
    inputPrefix?: string;
    showAt?: string;
    setvisible: any;
    handleChange?: (data: any) => void;
    handleSave: (data: any) => void;
    disabled?: boolean;
}
export default function RangeFilterModal(props: IRangeFilter) {
    const {
        defaultMin,
        defaultMax,
        defaultStep,
        minValue,
        maxValue,
        visible = false
    } = props;
    const [range, setRange] = useState<any>({
        min: '',
        max: ''
    });
    const [error, setError] = useState<any>('');
    const { t } = useTranslation();

    useEffect(() => {
        setRange({
            min: minValue ? minValue : defaultMin,
            max: maxValue ? maxValue : defaultMax
        });
    }, [props]);
    useEffect(() => {
        setError('');
    }, [range]);
    function minValidation() {
        if (range.min < defaultMin) {
            setError({
                min:
                    'Please enter amount amount greater than min price' +
                    defaultMin
            });
        }
        if (range.max < defaultMax) {
            setError({
                max:
                    'Please enter amount amount greater than min price' +
                    defaultMin
            });
        }
    }
    useEffect(() => {
        minValidation();
    }, [range]);

    const content = (
        <Slider
            range
            max={defaultMax ?? 10000}
            min={defaultMin ?? 0}
            {...(defaultStep ? { step: defaultStep } : {})}
            value={[range.min, range.max]}
            onChange={(e) => {
                setRange({
                    min: e[0],
                    max: e[1]
                });
            }}
            className="range-slider-input"
            disabled={props.disabled}
        />
    );
    return (
        <div className="range-filter-container">
            {visible && (
                <div className={`range-filter ${props?.showAt}`}>
                    <div className="slider">
                        <span>{props?.minTitle}</span>
                        <span className="right">{props?.maxTitle}</span>

                        {content}
                    </div>

                    <div className="mid-section">
                        <div>
                            <span>{props.minInputTitle}</span>
                            <div className="input-section">
                                <img src={props.icon} alt={t('automatic')} />
                                <input
                                    type="text"
                                    value={
                                        range.min
                                            ? formatMoney(range.min)
                                            : range.min
                                    }
                                    onChange={(e) => {
                                        let value = returnOnlyNumber(
                                            e.target.value
                                        );
                                        setRange({
                                            ...range,
                                            min: value
                                        });
                                    }}
                                    name="minValue"
                                    disabled={props.disabled}
                                />
                            </div>
                            {error.min && (
                                <span className="error-message">{`Must be greater than  ${formatMoney(
                                    defaultMin
                                )}`}</span>
                            )}
                        </div>

                        <div className="separator"></div>
                        <div>
                            <span>{props.maxInputTitle}</span>
                            <div className="input-section">
                                <img src={props.icon} alt={t('automatic')} />
                                <input
                                    type="text"
                                    value={
                                        range.max
                                            ? formatMoney(range.max)
                                            : range.max
                                    }
                                    onChange={(e) => {
                                        let { value } = e.target;
                                        value = returnOnlyNumber(value);
                                        parseInt(value) >= props.defaultMax &&
                                        !props.maxTitle.includes('+')
                                            ? setRange({
                                                  ...range
                                              })
                                            : setRange({
                                                  ...range,
                                                  max: value
                                              });
                                    }}
                                    name="maxValue"
                                    disabled={props.disabled}
                                />
                                {props.inputPrefix ? props.inputPrefix : ''}
                            </div>
                        </div>
                    </div>
                    {error && typeof error === 'string' && (
                        <span className="error-message">{error}</span>
                    )}
                    <div className="border"></div>
                    <div className="footer">
                        <div
                            className="footer-cancel"
                            onClick={() => {
                                props.setvisible(false);
                            }}
                        >
                            {t('cancel')}
                        </div>
                        <button
                            className="footer-save "
                            onClick={() => {
                                let minval = parseInt(range.min);
                                let maxval = parseInt(range.max);
                                if (minval > maxval) {
                                    setError(
                                        "Minimum value can't  greater than maximum value"
                                    );
                                    return;
                                }
                                return props.handleSave(range);
                            }}
                        >
                            {t('Save')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
