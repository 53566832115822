import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Link,
    useLocation,
    useParams,
    useSearchParams
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Select, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretDown } from 'phosphor-react';
import { useDebouncedCallback } from 'use-debounce';

import ListingCard from './ListingCard';
import Card from './ui/Card';
import Loading from './ui/Loading';
import { NoCarFound } from './ui/NoCarFound';
import { SellYourCarForm } from './Certification';

import { getCarData } from '../redux/actions/carListingAction';
import { ICombineReducerType } from '../redux/reducers';
import { $FIXME, COUNTRY_TOP_LEVEL_DOMAIN } from '../constants';
import { CarFilterType, CarSortType } from '../interface/car';
import { Stringfy } from '../utils/Helper/jsHelpers';
import { appendClassInNavbarAction } from '../redux/actions/appendClassInNavbarActions';
import { api } from '../helpers/api';
import { getTopLevelDomain } from '../utils/translation';
import {
    buyCarListLoadingDisable,
    buyCarListLoadingEnable,
    resetBuyCarsList,
    setBuyCarListPage
} from '../redux/actions/carListAction';
import { decodeCompactNumber } from '../utils/Helper/PriceFormatter';

const { Option } = Select;

const Listings = (props: $FIXME) => {
    const { t } = useTranslation();
    const topLevelDomain = getTopLevelDomain();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { brandName, bodyTypeName, priceRange } = useParams();
    const location = useLocation();

    const { totalCar } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const carListData = useSelector(
        (state: ICombineReducerType) => state.carListReducer
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const showModal = (e: any) => {
        setIsModalVisible(true);
        appendClassInNavbarAction(dispatch, true);
        e.preventDefault();
    };
    const handleCancel = () => {
        setIsModalVisible(false);
        appendClassInNavbarAction(dispatch, false);
    };
    const handleOk = () => {
        setIsModalVisible(false);
        appendClassInNavbarAction(dispatch, false);
    };
    const debounced = useDebouncedCallback(fetchData, 1000);
    const debounceFetch = useDebouncedCallback(fetchData, 300);

    const [loadinge, setLoadinge] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const observer = useRef<any>();

    const lastElementRef = useCallback(
        (node: any) => {
            if (loadinge) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        if (
                            !loadinge &&
                            carListData?.buyPage <= carListData?.totalBuyPage
                        ) {
                            setLoadinge(true);
                            debounceFetch();
                            return;
                        }
                        setHasMore(false);
                    }
                },
                {
                    rootMargin: '300px'
                }
            );
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadinge, hasMore, carListData]
    );

    useEffect(() => {
        (async function () {
            await buyCarListLoadingEnable(dispatch);
        })();
        return () => {
            resetBuyCarsList(dispatch);
        };
    }, []);

    async function fetchData() {
        const modelYearParams = searchParams.getAll('modelYear');
        const brand = [brandName];
        const bodyType = [bodyTypeName];
        const maxPriceParam = priceRange;

        let currentYearAndAbove = [];
        if (
            modelYearParams &&
            Array.isArray(modelYearParams) &&
            modelYearParams.length > 0
        ) {
            const modelYearsAPIData = await api.get('/cars/get-car-years');
            const currentYear = modelYearParams[0]
                .split('')
                .filter((item) =>
                    ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(
                        item
                    )
                )
                .join('');
            currentYearAndAbove = modelYearsAPIData.data.modelYears.filter(
                (modelYr: string) => modelYr >= currentYear
            );
        }

        const resData = await getCarData(dispatch, {
            search: searchParams.get('search') ?? undefined,
            brands: brandName
                ? Stringfy(brand)
                : Stringfy(searchParams.getAll('brand')) ?? undefined,
            models: Stringfy(searchParams.getAll('model')) ?? undefined,
            minPrice: searchParams.get('minPrice') ?? undefined,
            maxPrice: priceRange
                ? decodeCompactNumber(maxPriceParam)
                : searchParams.get('maxPrice') ?? undefined,
            colors: Stringfy(searchParams.getAll('color')) ?? undefined,
            transmissions:
                Stringfy(searchParams.getAll('transmission')) ?? undefined,
            bodyTypes: bodyTypeName
                ? Stringfy(bodyType)
                : Stringfy(searchParams.getAll('bodyType')) ?? undefined,
            fuelType: searchParams.get('fuelType') ?? undefined,
            modelYears: Stringfy(currentYearAndAbove) ?? undefined,
            sort: (searchParams.get('sort') as CarSortType) ?? '-price',
            isNewCar:
                (searchParams.get('isNewCar') as CarFilterType) ?? undefined,
            isElectric:
                (searchParams.get('isElectric') as CarFilterType) ?? undefined,
            imported:
                (searchParams.get('imported') as CarFilterType) ?? undefined,
            minMileage: searchParams.get('minMileage') ?? undefined,
            maxMileage: searchParams.get('maxMileage') ?? undefined,
            minMonthlyInstallment:
                searchParams.get('minMonthlyInstallment') ?? undefined,
            maxMonthlyInstallment:
                searchParams.get('maxMonthlyInstallment') ?? undefined,
            discount:
                (searchParams.get('discount') as CarFilterType) ?? undefined,
            page: carListData?.buyPage,
            limit: 9
        });
        if (resData.status === 200) {
            await setBuyCarListPage(dispatch, carListData?.buyPage + 1);
            setLoadinge(false);
            setHasMore(resData?.data?.cars.length > 0);
        } else {
            setHasMore(false);
        }
        await buyCarListLoadingDisable(dispatch);
    }

    useEffect(() => {
        (async function () {
            if (searchParams.toString() || location.pathname) {
                window.scrollTo(0, 0);
                await setBuyCarListPage(dispatch, 1);
                await resetBuyCarsList(dispatch);
                await buyCarListLoadingEnable(dispatch);
                debounced();
            }
        })();
    }, [searchParams, location.pathname]);
    const sortByArray = [
        { name: 'highestPriceFirst', value: '-price' },
        { name: 'lowestPriceFirst', value: 'price' },
        { name: 'highestMileageFirst', value: '-mileage' },
        { name: 'lowestMileageFirst', value: 'mileage' }
    ];
    const filterByArray = [
        { name: 'all', value: '', omit: false },
        { name: 'new', value: 'isNewTrue', omit: false },
        { name: 'used', value: 'isNewFalse', omit: false },
        {
            name: 'discount',
            value: 'discount',
            omit: topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.asterdio
        }
    ];
    const FILTER_BY_ARRAY = filterByArray.filter((item) => !item.omit);
    const minimumCars = 5;

    const onSortSelect = (sortValue: string, e: $FIXME) => {
        searchParams.set('sort', sortValue);
        setSearchParams(searchParams);
    };
    const onFilterSelect = (filterValue: string, e: $FIXME) => {
        if (filterValue === '') {
            searchParams.delete('isNewCar');
            searchParams.delete('discount');
        } else if (
            filterValue === 'isNewTrue' ||
            filterValue === 'isNewFalse'
        ) {
            searchParams.delete('discount');
            searchParams.set(
                'isNewCar',
                filterValue === 'isNewTrue' ? 'true' : 'false'
            );
        } else if (filterValue === 'discount') {
            searchParams.delete('isNewCar');
            searchParams.set('discount', 'true');
        }
        setSearchParams(searchParams);
        debounced();
    };

    const Listing = () => (
        <>
            <div className="listing-container">
                <div className="listing__holder padding ">
                    {carListData?.buyCars
                        ?.slice(0, 4)
                        .map((items: $FIXME, index: number) => {
                            return (
                                <div key={index} className="card-holder">
                                    <Card item={items} />
                                </div>
                            );
                        })}
                    {carListData?.buyCars.length >= minimumCars ? (
                        <div className="card-holder">
                            <Link to={t('/car-finance', { ns: 'routes' })}>
                                {topLevelDomain ===
                                COUNTRY_TOP_LEVEL_DOMAIN.ma ? (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            paddingBottom: '6px'
                                        }}
                                    >
                                        <img
                                            src="/images/creditGratuit.jpeg"
                                            alt="creditGratuit"
                                        />
                                    </div>
                                ) : (
                                    <ListingCard
                                        color="dark-blue"
                                        imagesrc="/images/listingcar.webp"
                                        title="loanTitle"
                                        subtitle="loanHappyCusSubtitle"
                                        btntext="loanButton"
                                    />
                                )}
                            </Link>
                        </div>
                    ) : (
                        ''
                    )}
                    {carListData?.buyCars
                        ?.slice(4, 9)
                        .map((items: any, index: number) => {
                            if (
                                carListData?.buyCars.length ===
                                index + minimumCars
                            )
                                return (
                                    <div
                                        ref={lastElementRef}
                                        key={index}
                                        className="card-holder"
                                    >
                                        <Card item={items} />
                                    </div>
                                );
                            return (
                                <div key={index} className="card-holder">
                                    <Card item={items} />
                                </div>
                            );
                        })}
                    {carListData?.buyCars.length >=
                        minimumCars + minimumCars && (
                        <div className="card-holder">
                            {topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.ma ? (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        paddingBottom: '6px'
                                    }}
                                >
                                    <img
                                        src="/images/creditGratuit.jpeg"
                                        alt="creditGratuit"
                                    />
                                </div>
                            ) : (
                                <ListingCard
                                    imagesrc="/images/listingcar.webp"
                                    color="white"
                                    title={t('sellYourCar')}
                                    subtitle={t(
                                        'knowaboutyourcarbetterandgetevaluation'
                                    )}
                                    btntext={t('sellYourCar')}
                                    onClick={showModal}
                                />
                            )}
                        </div>
                    )}
                    {carListData?.buyCars
                        ?.slice(9)
                        .map((items: any, index: number) => {
                            if (
                                carListData?.buyCars.length ===
                                index + minimumCars + minimumCars
                            )
                                return (
                                    <div
                                        ref={lastElementRef}
                                        key={index}
                                        className="card-holder"
                                    >
                                        <Card item={items} />
                                    </div>
                                );
                            return (
                                <div key={index} className="card-holder">
                                    <Card item={items} />
                                </div>
                            );
                        })}

                    {hasMore && carListData?.buyCars.length < 1 && (
                        <Loading
                            style={{
                                display: 'flex',
                                width: '100%',
                                textAlign: 'center'
                            }}
                            id="first"
                        />
                    )}
                    {hasMore &&
                        carListData?.buyCars.length > 0 &&
                        carListData?.buyCars.length !==
                            carListData?.totalBuyCars && (
                            <Loading
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                                id="second"
                            />
                        )}
                </div>

                <Modal
                    className="listing-modal ants-modal"
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    centered={true}
                >
                    <SellYourCarForm
                        setIsModalVisible={setIsModalVisible}
                        from="listing-modal"
                    />
                </Modal>
            </div>
        </>
    );
    const SortBy = () => (
        <div className="sort-by">
            {t('sortBy')}
            <Select
                defaultValue={searchParams.get('sort') ?? sortByArray[0].value}
                className="sort-car-select"
                defaultActiveFirstOption={true}
                bordered={false}
                getPopupContainer={(trigger: $FIXME) => trigger.parentNode}
                onChange={onSortSelect}
                suffixIcon={
                    <CaretDown size={20} color="#175adc" weight="bold" />
                }
            >
                {sortByArray.map((options, index) => {
                    return (
                        <Option
                            value={options.value}
                            key={index}
                            dropdownClassName="sortby-dropdown"
                        >
                            {t(options.name)}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
    function getDefaultValueFilterBy() {
        const newCarValue = searchParams.get('isNewCar') ?? null;
        const discountValue = searchParams.get('discount') ?? null;
        if (newCarValue) {
            if (newCarValue === 'true') return 'isNewTrue';
            return 'isNewFalse';
        }
        if (discountValue) {
            return 'discount';
        }
        return FILTER_BY_ARRAY[0].value;
    }
    const FilterBy = () => (
        <div className="sort-by">
            {t('filterBy')}
            <Select
                defaultValue={getDefaultValueFilterBy()}
                className="filter-car-select"
                defaultActiveFirstOption={true}
                bordered={false}
                getPopupContainer={(trigger: $FIXME) => trigger.parentNode}
                onChange={onFilterSelect}
                suffixIcon={
                    <CaretDown size={20} color="#175adc" weight="bold" />
                }
            >
                {FILTER_BY_ARRAY.map((options, index) => {
                    return (
                        <Option
                            value={options.value}
                            key={index}
                            dropdownClassName="sortby-dropdown"
                        >
                            {t(options.name)}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );

    return (
        <div className="listings ">
            <div className="listing-tops padding">
                <h3 className="listing-title">
                    {carListData?.buyCars?.length > 0 &&
                        t('xlistingPageResultTitle', {
                            number: totalCar
                        })}{' '}
                </h3>
                <div className="listing-filter">
                    <FilterBy />
                    <SortBy />
                </div>
            </div>
            {carListData?.buyIsLoading ? (
                <Loading style={{ display: 'block' }} id="lower" />
            ) : carListData?.buyCars.length > 0 ? (
                <Listing />
            ) : (
                <NoCarFound {...props} />
            )}
        </div>
    );
};

export default Listings;
