import { CSSProperties, MouseEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { AutoComplete, Form, Modal } from 'antd';
import {
    CalendarBlank,
    Car,
    CirclesThree,
    CircleWavyQuestion,
    GasPump,
    User,
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import SecSeperator from './ui/SecSeperator';

import LoadingButton from './ui/LoadingButton';
import Selects from './ui/Select';

import { $FIXME } from '../constants';
import { CAR_CONDITION, getYearList } from '../helpers/constants';
import { defaultDailing_Code, getCountryByCode } from '../helpers/countryCode';
import { getNumbersOnly } from '../helpers/functions';
import { IBrand, ICarBodyType } from '../interface/car';
import { getCarBodyTypes } from '../redux/actions/carActions';
import { getCarBrandsList } from '../redux/actions/carBrandsAction';
import {
    fetchCarModelByModel,
    getAllCarModels
} from '../redux/actions/carModelsAction';
import {
    createSellingCar,
    userRequirements
} from '../redux/actions/sellCarAction';
import { ICombineReducerType } from '../redux/reducers';
import {
    contentAccordToCurrentLang,
    fullNameValidate,
    toSlug
} from '../utils/Helper/jsHelpers';
import { getMileageOptions } from './SellDetails';
import FourCircleSvg from './ui/icons/FourCircleSvg';
import MeterSvg from './ui/icons/MeterSvg';
import TransmissionSvg from './ui/icons/TransmissionSvg';
import PhoneNumberInput from './ui/PhoneNumberInput';

export const setFullNameError = function (
    fullName: $FIXME,
    setErrors: $FIXME,
    t: $FIXME
) {
    if (!fullNameValidate(fullName)) {
        setErrors({
            fullName: t('pleaseEnterFullName')
        });
    }
};
interface IProps {
    title?: string;
    image?: string;
    style?: CSSProperties;
    subtitle?: string;
    from: string;
    buttonText?: string;
    className?: string;
}
const Certification = ({
    title,
    image,
    style,
    subtitle,
    from,
    buttonText,
    className
}: IProps) => {
    const { t, i18n } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = (e: any) => {
        e.preventDefault();
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };

    return (
        <div className={`certification  ${className}`} style={style}>
            <div className="certification-container padding">
                <div className="certi">
                    <SecSeperator />
                    <h2
                        className="title"
                        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    >
                        {title ? t(title) : t('sellYourCar')}
                    </h2>

                    <p
                        className="subtitle"
                        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    >
                        {subtitle
                            ? t(subtitle)
                            : t('pleaseprovideusyourcardetails')}
                    </p>
                    <div className="mobile-hide">
                        <SellYourCarForm from={from} />
                    </div>
                    <button className="bottom-btn mobile text-uppercase" onClick={showModal}>
                        {buttonText ? t(buttonText) : t('sellYourCar')}
                    </button>
                </div>
                <div className="image-container">
                    <img
                        alt="Sell Your Car"
                        src={image ? image : '/images/sellcar.svg'}
                        loading="lazy"
                    />
                </div>
            </div>

            <Modal
                className="certification-modal-container ants-modal"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={true}
                centered={true}
            >
                <SellYourCarForm from={from} />
            </Modal>
        </div>
    );
};

export default Certification;
export const SellYourCarForm = (props: any) => {
    const { t, i18n } = useTranslation();
    const [brandList, setBrandList] = useState<any[]>([]);
    const [modelsList, setModelsList] = useState<any[]>([]);
    const [successModal, setSuccessModal] = useState(false);
    const [load, setLoad] = useState(false);
    const initialRender = useRef(true);
    const [phoneValidErr, setPhoneValidErr] = useState({
        number: true
    });

    let { user } = useSelector((state: ICombineReducerType) => state.auth);
    const [certificationData, setCertificationData] = useState<any>({
        brandName: '',
        modelName: '',
        year: '',
        mileage: '',
        transmission: '',
        fuelType: '',
        condition: '',
        phoneNumber: {
            code: user?.phoneNumber?.code
                ? String(user?.phoneNumber?.code)
                : defaultDailing_Code,
            number: user?.phoneNumber?.number
                ? getNumbersOnly(`${user?.phoneNumber?.number}`)
                : '',
        },
        fullName: user?.name ?? ''
    });
    useEffect(() => {
        if (user) {
            setCertificationData((prev: any) => ({
                ...prev,
                phoneNumber: {
                    code: user?.phoneNumber?.code,
                    number: String(getNumbersOnly(user?.phoneNumber?.number)),
                },
                fullName: user?.name
            }));
        } else {
            setCertificationData((prev: any) => ({
                ...prev,
                phoneNumber: {
                    code: defaultDailing_Code,
                    number: '',
                },
                fullName: ''
            }));
        }
    }, [user]);
    const { bodyTypes } = useSelector(
        (state: ICombineReducerType) => state.car
    );
    const [bodyTypesList, setBodyTypeList] = useState<any[]>([]);

    const [errors, setErrors] = useState<any>({});
    const navigate = useNavigate();

    const onSelect = (value: string, key: string) => {
        let obj = JSON.parse(JSON.stringify(errors));
        delete obj[key];
        setErrors(obj);
        if (key === 'brandName') {
            setModelsList([]);
            setCertificationData({
                ...certificationData,
                [key]: value,
                modelName: ''
            });
        } else setCertificationData({ ...certificationData, [key]: value });
    };

    const dispatch = useDispatch();

    const carBrands = useSelector(
        (state: ICombineReducerType) => state.carBrands.carBrands
    );
    const carModels = useSelector(
        (state: ICombineReducerType) => state.carModels.carModels
    );
    const { loadingSellData } = useSelector(
        (state: ICombineReducerType) => state.sellingCars
    );

    useEffect(() => {
        if (!carBrands.length) getCarBrandsList(dispatch);
    }, []);
    useEffect(() => {
        if (props.from === 'lookingForCar') {
            getAllCarModels(dispatch);
            getCarBodyTypes(dispatch);
        }
    }, []);

    useEffect(() => {
        if (carBrands.length > 0) {
            const brandList: Array<any> = carBrands?.map((items: IBrand) => ({
                label: items.brand,
                value: items.brand,
                _id: items._id
            }));
            setBrandList(brandList);
        }
    }, [carBrands]);

    useEffect(() => {
        if (bodyTypes.filter((item) => item).length) {
            if (bodyTypes.length > 0) {
                const bodyTypesList: any[] = bodyTypes.map(
                    (item: ICarBodyType) => {
                        return {
                            label: contentAccordToCurrentLang(item).bodyType,
                            value: contentAccordToCurrentLang(item).bodyType,
                            _id: item._id
                        };
                    }
                );
                setBodyTypeList(bodyTypesList);
            }
        }
    }, [bodyTypes]);

    useEffect(() => {
        if (!initialRender.current) {
            validateField();
        }
    }, [certificationData, phoneValidErr]);
    useEffect(() => {
        if (!initialRender.current) {
            if (!certificationData.brandName) {
                if (props.from === 'lookingForCar') getAllCarModels(dispatch);
                else
                    setCertificationData((prv: any) => ({
                        ...prv,
                        modelName: ''
                    }));
            }
        } else {
            initialRender.current = false;
        }
    }, [certificationData.brandName]);
    const [disabled, setDisabled] = useState(true);
    useEffect(() => {
        if (certificationData.brandName) {
            fetchCarModelByModel(dispatch, toSlug(certificationData.brandName));
        }
    }, [certificationData?.brandName]);
    useEffect(() => {
        if (carModels.length > 0) {
            const carModelsList = carModels.map((items) => ({
                label: `${items.model}`,
                value: items.model,
                _id: items._id
            }));
            setModelsList(carModelsList);
        } else {
            setModelsList([])
        }
    }, [carModels]);

    const required =
        props.from === 'lookingForCar'
            ? [
                  'brandName',
                  'modelName',
                  'condition',
                  'mileage',
                  'bodyTypeName',
                  'fullName',
                  'phoneNumber.number'
              ]
            : ['*'];

    useEffect(() => {
        if (fullNameValidate(certificationData.fullName)) {
            setErrors({});
        }
    }, [certificationData.fullName]);

    function validateField() {
        if (props.from === 'lookingForCar') {
            let o = required
                .slice(0, 5)
                .filter((item) => !certificationData[item]);
            let r = required
                .filter((key) => key === 'number' || key === 'fullName')
                .filter((key) => !certificationData[key]);
            if (
                o.length < 5 &&
                !r.length &&
                fullNameValidate(certificationData.fullName) &&
                !phoneValidErr?.number
            ) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            let arr = Object.keys(certificationData).filter(
                (item) => !certificationData[item]
            );
            if (
                !arr.length &&
                fullNameValidate(certificationData.fullName) &&
                !phoneValidErr?.number
            ) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }

    const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!disabled) {
            setLoad(true);
            let data = Object.keys(certificationData).reduce(
                (data: any, key) => {
                    if (certificationData[key]) {
                        data[key] = certificationData[key];
                    }
                    return data;
                },
                {}
            );

            setErrors((prev: $FIXME) => ({
                ...prev,
                number: undefined
            }));

            (props.from === 'lookingForCar'
                ? userRequirements(dispatch, {
                      ...data
                  })
                : createSellingCar(dispatch, {
                      ...data
                  })
            ).then((res) => {
                if (res) {
                    setCertificationData({
                        phoneNumber: {
                            code: user?.phoneNumber?.code ?? defaultDailing_Code,
                            number: user?.phoneNumber?.number ?? '',
                        },
                        fullName: user?.name ?? '',
                        brandName: '',
                        modelName: '',
                        bodyTypeName: '',
                        year: '',
                        mileage: '',
                        transmission: '',
                        fuelType: '',
                        condition: ''
                    });
                    setSuccessModal(true);
                }
            }).finally(() => {
                setLoad(false)
            });
        }
    };

    const goToListings = () => {
        props.from === 'listing-modal' && props.setIsModalVisible(false);
        navigate(t('/buy-cars', { ns: 'routes' }));
        setSuccessModal(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const closeModal = () => {
        props.from === 'listing-modal' && props.setIsModalVisible(false);
        setSuccessModal(false);
    };
    const oncloseModal = () => {
        props.from === 'listing-modal' && props.setIsModalVisible(false);
        setSuccessModal(false);
    };

    const handlePhoneNumberInputChange = (value: any, key: string) => {
        let obj = JSON.parse(JSON.stringify(errors));
        delete obj[key];
        delete obj['code'];
        setErrors(obj);
        setCertificationData({
            ...certificationData,
            phoneNumber: {
                [key]: String(getNumbersOnly(value[key])),
                code: value?.code
            }
        });
    };

    return (
        <>
            <div>
                <div className="desktop-hide">
                    <SecSeperator className="left-align" />
                    <h2 className="title">{t('sellYourCar')}</h2>
                    <p className="subtitle start ">
                        {t('knowaboutyourcarbetterandgetevaluation')}
                    </p>
                </div>
                {<span className="error-label">{t(errors?.listingError)}</span>}
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    <div className="certification-form">
                        <label className="display-contents">
                            <div style={{width: '100%'}}>
                                <div
                                    className={`certification-input ${
                                        errors.brandName && 'border-error'
                                    }  `}
                                >
                                    <div className="selects-prefixicon">
                                        <Car size={24} color="#727272" />
                                    </div>
                                    <AutoComplete
                                        placeholder={t('selectorenterbrand')}
                                        options={brandList}
                                        value={certificationData.brandName}
                                        filterOption={(inputValue, option) => 
                                            option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={(data) => 
                                            setCertificationData({
                                                ...certificationData, 
                                                brandName: data.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase())
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </label>
                        <div className="certification-top">
                            <label className='display-contents'>
                                <div style={{width: '100%'}}>
                                    <div
                                        className={`certification-input ${
                                            errors.model && 'border-error'
                                        }  `}
                                    >
                                        <div className="selects-prefixicon">
                                            <FourCircleSvg stroke="#727272" />
                                        </div>
                                        <AutoComplete
                                            placeholder={t('selectorentermodel')}
                                            disabled={
                                                !certificationData.brandName &&
                                                props.from !== 'lookingForCar'
                                                    ? true
                                                    : false
                                            }
                                            className={
                                                !certificationData.brandName &&
                                                props.from !== 'lookingForCar'
                                                    ? ''
                                                    : 'select-cursor'
                                            }
                                            options={modelsList}
                                            value={certificationData.modelName}
                                            filterOption={(inputValue, option) => 
                                                option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            onChange={(data) => 
                                                setCertificationData({
                                                    ...certificationData, 
                                                    modelName: data
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </label>
                            {props.from === 'lookingForCar' ? (
                                <label className='display-contents'>
                                    <div style={{width: '100%'}}>
                                        <div
                                            className={`certification-input ${
                                                errors.bodyTypeName && 'border-error'
                                            }  `}
                                        >
                                            <div className="selects-prefixicon">
                                                <CirclesThree size={24} color="#727272" />
                                            </div>
                                            <AutoComplete
                                                placeholder={t('selectorenterbodyType')}
                                                options={bodyTypesList}
                                                value={certificationData.bodyTypeName}
                                                filterOption={(inputValue, option) => 
                                                    option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={(data) => 
                                                    setCertificationData({
                                                        ...certificationData, 
                                                        bodyTypeName: data.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase())
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </label>
                            ) : (
                                <label className="display-contents">
                                    <Selects
                                        error={errors.year}
                                        prefixicon={
                                            <CalendarBlank size={24} color="#727272" />
                                        }
                                        placeholder={t('selectYear')}
                                        name="year"
                                        options={getYearList().map(
                                            (
                                                item: any,
                                                index: number,
                                                arry
                                            ) => {
                                                return index + 1 === arry.length
                                                    ? {
                                                          label: t('other'),
                                                          value: 'other'
                                                      }
                                                    : {
                                                          label: item,
                                                          value: item
                                                      };
                                            }
                                        )}
                                        value={certificationData.year}
                                        onSelect={onSelect}
                                        showSearch={true}
                                        class="select-cursor"
                                        aria-expanded="false"
                                        aria-labelledby="year"
                                    />
                                </label>
                            )}
                        </div>

                        <div className="certification-top">
                            {props.from === 'lookingForCar' ? (
                                <label className="display-contents">
                                    <Selects
                                        error={errors.condition}
                                        prefixicon={<MeterSvg />}
                                        placeholder={t('selectACondition')}
                                        size="16"
                                        weight="regular"
                                        options={CAR_CONDITION.map((item) => ({
                                            label: t(item.name),
                                            value: item.value
                                        }))}
                                        onSelect={onSelect}
                                        name="condition"
                                        value={certificationData.condition}
                                        showSearch={true}
                                        class="select-cursor"
                                        aria-expanded="false"
                                        aria-labelledby="condition"
                                    />
                                </label>
                            ) : (
                                <label className="display-contents">
                                    <Selects
                                        error={errors.mileage}
                                        prefixicon={<MeterSvg />}
                                        placeholder={t('selectMileageRange')}
                                        size="16"
                                        weight="regular"
                                        options={getMileageOptions(t)}
                                        onSelect={onSelect}
                                        name="mileage"
                                        value={
                                            certificationData.mileage
                                                ? certificationData.mileage
                                                : ''
                                        }
                                        showSearch={true}
                                        class="select-cursor"
                                        aria-expanded="false"
                                        aria-labelledby="mileage"
                                    />
                                </label>
                            )}
                            {props.from === 'lookingForCar' ? (
                                <label className="display-contents">
                                    <Selects
                                        error={errors.mileage}
                                        prefixicon={<MeterSvg />}
                                        placeholder={t('selectMileageRange')}
                                        size="16"
                                        weight="regular"
                                        options={getMileageOptions(t)}
                                        onSelect={onSelect}
                                        name="mileage"
                                        value={
                                            certificationData.mileage
                                                ? certificationData.mileage
                                                : ''
                                        }
                                        showSearch={true}
                                        class="select-cursor"
                                        aria-expanded="false"
                                        aria-labelledby="mileage"
                                    />
                                </label>
                            ) : (
                                <label className="display-contents">
                                    <Selects
                                        error={errors.transmission}
                                        prefixicon={
                                            <TransmissionSvg stroke="#727272" />
                                        }
                                        placeholder={t(
                                            'selectTransmissionType'
                                        )}
                                        name="transmission"
                                        options={['automatic', 'manual'].map(
                                            (item) => ({
                                                label: t(item),
                                                value: item
                                            })
                                        )}
                                        value={certificationData.transmission}
                                        onSelect={onSelect}
                                        showSearch={true}
                                        class="select-cursor"
                                        aria-expanded="false"
                                        aria-labelledby="transmission"
                                    />
                                </label>
                            )}
                        </div>
                        {props.from === 'lookingForCar' ? null : (
                            <div className="certification-top">
                                <label className="display-contents">
                                    <Selects
                                        error={errors.fuelType}
                                        prefixicon={
                                            <GasPump
                                                size={24}
                                                color="#727272"
                                            />
                                        }
                                        placeholder={t('selectFuelType')}
                                        name="fuelType"
                                        options={[
                                            'carburant',
                                            'diesel',
                                            'ev',
                                            'hybrid',
                                            'petrol',
                                            'phev',
                                            'other'
                                        ].map((item) => ({
                                            label: t(item),
                                            value: item
                                        }))}
                                        value={certificationData.fuelType}
                                        onSelect={onSelect}
                                        showSearch={true}
                                        class="select-cursor"
                                        aria-expanded="false"
                                        aria-labelledby="fuelType"
                                    />
                                </label>

                                <label className="display-contents">
                                    <Selects
                                        error={errors.condition}
                                        prefixicon={
                                            <CircleWavyQuestion
                                                size={24}
                                                color="#727272"
                                            />
                                        }
                                        placeholder={t('selectACondition')}
                                        name="condition"
                                        options={CAR_CONDITION.map((item) => ({
                                            label: t(item.name),
                                            value: item.value
                                        }))}
                                        value={certificationData.condition}
                                        onSelect={onSelect}
                                        showSearch={true}
                                        class="select-cursor"
                                        aria-expanded="false"
                                        aria-labelledby="condition"
                                    />
                                </label>
                            </div>
                        )}

                        <div className="cert-details">
                            <p className="subtitle">
                                {t('pleaseprovideusyourdetails')}
                            </p>

                            <div className='mb-12'>
                                <div
                                    className={`certification-input ${
                                        errors.fullName && 'border-error'
                                    }  `}
                                >
                                    <div className="selects-prefixicon">
                                        <User size={24} color="#727272" />
                                    </div>
                                    <input
                                        onBlur={() => {
                                            if (certificationData.fullName) {
                                                setFullNameError(
                                                    certificationData.fullName,
                                                    setErrors,
                                                    t
                                                );
                                            }
                                        }}
                                        type="text"
                                        placeholder={t('enterFullName')}
                                        name="fullName"
                                        value={certificationData.fullName ?? ''}
                                        onChange={(e) => {
                                            let obj = JSON.parse(
                                                JSON.stringify(errors)
                                            );
                                            delete obj.fullName;
                                            setErrors(obj);
                                            setCertificationData({
                                                ...certificationData,
                                                fullName: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                {/* <div>
                                    <span
                                        className={`error-label ${
                                            !!errors.fullName && 'fade-in'
                                        }`}
                                    >
                                        {errors.fullName}
                                    </span>
                                </div> */}
                            </div>
                            <PhoneNumberInput
                                onChange={handlePhoneNumberInputChange}
                                name="number"
                                value={getNumbersOnly(
                                    `${certificationData?.phoneNumber?.number}`
                                )}
                                defaultSelect={
                                    user?.phoneNumber?.code
                                        ? getCountryByCode(
                                              user?.phoneNumber?.code
                                          )
                                        : getCountryByCode(defaultDailing_Code)
                                }
                                setError={setPhoneValidErr}
                            />
                        </div>

                        <div className="cert-submit">
                            <span dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                {t('byClickingXButton', {
                                    name: t('send')
                                })}{' '}
                                <Link
                                    to={t('/terms-of-use', { ns: 'routes' })}
                                    className="termandpolicy"
                                >
                                    {t('termOfUse')}
                                </Link>{' '}
                                {t('and')}{' '}
                                <Link
                                    to={t('/privacy-policy', { ns: 'routes' })}
                                    className="termandpolicy"
                                >
                                    {t('privacyPolicy')}
                                </Link>
                            </span>
                            {load || loadingSellData ? (
                                <LoadingButton buttonText="sending" />
                            ) : (
                                <button
                                    className="bottom-btn button-primary send text-uppercase"
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={disabled}
                                >
                                    {t('send')}
                                </button>
                            )}
                        </div>
                    </div>
                </Form>
            </div>
            <Modal
                className="sell-modal ants-modal"
                title={t('cardetailssendSuccessfully')}
                open={successModal}
                onCancel={oncloseModal}
                closable={false}
                centered={true}
            >
                <p className="subtitle">{t('thanyouforprovidingusdetail')}</p>
                <div className="done-icon">
                    <img src="/images/done.webp" alt="done" loading='lazy' />
                </div>
                <div className="modal-btn-container">
                    <button className="explore" onClick={goToListings}>
                        {t('exploreCar', { ns: 'hero' })}
                    </button>
                    <button className="close" onClick={closeModal}>
                        {t('cancel')}
                    </button>
                </div>
            </Modal>
        </>
    );
};
