import { Dispatch } from 'redux';
import { BUY_CARS_LIST_LOADING_BEGIN, BUY_CARS_LIST_LOADING_END, BUY_CARS_LIST_PAGE, NEW_CARS_LIST_PAGE, RECENT_CARS_LIST_PAGE, RESET_BUY_CARS_LIST, RESET_NEW_CARS_LIST, RESET_RECENT_CARS_LIST } from '../types/carListTypes';

// For home page
export const resetRecentCarsList = (dispatch: Dispatch) => {
    dispatch({ type: RESET_RECENT_CARS_LIST });
};
export const setRecentCarListPage = (dispatch: Dispatch, payload: number) => {
    dispatch({ type: RECENT_CARS_LIST_PAGE, payload });
};

export const resetNewCarsList = (dispatch: Dispatch) => {
    dispatch({ type: RESET_NEW_CARS_LIST });
};
export const setNewCarListPage = (dispatch: Dispatch, payload: number) => {
    dispatch({ type: NEW_CARS_LIST_PAGE, payload });
};

// For listing page
export const resetBuyCarsList = (dispatch: Dispatch) => {
    dispatch({ type: RESET_BUY_CARS_LIST });
};

export const buyCarListLoadingEnable = (dispatch: Dispatch) => {
    dispatch({ type: BUY_CARS_LIST_LOADING_BEGIN });
};

export const buyCarListLoadingDisable = (dispatch: Dispatch) => {
    dispatch({ type: BUY_CARS_LIST_LOADING_END });
};

export const setBuyCarListPage = (dispatch: Dispatch, payload: number) => {
    dispatch({ type: BUY_CARS_LIST_PAGE, payload });
};