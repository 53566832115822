import { Form, FormInstance } from 'antd';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IntlTelInput, { CountryData } from 'react-intl-tel-input';
import {
    defaultDailing_Code,
    getCountryByCode
} from '../../helpers/countryCode';
import { getNumbersOnly } from '../../helpers/functions';
import {
	isValidPhoneNumber
} from 'libphonenumber-js'
import 'react-intl-tel-input/dist/main.css';

interface IProps {
    style?: CSSProperties;
    id?: string;
    name: any;
    value: any;
    defaultSelect?: string;
    onChange: any;
    disabled?: boolean;
    setIsValid?: (errData: boolean) => void;
    placeholder?: string;
    form?: FormInstance;
    required?: boolean;
    requiredMsg?: string;
    error?: any;
    setError?: any;
    className?: string;
}

const PhoneNumberInput = (props: IProps) => {
    const {
        value,
        name,
        setIsValid,
        onChange,
        defaultSelect,
        requiredMsg = 'Please input Phone Number',
        placeholder = 'Phone Number',
        disabled = false,
        form,
        error,
        setError,
        required = true,
        className,
        style,
    } = props;
    const { t } = useTranslation();

    const [isValidNum, setIsValidNum] = useState(true);
    const [errorBorder, setErrorBorder] = useState(false);

    const intlTelInputRef = useRef<any>(null);

    useEffect(() => {
    if(intlTelInputRef) {
        const container: any = intlTelInputRef?.current;
        if (container) {
            const dialCodeDiv = container.querySelector('.selected-dial-code');
            const selectedFlagDiv = container.querySelector('.selected-flag');
            const newArrowElem = document.createElement('div');
            newArrowElem.className = 'arrowElement';
        
            if (dialCodeDiv && selectedFlagDiv) {
                newArrowElem.addEventListener('click', (e: any) => {
                    e.stopPropagation();
                    selectedFlagDiv.click();
                })
                selectedFlagDiv.appendChild(newArrowElem);
                dialCodeDiv.addEventListener('click', (e: any) => {
                    e.stopPropagation();
                    selectedFlagDiv.click();
                });
            }
        }
    }
    }, []);

    const handlePhoneNumberChange = (
        _status: boolean,
        value: any,
        countryData: any,
        number: any,
        id: any
    ) => {
        const isValid = isValidPhoneNumber(`+${countryData?.dialCode}${value}`);
        if (!value) {
            setIsValidNum(true);
            if (required) {
                setError &&
                    setError((prevErr: any) => ({
                        ...prevErr,
                        [name]: true
                    }));
            }
        } else {
            setIsValidNum(isValid);
            setError &&
                setError((prevErr: any) => ({
                    ...prevErr,
                    [name]: !isValid
                }));
        }
        form && form.setFieldValue(name, getNumbersOnly(value));
        setErrorBorder(!isValid);

        setIsValid && setIsValid(isValid);

        onChange(
            {
                code: `+${countryData?.dialCode}`,
                [name]: value ?? ''
            },
            name
        );
    };

    const handleSelectFlag = (value: string, selectedCountry: CountryData) => {
        const isValid = isValidPhoneNumber(`+${selectedCountry?.dialCode}${value}`);
        if (!value) {
            setIsValidNum(true);
            if (required) {
                setError &&
                    setError((prevErr: any) => ({
                        ...prevErr,
                        [name]: true
                    }));
            }
        } else {
            setIsValidNum(isValid);
            form && form.setFieldValue(name, getNumbersOnly(value));
            setError &&
                setError((prevErr: any) => ({
                    ...prevErr,
                    [name]: !isValid
                }));
        }
        setErrorBorder(!isValid);
        setIsValid && setIsValid(isValid);
        onChange(
            {
                code: `+${selectedCountry?.dialCode}`,
                [name]: value ?? ''
            },
            name
        );
      };

    return (
        <div
            className={`phoneNumberInput ${
                required && (errorBorder || (error && error[name])) && !isValidNum
                    ? 'phoneNumberInput-error'
                    : ''
            } ${className ?? ''}`}
            style={style}
            ref={intlTelInputRef}
        >
            <Form.Item
                name={name}
                rules={[{ required: required, message: requiredMsg }]}
            >
                <>
                    <IntlTelInput
                        fieldName={name}
                        value={value}
                        defaultValue={value}
                        defaultCountry={
                            defaultSelect
                                ? defaultSelect.toLowerCase()
                                : getCountryByCode(
                                    defaultDailing_Code
                                )?.toLowerCase()
                        }
                        placeholder={placeholder}
                        onPhoneNumberChange={handlePhoneNumberChange}
                        onPhoneNumberBlur={handlePhoneNumberChange}
                        onSelectFlag={handleSelectFlag}
                        separateDialCode
                        containerClassName="intl-tel-input"
                        inputClassName="form-control"
                        disabled={disabled}
                    />
                    {!isValidNum && (
                        <div className="phoneNumberInput-errorField">
                            <span>{t('enterValidPhoneNumber')}</span>
                        </div>
                    )}
                </>
            </Form.Item>
        </div>
    );
};

export default PhoneNumberInput;
