export const isJson = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const getNumbersOnly = (numData: string | number): string | number => {
    const stringifiedNumData = String(numData);
    const transformedNum = stringifiedNumData.split('-').join('');
    return typeof numData === 'number' ? parseInt(transformedNum) : transformedNum;
  };
  
  export const removeCountryCodeFromNumber = (numData: string, dialCode: string): number => {
    const getNumberOnly = getNumbersOnly(numData);
    if(String(getNumberOnly).includes(dialCode)) {
      const stringifiedNumData = String(getNumberOnly).split(dialCode)[1];
      return parseInt(stringifiedNumData);
    }
    return parseInt(String(getNumberOnly));
  };