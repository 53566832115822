interface IconPropsInterface {
    width?: number;
    height?: number;
    fill?: string;
    stroke?: string;
}

const MeterSvg = (props: IconPropsInterface) => {
    return (
        <svg
            width={props.width ?? '24'}
            height={props.height ?? '24'}
            viewBox="0 0 24 24"
            fill='none'
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_9500_6829)">
                <path
                    d="M10.0003 1.6665C14.6028 1.6665 18.3337 5.39734 18.3337 9.99984C18.3337 14.6023 14.6028 18.3332 10.0003 18.3332C5.39783 18.3332 1.66699 14.6023 1.66699 9.99984C1.66699 5.39734 5.39783 1.6665 10.0003 1.6665ZM10.0003 3.33317C6.31866 3.33317 3.33366 6.31817 3.33366 9.99984C3.33366 13.6815 6.31866 16.6665 10.0003 16.6665C13.682 16.6665 16.667 13.6815 16.667 9.99984C16.667 6.31817 13.682 3.33317 10.0003 3.33317ZM13.1945 6.114C13.392 5.97567 13.6603 5.999 13.8303 6.16984C14.0003 6.33984 14.022 6.60817 13.8828 6.80317C12.0662 9.3415 11.0662 10.7015 10.8845 10.884C10.3962 11.3715 9.60449 11.3715 9.11616 10.884C8.62866 10.3957 8.62866 9.604 9.11616 9.11567C9.42783 8.80484 10.787 7.804 13.1945 6.114ZM14.5837 9.1665C15.0437 9.1665 15.417 9.53984 15.417 9.99984C15.417 10.4598 15.0437 10.8332 14.5837 10.8332C14.1237 10.8332 13.7503 10.4598 13.7503 9.99984C13.7503 9.53984 14.1237 9.1665 14.5837 9.1665ZM5.41699 9.1665C5.87699 9.1665 6.25033 9.53984 6.25033 9.99984C6.25033 10.4598 5.87699 10.8332 5.41699 10.8332C4.95699 10.8332 4.58366 10.4598 4.58366 9.99984C4.58366 9.53984 4.95699 9.1665 5.41699 9.1665ZM7.34866 6.16984C7.67366 6.49484 7.67366 7.02234 7.34866 7.34817C7.02366 7.67317 6.49533 7.67317 6.17033 7.34817C5.84533 7.02317 5.84533 6.49484 6.17033 6.16984C6.49533 5.84484 7.02283 5.84484 7.34866 6.16984ZM10.0003 4.58317C10.4603 4.58317 10.8337 4.9565 10.8337 5.4165C10.8337 5.8765 10.4603 6.24984 10.0003 6.24984C9.54033 6.24984 9.16699 5.8765 9.16699 5.4165C9.16699 4.9565 9.54033 4.58317 10.0003 4.58317Z"
                    fill={props.fill ?? '#727272'}
                />
            </g>
            <defs>
                <clipPath id="clip0_9500_6829">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default MeterSvg;
