interface IconPropsInterface {
    width?: number;
    height?: number;
    fill?: string;
    stroke?: string;
}

const TransmissionSvg = (props: IconPropsInterface) => {
    return (
        <svg
            width={props.width ?? '24'}
            height={props.height ?? '24'}
            viewBox="0 0 24 24"
            fill={props.fill ?? 'none'}
            strokeWidth={1.4}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 11.0001H19V5M11 5.00012V17.0001M3 5.00012V17.0001"
                stroke={props.stroke ?? "#B3B3B3"}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21 3C21 3.53043 20.7893 4.03914 20.4142 4.41421C20.0391 4.78929 19.5304 5 19 5C18.4696 5 17.9609 4.78929 17.5858 4.41421C17.2107 4.03914 17 3.53043 17 3C17 2.46957 17.2107 1.96086 17.5858 1.58579C17.9609 1.21071 18.4696 1 19 1C19.5304 1 20.0391 1.21071 20.4142 1.58579C20.7893 1.96086 21 2.46957 21 3V3ZM13 3C13 3.53043 12.7893 4.03914 12.4142 4.41421C12.0391 4.78929 11.5304 5 11 5C10.4696 5 9.96086 4.78929 9.58579 4.41421C9.21072 4.03914 9 3.53043 9 3C9 2.46957 9.21072 1.96086 9.58579 1.58579C9.96086 1.21071 10.4696 1 11 1C11.5304 1 12.0391 1.21071 12.4142 1.58579C12.7893 1.96086 13 2.46957 13 3V3ZM5 3C5 3.53043 4.78929 4.03914 4.41421 4.41421C4.03914 4.78929 3.53043 5 3 5C2.46957 5 1.96086 4.78929 1.58579 4.41421C1.21071 4.03914 1 3.53043 1 3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1C3.53043 1 4.03914 1.21071 4.41421 1.58579C4.78929 1.96086 5 2.46957 5 3V3ZM13 19C13 19.5304 12.7893 20.0391 12.4142 20.4142C12.0391 20.7893 11.5304 21 11 21C10.4696 21 9.96086 20.7893 9.58579 20.4142C9.21072 20.0391 9 19.5304 9 19C9 18.4696 9.21072 17.9609 9.58579 17.5858C9.96086 17.2107 10.4696 17 11 17C11.5304 17 12.0391 17.2107 12.4142 17.5858C12.7893 17.9609 13 18.4696 13 19V19ZM5 19C5 19.5304 4.78929 20.0391 4.41421 20.4142C4.03914 20.7893 3.53043 21 3 21C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19C1 18.4696 1.21071 17.9609 1.58579 17.5858C1.96086 17.2107 2.46957 17 3 17C3.53043 17 4.03914 17.2107 4.41421 17.5858C4.78929 17.9609 5 18.4696 5 19V19Z"
                stroke={props.stroke ?? "#B3B3B3"}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default TransmissionSvg;
