import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { SpinnerGap } from 'phosphor-react';

import ButtonComponent from '../ui/ButtonComponent';
import { $FIXME } from '../../constants';
import { request } from '../../helpers/request';
import Modal from '../ui/Modal';
import { returnOnlyNumber } from '../../utils/Helper/jsHelpers';

const OtpVerification = ({
    handleActiveModal,
    phonenumber,
    email,
    token,
    fromForget,
    handleCancel
}: $FIXME) => {
    const { t } = useTranslation();
    const [otp, setOtp] = useState({
        first: '',
        second: '',
        third: '',
        fourth: '',
        fifth: ''
    });
    const [loading, setLoading] = useState(false);
    function resetOtpState() {
        setOtp({
            first: '',
            second: '',
            third: '',
            fourth: '',
            fifth: ''
        });
    }
    const navigate = useNavigate();
    const location = useLocation();
    const [successModal, setSuccessModal] = useState(false);
    const [verifyToken, setToken] = useState(token ?? '');
    const resendOpt = () => {
        (!fromForget
            ? request.resendOtp(phonenumber)
            : request.getForgetPasswordLink({ email })
        )
            .then((res: AxiosResponse) => {
                message.success(res.data.msg);
                !fromForget
                    ? setToken(res.data.phoneNumberVerificationToken)
                    : setToken(res.data.forgotPasswordToken);
            })
            .catch((err: $FIXME) => {
                handleCancel();
            });
    };
    function handleChange(e: any) {
        e.preventDefault();
        let { name, value } = e.target;
        if (value) {
            e.target.nextSibling?.focus();
        }
        setOtp((prev) => ({
            ...prev,
            [name]: value
        }));
    }
    function submit(e: any) {
        setLoading(true);
        e.preventDefault();
        (!fromForget
            ? request.verifyOtp(Object.values(otp).join(''), verifyToken)
            : request.verifyForgetPasswordOtp(
                  Object.values(otp).join(''),
                  verifyToken
              )
        )
            .then((res: AxiosResponse) => {
                message.success(t('phoneNumberVerifiedSuccess'));
                // message.success(res.data.msg);
                if (fromForget) {
                    setToken(res.data.forgotPasswordToken);
                    handleActiveModal(3);
                } else {
                    setSuccessModal(true);
                }
            })
            .catch((err: $FIXME) => {
                resetOtpState();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handlePast(e: any) {
        let number = returnOnlyNumber(e.clipboardData.getData('text')).split(
            ''
        );
        ['first', 'second', 'third', 'fourth', 'fifth'].forEach((item, i) =>
            setOtp((prev) => ({
                ...prev,
                [item]: number[i]
            }))
        );
    }

    return (
        <div className="otpVerification">
            <div className="otp-verification-subs">
                {fromForget
                    ? t('otpVerificationEmail', { email: email })
                    : t('otpVerificationSubtitle', {
                          code: phonenumber.code,
                          number:
                              phonenumber.number.slice(0, 2) +
                              Array(phonenumber.number.slice(2, 8).length)
                                  .fill('*')
                                  .join('') +
                              phonenumber.number.slice(8, 10)
                      })}
            </div>

            <div className="otp-inputs" onSubmit={submit}>
                <form onPaste={handlePast}>
                    <input
                        required
                        type="text"
                        autoFocus
                        maxLength={1}
                        name="first"
                        onChange={handleChange}
                        value={otp.first}
                    />
                    <input
                        required
                        type="text"
                        value={otp.second}
                        maxLength={1}
                        onChange={handleChange}
                        name="second"
                    />
                    <input
                        required
                        type="text"
                        maxLength={1}
                        value={otp.third}
                        onChange={handleChange}
                        name="third"
                    />
                    <input
                        required
                        type="text"
                        maxLength={1}
                        value={otp.fourth}
                        onChange={handleChange}
                        name="fourth"
                    />
                    <input
                        required
                        name="fifth"
                        type="text"
                        onChange={handleChange}
                        value={otp.fifth}
                        maxLength={1}
                    />
                    <ButtonComponent
                        className="otpbutton"
                        htmlType="submit"
                        type="primary"
                        disabled={loading}
                    >
                        {loading && (
                            <SpinnerGap
                                size={32}
                                weight="bold"
                                className="rotate"
                            />
                        )}
                        {fromForget ? t('resetPassword') : t('verifyNumber')}
                    </ButtonComponent>
                </form>
            </div>
            <span className="otp-footer-info">
                {t('didntGetTheCode')} &nbsp;
                <span onClick={resendOpt}>{t('resendCode')}</span>
            </span>

            <Modal
                closable={false}
                isLeftRequired={false}
                isVisible={successModal}
                handleCancel={() => {
                    handleCancel();
                    setSuccessModal(!successModal);
                }}
                header={t('phoneVerifiedSuccesfully')}
                backdropType="blur"
                rightPadding="40"
                size="medium"
                secSeperator={false}
            >
                <div className="reset-successful">
                    <span>{t('phoneVerifiedSubtitle')}</span>
                    <img src="/images/check2.svg" alt="check" />
                    <div className="reset-success-button-wrapper">
                        <ButtonComponent
                            type="primary"
                            onClick={() => handleActiveModal(1)}
                            className="reset-success-button"
                        >
                            {t('signIn')}
                        </ButtonComponent>
                        <ButtonComponent
                            type="secondary"
                            className="reset-success-button"
                            onClick={() => {
                                let path = location.pathname.replace('/en', '');
                                if (
                                    path === '/buy-cars' ||
                                    path === '/acheter-voitures'
                                ) {
                                    handleCancel();
                                } else {
                                    navigate(t('/buy-cars', { ns: 'routes' }));
                                }
                            }}
                        >
                            {t('exploreCar', { ns: 'hero' })}
                        </ButtonComponent>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default OtpVerification;
