const commonXhosa = {
    and: 'Kwaye',
    buy: 'Thenga',
    sell: 'Thengisa',
    send: 'Thumela',
    finance: 'Ezemali',
    about: 'Malunga',
    blogs: 'Iiblogi',
    blog: 'Blog',
    callUsAt: 'Call us at',
    signIn: 'Ngena',
    signUp: 'bhalisa',
    or: 'Okanye',
    insure: 'uqinisekise',
    enteremailAddress: 'ngenisa idilesi ye-imeyile',
    enterPassword: 'ngenisa igama lokugqitha',
    rememberMe: 'Ndikhumbule',
    forgotPassword: 'ingaba uyilibele iphasiwedi',
    carsAvailable: 'iimoto ezikhoyo ngoku',
    cancel: 'rhoxisa',
    enterFullName: 'ngenisa igama elipheleleyo',
    byClickingXButton: 'Ngokucofa {{name}} iqhosha,uyavumelana neyethu',
    termOfUse: 'imimmiselo yokusebenzisa',
    privacyPolicy: 'umthetho wabucala',
    alreadyhaveanAccount: 'sele unayo iakhawunti',
    howItWorks: 'Ingaba isebenza kanjani',
    howtoBuy: 'Indlela yokuthenga',
    howtoSell: 'Indlela yokuthengisa',
    exploreBlogs: 'Jonga iiblogi',
    car: 'imoto',
    search: 'khangela',
    Certified: 'Eqinisekisiweyo',
    Inspected: 'Ihlolwe',
    ev: 'Umbane',
    hybrid: 'Hybrid',
    phev: 'PHEV',
    petrol: 'Essence',
    diesel: 'Idizili',
    carburant: 'Isibaso',
    other: 'Other',
    automatic: 'Oluzenzekelayo',
    pleaseprovideusyourdetails: 'nceda usinike iinkcukacha zakho',
    pleaseprovideusyourcardetails: 'nceda usinike iinkcukacha zemoto yakho',
    desiredPleaseprovideusyourcardetails:
        'nceda usinike iinkcukacha zemoto ozifunayo, kheta nokuba ibe nye',
    enterCarDetails: 'Enter Car Details',
    phonenumberisnotvalid: 'inombolo yefowuni ayisebenzi',
    internalError:
        'impazamo yangaphakathi. Nceda uzame kwakhona okanye uqhagamshelane nathi.',
    knowaboutyourcarbetterandgetevaluation:
        'Yazi ngemoto yakho ngcono kwaye ufumane ixabiso lesivumelwano esingcono.',
    cardetailssendSuccessfully: 'iinkcukacha zemoto zithunyelwe ngempumelelo',
    thanyouforprovidingusdetail:
        'Enkosi ngokusibonisa iinkcukacha. Siza kukutsalela umnxeba kungekudala',
    selectbrand: 'Khetha uphawu',
    selectcountry: 'Select country',
    enterMileage: 'Enter Mileage',
    minMileage: 'Minimum Mileage',
    maxMileage: 'Maximum Mileage',
    selectmodel: 'Khetha imodeli',
    selectYear: 'Khetha unyaka',
    selectorenterbrand: 'Khetha okanye ufake igama',
    selectorentermodel: 'Khetha okanye ngenisa imodeli',
    selectorenterbodyType: 'Khetha okanye ufake uhlobo lomzimba',
    brand: 'Uphawu',
    model: 'iModeli',
    modelYear: 'Unyaka oyimodeli',
    selectFuelType: 'Khetha uhlobo lwesibaso',
    selectACondition: 'Khetha imeko',
    phoneNumber: 'inombolo yomnxeba',
    loanTitle: 'Sikunika olona nikezelo lungcono, fumana inzala eyi-10%',
    loanHappyCusSubtitle:
        'Siyabakhathalela abathengi bethu kunye nolonwabo lwabo olusikhuthazayo ukuba sinikeze iinkonzo enzininzi',
    loanButton: 'Ukubala imali-mboleko yam',
    calculateButton: 'Calculate',
    blogTitle: 'Jonga phambi kokuthenga imoto',
    blogSubtitle:
        'Ezi zizinto omele uzazi phambi kokuthenga naluphi na uhlobo lwemoto',
    viewAllCars: 'Jonga zonke iimoto',
    availableNowOn: 'Iyafumaneka ngoku',
    account: 'iAkhawunti',
    myAccount: 'i_Akhawunti yam',
    ourBrands: 'iImpawu zethu',
    contactUs: 'Qhagamshelana nathi',
    contactInformation: 'Linkcukacha zoqhakamshelwano',
    aboutUs: 'Ngathi',
    addressLine: 'Umgca wedilesi',
    contact: 'Contact',
    whyChooseAuto: 'Why Choose AUTO24?',
    allcarshavebeenthoroughlyinspectedandreconditionedHowweguaranteecarquality:
        'Zonke iimoto zahlolwa ngokucokisekileyo kwaye zalungiswa. Siqinisekisa njani umgangatho wemoto',
    imInterested: 'Ndinomdla',
    buyOnline: 'Buy Online',
    downPayment: 'Ubeka imali phantsi',
    hotDeal: 'isivumelwano esishushu',
    lowPrice: 'Ixabiso eliphantsi',
    likeNew: 'Njengentsha',
    moneybackSubtitle: 'Awuthandi imoto? Siya kukubuyisela imali.',
    warrentyTitle: 'Iwaranti yeenyanga ezi_6',
    warrentySubtitle:
        'Iyanwebeka ukuya kwiinyanga ezili-12 kwi-injini, kwi-gearbox nakwi-powertrain',
    inspectionPointsSubtitle:
        'Ihlolwe liqela lethu leengcaphephe zemoto kuzo zonke ii-engile',
    roadSideAssistanceTitle: 'Uncedo lwe nyaka omnye',
    roadSideAssistanceSubtitle:
        'Uncedo olusecaleni kwendlela lubandakanyiwe kuzo zonke izithuthi zethu, unyaka omnye',
    deliveryTitle: 'Ukuhanjiswa ekhaya',
    deliverySubtitle: 'Sinokuwuletha imoto yakho naphi na e-South Africa',
    xDeliverySubtitle: 'Sinokuwuletha imoto yakho naphi na e-{{country}}',
    Summary: 'Isishwankathelo',
    engine: 'Injini',
    enginePower: 'Injini',
    fuelType: 'Uhlobo lwamafutha',
    bodyType: 'Uhlobo lomzimba',
    numberofkeys: 'Inani lezitshixo',
    exteriorColor: 'Umbala wangaphandle',
    interiorColor: 'Umbala wangaphakathi',
    engineSize: 'Ubungakanani benjini',
    fiscalPower: 'Amandla emali',
    specifications: 'Iinkcukacha',
    lengthWidthHeight: 'Ubude, ububanzi & ubude',
    weight: 'Ubunzima',
    emissionCO2: 'Ukukhutshwa kwe-CO2',
    fuelConsumption: 'Ukusetyenziswa kwamafutha',
    enginepower: 'Amandla enjini',
    fuelTankCapacity: 'Umthamo wetanki yamafutha',
    'performance&Economy': 'Ukusebenza & noqoqosho',
    features: 'Limbonakalo',
    'weight&Dimensions': 'Ubunzima kunye nemilinganiselo',
    showMoreFeatures: 'Bonisa iimpawu ezingakumbi',
    showLess: 'Bonisa kancinci',
    serviceHistory: 'Imbali yenkonzo',
    whyAUTO24: 'Kutheni my-AUTO24',
    serviceCenter: 'Iziko lenkonzo',
    Kilometers: 'Km',
    lookingForaCarQtellUs: 'Ukhangela imoto? Sixelele!',
    sellPageTitle: 'Thengisa imoto yakho ngamanyathelo amabini alula',
    sellPageSubtitle:
        'Unganikezela ngeenkcukacha malunga nemoto yakho kwaye siya kukufowunela ukuze ufumane ezinye izivumelwano',
    findCarPricePageTitle: 'Find The Price of Your Car',
    findCarPricePageSubtitle:
        'You can provide details about your car and we will call you back for futher deals.',
    whyFinanceYourCarwithAuto24: 'Kutheni uxhasa imoto yakho nge-AUTO24',
    financeSubtitle:
        'Sikuboonelela ngeyona nkxaso-mali ibalaseleyo enenzala ephantsi kunye neentlawulo eziphantsi',
    competitiveRates: 'Amazinga okhuphiswano',
    competitiveDesc: 'I-APR ukusuka kwi-5%(isalathiso)',
    competitiveDescMA: 'Ukubanakho kwetyala lasimahla phantsi kweemeko',
    flexiableDuration: 'Ubude bexesha eliguquguqukayo',
    flexiableDes:
        'Hlawulela imoto yakho ngezavenge zenyanga ezili-12 ukuya kweziyi-72',
    Percent100OnlineApproach: '100% indlela ye-intanethi',
    Percent100OnlineApproachDesc: 'Sayonara onke amaxwebhu akho ekhaya',
    loanCalculatorTitle: 'Imoto yokubala mboleko',
    loadCalculatorSubtitle:
        'Inzala yenyanga isenokwahluka njengoko sisebenzisana namaziko ohlukeneyo ezezimali',
    interestRate: 'Umgangatho wenzala',
    years: 'Iminyaka',
    ourFinancingPartners: 'Iqabane lethu lezemali',
    ourInsurePartners: 'Iqabane lethu kwi-inshurensi',
    ourPartners: 'Amaqabane ethu',
    copyrightfooter: 'Copyright Â© Africar Group Pty Ltd - All rights reserved',
    insureSubtitle:
        'Yonwabela eyona ipolisi ye-isnshorensi ngexabiso elincomekayo',
    aboutFinanceSub: 'Nanga amanye amaqabane ethu okuxhasa ngemali',
    selectMileageRange: 'Khetha uluhlu lweemayile',
    selectTransmissionType: 'Khetha uhlobo lothumelo',
    backToHome: 'Ndibuyele ekhaya',
    getACallBack: 'Ufumane umnxeba kwakhona',
    personalDetails: 'Iinkcukacha zakaho',
    carDetails: 'Iinkcukacha zemoto',
    ourBrandsSubtitle:
        'Sinxulunyaniswa nemoto yohlobo oluphezulu ephumelele iimbasa ezininzi ngokusebenza kakuhle',
    ourTeam: 'Amaqela ethu',
    findCarPrice: 'Isixhobo sokuthengisa',
    teamMembers: 'Abantu bethu',
    ourTeamSubtitle: 'Usazi ngcono ngeengcinga zethu',
    latestPosts: 'Izithuba zamva nje',
    lastestPostSubtitle:
        'Nazi iiblog zamva nje ezivela kubasebenzi abohlukeneyo',
    featuredPosts: 'Izithuba ezibonakalayo',
    featuredPostSubtitle:
        'Nazi iiblog eziveziweyo ezivela kubasebenzi abohlukeneyo',
    newsLetterTitle: 'Ungaze uphoswe libali',
    newsLetterSubtitle:
        'Bhalisa kwiblog ye-AUTO24 ukuze uhlale uhlaziyiwe malunga nayo yonke my into yeemoto',
    buySellTitle: 'Sele ulungele?',
    buySellSubtitle:
        "Whether it's selling your used car or buying your next ride, we can do it all.",
    sellYourCar: 'Thengisa imoto yakho',
    buyaCar: 'Thenga imoto',
    footerInfo:
        'AUTO24.ci is a subsidiary of Africar Group Pty Ltd, registered in Ivory Coast under the IDU CI-2022-0027564 O.',
    FAQ: 'FAQ',
    buyCars: 'Thenga iimoto',
    sellCars: 'Thengisa iimoto',
    categories: 'Iindidi',
    topStories: 'Amabali aphezulu',
    pleaseInputYourPassword: 'nceda ufake igama lokugqitha lakho!',
    donthaveanaccount: 'Andinayo iakhawunti',
    resetPassword: 'Phinda umisele igama lokugqitha',
    editProfile: 'Hlengahlengisa iprofayile yakho',
    createNewPassword: 'Yila igama lokugqitha elitsha',
    passwordresetsuccessfully: 'Ukuseta kwakhona igama lokugqitha ngempumelelo',
    pleaseInputYourName: 'nceda ufake igama lakho',
    pleaseInputYourEmail: 'nceda ufake idilesi ye-imeyile yakho!',
    pleaseInputValidEmail: 'nceda ufake idilesi ye-imeyile esebenzayo!',
    pleaseInputYourPhoneNumber: 'nceda ufake inombolo yefowuni yakho!',
    maximumXdigitsisallowed: 'Amanani aphezulu {{X}} avumelekileyo',
    minimumXdigit: 'Ubuncinane {{X}} bamanani avumelekileyo',
    passwordisRequired: 'Igama lokugqitha liyafuneka!',
    minimum8chars: 'Ubuncinci boonobumba abasi-8 bayafuneka',
    usersuccessfullyregistered: 'Umsebenzi ubhaliswe ngempumelelo',
    shortBio: 'I-bio emfutshane',
    Save: 'Gcina',
    doyouwantDeleteAccount: 'Ufuna ukuyicima ngokusisigxina iakhawunti yakho?',
    delete: 'Cima',
    saveChanges: 'Gcina utshintsho?',
    youcanstillchangetheseinformation: 'Usenako ukutshintsha ulwazi',
    changessavedsuccessfully: 'Utshintsho lugciniwe ngempumelelo',
    yourchangessavedsuccessfully: 'Utshintsho lwakho lugciniwe ngempumelelo',
    deleteyourAccount: 'Cima iakhawunti yakho',
    deleteAccountsubtitile:
        'Once you delete your account your all data will be lost and you cant  get access to your account.',
    accountdeletedsuccessfully: 'Iakhawunti icinywe ngempumelelo',
    youcanstillexpolrecarwithouthavinganaccount:
        'Usenakho ukuhlola iimoto ngaphandle kokuba neakhawunti',
    logout: 'Phuma',
    xInspectionPoints: '{{number}} iindawo zokuhlola',
    xlistingPageResultTitle: '{{number}} iimoto zifunyenwe',
    xlistingPageResultsTitle: '{{number}} iimoto zifunyenwe',
    sortBy: 'Misa nge:',
    filterBy: 'Hluza Nge:',
    lowestPriceFirst: 'Ixabiso eliphantsi kuqala',
    highestMileageFirst: 'Imayile ephezulu kuqala',
    lowestMileageFirst: 'Imayile isezantsi kuqala',
    price: 'Ixabiso',
    mileage: 'Imayile',
    selectBodyType: 'Khetha uhlobo lomzimba',
    filter: 'Izihluzi',
    moreFilters: 'Ezinye izihluzi',
    color: 'Umbala',
    transmission: 'Usulelo',
    highestPriceFirst: 'Ixabiso eliphezulu kuqala',
    provideUsYourDetail: 'Sinikeze iinkcukacha zakho',
    interestcartitle: 'Uzimisele ukuthenga le moto?',
    interestcarsubtitle:
        'Khetha ukhetho olunye, ezinye ziya kuqhubeka zifumaneka kwinkqubo yakho yokuthenga',
    bookThisCar: 'Bhukisha le moto',
    bookThisCarSubtitle:
        'Qhubeka uthenge le moto kwaye uyibhukishe ngoku. Qgiba ukuthenga kwakho ngeentsuku ezisi-7. 10% icandelo elibuyiswayo',
    scheduleAVisit: 'Cwangcisa utyelelo',
    scheduleAVisitSubtitle:
        'Ndwendwela ivenkile yethu ukuze ujonge imoto yakho oyifunayo. Isaza kufumaneka nakwabanye abathengi',
    popularblog: 'Iiblog ezidumileyo',
    popularblogSub:
        'Nazi iibloi ezidumileyo ezivela kubasebenzinsi abohlukeneyo',
    category: 'Udidi',
    carOverview: 'Umboniso wemoto',
    Detailssentsuccessfully: 'Iinkcukacha zithunyelwe ngempumelelo',
    DetailChangeSubtitle:
        'Enkosi ngokusinika iinkcukacha zakho. to Sizakutsalela umnxeba kungekudala',
    continue: 'Qhubeka',
    carnotfoundSubtitle:
        'Akukho moto kwiikhrayitheriya ezikhethiweyo.Sincede ngeenkcukacha zakho kwaye siya kukunceda ufumane imoto efanelekileyo!',
    logoutModelTitle: 'Uqinisekile ukuba ufuna ukuphuma?',
    french: 'IsiFrentshi',
    QuickLinks: 'Amakhonkco akhawulezayo',
    similarResults: 'Similar Results ',
    copyrightInfo:
        "AUTO24.CI is a subsidiary of Africar Group Pty Ltd, registered under the unique identification number CI-2022-0027564 O in the Republic of Cote d'Ivoire",
    recentlyAdded: 'Yongezwa mva nje',
    recentlyAddedSubtitle:
        'Apha ungabona iimoto ezahlukeneyo ezisanda kongezwa',
    newCarList: 'Iimoto ezintsha',
    newCarListSubtitle: 'Apha ungakuthatha izixhobo ezintsha ezahlukene',
    electricCarList: `Iimoto ye-Lectric`,
    electricCarListSubtitle: `Apha ungakuthatha izixhobo ezintsha zaselektirikhi ezahlukene`,
    importedCarList: `Iimoto ezibizwayo`,
    importedCarListSubtitle: `Apha ungakuthatha izixhobo ezibizwayo ezahlukene`,
    cheapestCarList: 'Iimoto ezincinci phakathi ko {{X}}',
    cheapestCarListSubtitle:
        'Apha ungakuthatha izixhobo ezinzima phantsi kwesikrini esiphezulu ka {{X}}',
    hereyoucanseedifferentsimilarcars:
        'Apha unokubona iimoto ezahlukeneyo ezifanayo',
    carNotFound: 'Imoto ayifumanekanga',
    whatsappUrlText: 'Mholo! Ndinomdla kule moto',
    latestService: 'Inkonzo yamva nje',
    resetSuccessMsg:
        'Ngoku ungangena ngephasiwedi yakho entsha okanye usenokujonga iimoto',
    bothPasswordMustMatch: 'Zombini iiphasiwedi kufuneka zihambelane',
    mustBeAtleast8Characters: 'Kufuneka ibenamagama asi-8 ubuncinane',
    sendResetLink: 'Thumela ikhonkco lokuseta kwakhona',
    returnTo: 'Buyela kwi',
    enterEmailToGetResetLinkInfo:
        'ngenisa i-imeyile enxulumene neakhawunti yakho kwaye siza kuthumela i-imeyile enomyalelo wokuseta ngokutsha igama lakho lokugqitha',
    resetPasswordModalTitle:
        'Uqinisekile ukuba ufuna ukuseta kwakhona igama lakho lokugqitha?',
    yourPasswordChangedSuccessfully:
        'Igama lokugqitha lakho litshintshwe ngempumelelo',
    confirmPassword: 'Qinisekisa iphasiwedi',
    carViewsXTimes: 'Ijongwe {{number}} amaxesha namhlanje',
    shortDescription: 'Inkcazelo emfutshane',
    carImages: 'Imifanekiso yemoto',
    uploadImage: 'Layisha imifanekiso',
    browse: 'Khangela',
    listAnother: 'Dwelisa enye',
    listCarRequestSentSucessfully:
        'Uluhlu lwesicelo semoto luthunyelwe ngempumelelo',
    basicDetails: 'Iinkcukacha ezisisiseko',
    manual: 'Incwadi yesandla',
    postYouHaveLiked: 'Ipowusti oyithandileyo',
    enterOldPassword: 'ngenisa igama lokugqitha elidala',
    enterNewPassword: 'Iphasiwedi entsha',
    confirmNewPassword: 'Qinisekisa igama lokugqitha elitsha',
    passwordChangeSuccessfully: 'Igama lokugqitha litshintshwe ngempumelelo',
    verificationTitle:
        'Sikuthumelele ikhonkco lokuqinisekisa kwidilesi yakho ye-imeyile',
    viewLink: 'Jonga ikhonkco',
    resendingLink: 'Thumela kwakhona ikhonkco',
    tokenExpired: 'Uphawu lwakho luphelelwe nceda wenze entsha',
    tokenExpiredTitle: 'Ikhonkco lokuqinisekisa iakhawunti liphelelwe lixesha',
    callBackSubtitle:
        "Enter your name and phone number we'll get back to you soon",
    provideUsYourDetails: 'Sinike iinkcukacha zakho',
    showCars: 'Bonisa iimoto',
    accountVerifiedSuccessfully: 'Iakhawunti iqinisekiswe ngempumelelo',
    accountVerifiedSuccessfullySubtitle:
        'Iakhawunti yakho iqinisekiswe ngempumelelo. Uyafuna ukungena?',
    engineSound: 'Isandi senjini',
    showAllImage: 'Bonisa yonke imifanekiso',
    ourBenefits: 'Iinzuzo zethu',
    inspectionPoints: 'I-100 lamanqaku okuhlolwa ziingcali zethu zeemoto',
    warranties:
        'Iwaranti yeenyanga ezi-6 (injini,ibhokisi yegiya kunye ne-asi)',
    maintenances: '1 year maintenance package with AUTO24.co.za',
    assistance: 'Iinyanga ezili-12 zokutsalwa kunye noncedo lokuqhekeza',
    multiCurrency: 'Multi-currency payment DH, €, $, £',
    moneyBack: '5 iintsuku ukubuyisela imali isiqinisekiso',
    financing: 'Financing ',
    dragAndDrop: 'Tsala & ulahle umfanekiso okanye',
    imageFormat: 'Iifomati ezixhaswayo: JPEG,PNG',
    sellNow: 'Thengisa ngoku',
    confirm: 'Qinisekisa',
    didntFindQuestion: "Didn't find the question you're looking for?",
    yourMessage: 'Umyalezo wakho',
    sendMessage: 'Thumela umyalezo',
    loanTenure: 'Ixesha lokuboleka imali',
    selectLoanTenure: 'Select Loan Tenure',
    interestInput: 'Ngenisa imali phakathi kuka {{X}} kunye no {{Y}}',
    months: 'Iinyanga',
    from: 'Ukusuka',
    upto: 'Ixhomekeke ku',
    rateOfInterest: 'Izinga lenzala',
    tenure: 'Ixesha lokuhlala',
    monthlyEmi: 'EMI ngenyanga',
    loadMore: 'Gcwalisa okuninzi',
    contactSubtitle:
        'Unayo nayiphi na imibuzo? Ukhululekile ukusithumela umyalezo',
    inspector: 'Umhloli',
    inspectorSubtitle: 'Cela umhloli',
    insurance: 'I-inshurensi',
    insuranceSubtitle: 'Cela i-inshurensi engcono',
    financeSubtitles: 'Cela eyona mali engcono',
    diagnostic: 'Uxilongo',
    minPrice: 'Ixabiso elincinci',
    maxPrice: 'Ixabiso eliphezulu',
    minDistance: 'Umgama omncinci',
    maxDistance: 'Umgama omde',
    min: 'Min',
    max: 'Max',
    diagonosticSubtitle: 'Cela ukuxilongwa',
    VINVerify: 'VIN qiniseka',
    VINVerifySubtitle: 'Cela uqinisekiso lweVIN olukhawulezayo',
    learnMore: 'Funda nzulu',
    speedRegular: 'Umlawuli wesantya',
    airCondition: 'Imeko yomoya',
    sequential: 'Ngokulandelelana',
    CDPlayer: 'Umdlali we-CD',
    reversingCamera: 'Ikhamera ebuyela umva',
    reversingRadar: 'Ukubuyisela umva irada',
    GPSNavigation: 'GPS yokukhangela',
    parkAssist: 'Uncedo lokupaka',
    startAndStop: 'Qala kwaye uyeke',
    airbag: 'Ingxowa yomoya',
    ABS: 'ABS',
    computer: 'Ikhompyutha',
    rims: 'Iirim',
    sunroof: 'Uphahla lwelanga',
    electricMirrors: 'Izibuko zombane',
    electricWindows: 'Iifestile zombane',
    dateFirstUse: 'Umhla wokuqala ukusetyenziswa',
    seats: 'Izihlalo',
    certifiedUsedCars: 'Iimoto ezisetyenzisiweyo eziqinisekisiweyo',
    certifiedUsedCarsSubtitle:
        'Thenga iimoto esele esele zisetyenzisiwe ngokuthenjwa nangokucacileyo',
    inpectionsPoints: 'I-100 lamanqaku okuhlola',
    inpectionsPointsSubtitle: 'Zonke iimoto zethu ziye zanjongwa ngocoselelo',
    refundPolicy: 'Ipolisi yembuyezeko yeentsuku ezi-5',
    refundPolicySubtitle: 'Awuthandi, phinda ubuyiselwe!',
    warrentySubtitles:
        'Injini, ibhokisi yegiya kunye ne powertrain, iinyanga ezi-6 ziqinisekisiwe',
    excellentCondition: 'Imeko entle',
    goodCondition: 'Imeko entle',
    averageCondition: 'Imeko ephakathi',
    damagedCar: 'Imoto eyonakele',
    lessThan10: 'Ngaphantsi kwe-10 00 km',
    betweenXAndY: 'Phakathi kwe {{X}} kunye ne {{Y}} km',
    moreThan100: 'Ngaphezwu kwe-10 000 km',
    serviceTitle: 'Ukuthenga okanye ukuthengisa? Singakunceda',
    serviceSubtitle:
        'Explore our services and make your car buying / selling experience seamless ',
    verifyAccount: 'Qinisekisa iakhawunti',
    verifyNumber: 'Qinisekisa inombolo',
    otpVerificationSubtitle:
        'Ngenisa ikhowudi enamanani ama-5 athunyelwe ku {{code}} {{number}}',
    otpVerificationEmail:
        'Ngenisa ikhowudi enamanani ama-5 athunyelwe ku {{email}}',
    didntGetTheCode: "Didn't get the code?",
    resendCode: 'Thumela kwakhona ikhowudi',
    bluetooth: 'Ibluetooth',
    home: 'Ekhaya',
    lookingForBestInsurance: 'Ujonge eyona inshorense ingcono?',
    ourCustomerRepresentativesWillGetBackInTouchWithYou:
        'Abameli bethu abathengi baya kubuyela ukunxibelelana nawe',
    upload: 'Layisha',
    profileUpload: 'Layisha umfanekiso omtsha',
    remove: 'Susa',
    xIsrequired: '{{name}} liyafuneka',
    number: 'Inani',
    backToCarDetail: 'Buyela kwiphepha leenkcukacha',
    backToTheCarDescription: 'Buyela kwinkcazo yemoto',
    backToTheCarListing: 'Buyela kuluhlu lweemoto',
    phoneVerifiedSuccesfully: 'Ifowuni iqinisekiswe ngempumelelo',
    phoneVerifiedSubtitle:
        'Inombolo yefowuni yakho iqinisekiswe ngempumelelo. Uyafuna ukungena?',
    signWithPhone: 'Ngena ngefowuni',
    signWithEmail: 'Ngena nge-imeyile',
    newPriceFrom: 'Ixabiso lemoto entsha ukusuka',
    theySpeakAboutUs: 'Bathetha ngathi',
    theySpeakAboutUsSubTitle:
        'Ukhetho lweenkokheli zeendaba zamazwe ezithetha nge-AUTO24',
    carLoanSubTitle: 'Imbuyekezo yenyanga eqikelelwayo',
    carLoanTotalRepayment: 'Imali yembuyekezo iyonke',
    forgotTokenExpired:
        'Ikhonkco lokusetha ngokutsha igama lokugqitha liphelelwe lixesha',
    discover: 'Fumana imoto',
    paperWork: 'Amaphepha ezithuthi ahlaziyiwe kwaye aqinisekisiwe',
    verifyhistory: 'Siqinisekisa imbali yazo zonke izithuthi esizithengayo',
    buyBack: 'Sithenga imoto yakho endala',
    additionalPhotos: 'Imifanekiso eyongezelelweyo',
    technicalVisit: 'Isatifikhethi sotyelelo lobugcina',
    keysPhotos: 'Umfanekiso we zitshixo',
    seeKeyPhotos: 'Bona umfanekiso',
    pleaseTryAfterSomeTime: 'Ndicela uzame emva kwexesha',
    favourites: 'Ezithandwayo',
    electric: 'Yombane',
    autonomy: 'Ukuzimela',
    productionYear: 'Unyaka wemveliso',
    payWithOtherCurrencytitle: 'Hlawula ngenye imali nge-AUTO24',
    payWithOtherCurrencySubtitle:
        'Nge-AUTO24,ungabhatala kwenye yee-akhawunti zethu zebhanki zamazwe ngamazwe kuyo nayiphi na imali kwezi zingezantsi. Nali ixabiso onokuthi ulitshintshe',
    convertedPrice: 'Ixabiso eliguquliweyo',
    favouritesNotFoundSubtitle:
        "You haven't added any cars to favourites. Explore cars and add your favourites",
    noFavouritesFound: 'Akukho zithandwayo zifumanenyweyo',
    frontCrankOilSeal: 'Itywina lweoli ye crank yangaphambili',
    engineOverHaul: 'Ukulungiswa kwenjini',
    rearCrankOilSeal: 'Itywina lweoli ye crank yasemva',
    engineTuneUp: 'Lungisa injini',
    engineMount: 'I-mount ye injini',
    oilPanGasket: 'I-oyile pan gasket',
    valveCoverGasket: 'Isiciko sevalve gasket',
    oilFilterBracketOring: 'Isibiyeli se-oyile yokucoca i-oring',
    intakeManifoldGasket: 'I-Intake manifold gasket',
    waterLeak: 'Ukuvuza kwamanzi',
    radiator: 'Irediyetha',
    engineTimingCoverGasket: 'I-gasket yokulinganisa ixesha le injini',
    waterPump: 'Impopo yamanzi',
    belts: 'Amabhanti',
    fuelFilter: 'Isihluzo zamafutha',
    airFilter: 'Isihluzo somoya',
    underChassis: 'Phantsi kwe-chassis',
    tieRod: 'Intonga yokubopha',
    ballJointLandR: 'Ibhola edibeneyo L&R',
    idlerArm: 'Ingalo engasebenziyo',
    pitmanArm: 'Ingalo ye-pitman',
    steeringPump: 'Impopo yokuqhuba',
    steeringHoses: 'Imibhobho yokuqhuba',
    steeringBox: 'Ibhokisi yokulawula',
    steeringRackAndPinion: 'Indawo yokubeka kunye nepinion',
    frontShockLandR: 'Umothuko wangaphambili L&R',
    rearShockLandR: 'Umothuko ongasemva L&R',
    stabilizerLinkage: 'Unxibelelwano lokuzinzisa',
    crossJointPropellerShaft: 'Umnqamlezo we-propeller shaft',
    upperArmBushing: 'Ukubetha kwengalo ephezulu',
    rearLowerArmBushing: 'Umva wengalo esezantsi',
    suspensionSpring: 'Ukumiswa kwentwasahlobo',
    breakSystem: 'Inkqubo yokuqhawula',
    brakePad: 'Iphedi yebrake',
    brakeDiscRotor: 'Ibrake disc rotor',
    wheelBearing: 'Ukuthwala ivili',
    brakeMasterCLY: 'Ibrake master CLY',
    backBooster: 'I-booster yangasemva',
    brakeWheelCall: 'Ukufowuna kwevili lokuqhekeza',
    handBrakeCable: 'I-Hand brak',
    brakeHoses: 'Intambo yehand brake',
    rearAxlesAndDriveShafts: 'Iiasi ngasemva & iishafti zokuqhuba',
    frontAxle: 'I_asi yangaphambili',
    rearAxle: 'Iasi yangasemva',
    axleDifference: 'Umahluko weasi',
    differenceFrontOrRear: 'Umahluko ngaphambili okanye ngasemva',
    axleSealFrontOrRear: 'I-axle yokutywina ngaphambili okanye ngasemva',
    oilPumpGasket: 'Impopo ye-oyile gasket',
    frontOilSeal: 'Isitywina se-oyile yangaphambili',
    rearOilSeal: 'Isitywina se-oyile yangasemva',
    transmissionMount: 'Intaba yothumelo',
    gearStickSouta: 'Intonga yegiya souta',
    oilCollerPipe: 'Umbhobho opholileyo we-oyile',
    gearPerformance: 'Ukusebenza kwesixhobo',
    liquidSystem: 'Inkqubo yolwelo',
    engineOil: 'I-oyile ye-injini',
    transmissionOil: 'ioli yokuhambisa',
    brakeFluid: 'Ibrake fluid',
    engineCoolant: 'Into yokupholisa injini',
    washerFluid: 'Ulwelo lwewasha',
    electricitySystem: 'Inkqubo yombane',
    battery: 'Ibhetri',
    alternator: 'Ialternator',
    starler: 'Inkwenkwezi',
    lights: 'Izibane',
    ac: 'A/C',
    centerLock: 'Isitshixo esiphakathi',
    wiperBlades: 'Iincaikuba zosula',
    troubleCode: 'Ikhowudi ingxaki',
    computerCods: 'Iikhowudi zekhompyutha',
    acCondensor: 'I-condenser yeA/C',
    acRadiator: 'Irediyetha yeA/C',
    interior: 'Ngaphakathi',
    seatAndBelts: 'Izihlalo kunye namabhanti',
    interiorControlsAndSwitches: 'Ulawulo lwangaphakathi kunye nokutshintsha',
    sunRoofAndWindows: 'Uphahla lwelanga kunye neefestile',
    fuelAndTemperatureGauge: 'Isibaso kunye negeyiji yobushushu',
    dashboardAndGauges: 'Ideshibhodi kunye neegeyiji',
    radioOrMusicSystem: 'Irediyo okanye inkqubo yomculo',
    sRSAirbags: 'Iingxowa zomoya ze-SRS',
    steeringWheelTiltOrlock: 'Ivili lokuqhuba lithambekele okanye litshixe',
    mirrors: 'Izibuko',
    bodyInspection: 'Ukuhlolwa komzimba',
    inspectionDate: 'Umhla wokuhlolwa',
    carInspectionReport: 'Ingxelo yokuhlowa kwemoto',
    showPicture: 'Bonisa umfanekiso',
    pageNotFound: 'Iphela alifumaneki',
    pageRemoved: "This page doesn't exist or was removed!",
    askQuestionOnWhatsapp: 'Imibuzo?Sibuze kuWhatsapp!',
    whatsappsPlaceholder: 'Buza nantoni na!',
    customerSupport: 'Uxhaso lwabathengi',
    needHelpChatWithUs: 'Dinga uncedo? Ncokola nathi kuWhatsapp',
    instaTitle: 'Silandele ku-instagram',
    instaSubTitle:
        'Fumana uhlayizo ngonikezelo lwethu lamva nje,iimveliso kunye neendaba ngoko nangoko!',
    questionSentSuccessfully: 'Umbuzo uthunyelwe ngempumelel',
    questionSentSuccessfullySubtitle:
        'Umbuzo wakho uthunyelwe ngempumelel. Siza kubuyela kuwe ngempendulo yakho',
    unSubscribeMessage: 'Ukhutshiwe ngempumelelo kwiiblog ze-Auto24',
    numberMustBeUnique: 'Inani kufuneka ibe lodwa',
    pleaseEnterFullName: 'nceda ngenisa igama elipheleleyo',
    saving: 'Ukugcina',
    sending: 'Ukuthumela',
    signingIn: 'Ukungena',
    signingUp: 'Ukubhalisa',
    loggingout: 'Ukuphuma',
    deleting: 'Ukucima',
    querySubmittedSuccessfully: 'Umbuzo ungeniswe ngempumelelo',
    somethingWentWrong: 'Into ayihambanga kakuhle!',
    removedFromWishlist: 'Isusiwe kuluhlu lweminqweno!!',
    addedToWishlist: 'Yongezwe kuluhlu lweminqweno',
    subscribedSuccessfully: 'Ubhalise ngempumelelo',
    alreadySubscribed: 'Sele ubhalisile',
    messageIsRequired: 'Umyalezo uyafuneka',
    fullName: 'Igama eliphelele',
    phoneNumberVerifiedSuccess:
        'Inombolo yefowuni iqinisekiswe ngempumelelo. Nceda ungene',
    verifyEmail:
        'Nceda uqinisekise i-imeyile ye AUTO24 yokuqinisekisa ikhonkco itjunyelwe kwi-imeyile yakho',
    sold: 'Ithengisiwe',
    new: 'Entsha',
    used: 'Sebenzisiweyo',
    popular: 'Idumile',
    makes: 'Iimveliso',
    carTypes: 'Iintlobo zezimoto',
    explore: 'Phonononga',
    followUS: 'Landela thina',
    none: 'Akukho nanye',
    all: 'Konke',
    discount: 'Discount',
    careers: 'Imisebenzi',
    year: 'Year {{X}} and above',
    discountOff: '{{X}} Remise',
    financeYourCarWith: 'Finance Your Car With',
    creditButton: 'CREDIT GRATUIT',
    loanPaymentSimulator: 'Isibali-mali semali-mboleko yemoto',
    loanPaymentContent:
        'Siyakwenza kube lula ukuba ube nemoto oyifunayo. Masikuncede ufumane imali-mboleko, ukusuka ekungeniseni amaxwebhu ukuya ekufumaneni imvume yebhanki ngokukhawuleza.',
    vehiclePrice: 'Vehicle price',
    noOfMonthlyPayments: 'Number of monthly payments',
    monthlyPaymentAmount: 'Monthly payment amount',
    creditRequestedAmount: 'Credit Requested Amount',
    overallCostOfCredit: 'Ityala elipheleleyo',
    simulatorContribution: 'Simulator contribution',
    percentageOfContribution: 'Percentage of contribution',
    applicationFees: 'Application fees',
    monthlyInsurance: 'Monthly insurance',
    noOfOwners: 'Number Of Owners',
    condition: 'Condition',
    maisonMere: 'Maison Mere',
    imported: 'Imported',
    tamperedOdometer: 'Tampered Odometer',
    carIssue: 'Car Issues',
    evaluateAgain: 'Evaluate Again',
    marketValue: 'Market Value',
    downloadAuto24App: `Thwebula i-App ye-Auto24`,
    browseMore: `Qhagamshelane, thenga, uthengise ngeqiniso - unqubomgomo wakho wezimoto ovuthayo`,
    saveTimeAndEffort: 'Hlaziya Isikhathi Nokusebenza',
    saveTimeAndEffortSubtitle:
        'Makabongwe ukuba sikhuphukela isikhathi esibalulekile ngokuthi sisebenzise konke, sikwazi ukuthengisa ngokuzenzakalelayo futhi ngokululayo',
    freeNoCost: 'Mahhala Ngokuphelele',
    freeNoCostSubtitle: `Thuma isixeko, i-photography, imali yokufaka isiqinisekiso, kwaye izigqibo kwethu - sizokuthenga`,
    competetivePrice: 'Inani Ebalulekileyo',
    competetivePriceSubtitle:
        'Thola isilinganiso esiphakathi ngaphandle kokunxibelela nathi; sisebenzisa konke kwiqela kanye nezicelo zebhokhasi',
    carTitle: `Izaziso Zemoto`,
    carDesc: `Nceda usiphumelele izaziso mayelana nombane wakho`,
    personalDetail: `Izaziso Zezibini`,
    personalDetailDesc: `Nceda usiphumelele izaziso mayelana nawe`,
    sellCarHere: `Thengisa Imoto`,
    sellCarHereDesc: `Ungathenga imoto ethile kwaye siyokubhala`,
    buySellUsedCar: `Thenga kwaye uthengise imoto ezisebenzile kw`,
    auto24Press: `i-Auto24 i-Press`,
    auto24PressDesc1: `I-Auto24 ithunyelwe kwizixhobo ezahlukahlukene ezizidlulayo ngaphakathi kwelizwe.`,
    auto24PressDesc2: `I-Auto24 yathunyelwe kwiikhonferensazi eParis/France, Lisbon/Portugal, Barcelona/Spain, Sydney/Australia, London/UK, Kigali/Rwanda, Bangkok/Thailand, Abidjan/Ivory Coast, Johannesburg/South Africa, Dakar/Senegal, Nairobi/Kenya, Casablanca/Morocco…`,
    mediaExposure: `Ukuboniswa kwethu kwi-Media`,
    mediaExposureDesc: `Naphaya kwakho kufuneka kukhethwe ukuboniswa kwethu kwi-Media`,
    press: `Iqonga`,
    enterValidPhoneNumber: 'Fumana inombolo yocingo evumelekile',
    sellCar: 'Thengisa imoto',
    findUsGoogle: 'Sifumane kuGoogle Map',
    locateUs: 'Sifumane',
    whereFindUs: 'Apho ungasifumana khona?',
    aroundLocationText: 'Ungasifumana kwezi ndawo',
    facebook: 'Facebook',
    instagram: 'Instagram',
    tiktok: 'Tiktok',
    twitter: 'Twitter',
    youtube: 'Youtube',
    linkedin: 'LinkedIn',
    carsUnderPriceDescription:
        'Auto24 yindawo yakho efanelekileyo yokuphonononga iindidi ngeendidi zeempawu zeemoto ezivela kwihlabathi liphela. Nokuba ufuna imoto encinci yesixeko, i-SUV efanelekileyo kusapho, okanye i-sedan enobunewunewu, iqonga lethu likudibanisa neenketho ezihambelana nazo zonke iimfuno kunye nezinto ozikhethayo. Yonke imveliso yemoto eboniswe kwi-Auto24 yaziwa ngobuchule bayo, itekhnoloji yayo, kunye nokuthembeka kwayo, ikunika uxolo lwengqondo xa ukhangele imoto efanelekileyo.'
};

export default commonXhosa;
