import { COUNTRY_TOP_LEVEL_DOMAIN } from '../../constants';

const getMetaFrench = (topLevelDomain: string) => {
    switch (topLevelDomain) {
        case COUNTRY_TOP_LEVEL_DOMAIN.bj:
            return {
                homePageTitle:
                    "AUTO24.bj - Voitures d'Occasion Certifiées à Benin | Achetez et Vendez Localement",
                homePageDesc:
                    "À la recherche d'une voiture d'occasion certifiée à Benin? AUTO24.bj est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Vendre Voiture Benin, Marché Automobile, AUTO24 Benin, Voitures de Qualité, Vente Auto Benin",
                homePageTitleOg:
                    "AUTO24.bj - Voitures d'Occasion Certifiées à Benin | Achetez et Vendez Localement",
                homePageDescOg:
                    "À la recherche d'une voiture d'occasion certifiée à Benin? AUTO24.bj est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Vendre Voiture Benin, Marché Automobile, AUTO24 Benin, Voitures de Qualité, Vente Auto Benin",
                buyPageTitle:
                    "Voitures à vendre au Benin | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.bj",
                buyPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées {{ BRAND }} sur AUTO24.bj . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Benin.",
                buyPageKeywords:
                    "Acheter Voitures Benin, Voitures d'Occasion Certifiées, AUTO24 Benin, Shopping de Voitures, Voitures Abordables Benin",
                buyPageTitleOg:
                    "Voitures à vendre au Benin | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.bj",
                buyPageDescOg:
                    "Parcourez une large sélection de voitures d'occasion certifiées sur AUTO24.bj . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Benin.",
                buyPageKeywordsOg:
                    "Acheter Voitures Benin, Voitures d'Occasion Certifiées, AUTO24 Benin, Shopping de Voitures, Voitures Abordables Benin",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.bj",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.bj ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywords:
                    "Vendre Voiture Benin, Vente de Voitures d'Occasion Benin, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Benin",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.bj",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.bj ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywordsOg:
                    "Vendre Voiture Benin, Vente de Voitures d'Occasion Benin, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Benin",
                aboutPageTitle:
                    "À Propos de  AUTO24.bj - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Benin",
                aboutPageDesc:
                    "Découvrez AUTO24.bj , le leader en matière de voitures d'occasion certifiées à Benin. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywords:
                    "À Propos de AUTO24 Benin, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Benin",
                aboutPageTitleOg:
                    "À Propos de  AUTO24.bj - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Benin",
                aboutPageDescOg:
                    "Découvrez AUTO24.bj , le leader en matière de voitures d'occasion certifiées à Benin. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywordsOg:
                    "À Propos de AUTO24 Benin, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Benin",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.bj',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.bj - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    'Financement de Voiture, Auto Crédit, AUTO24 Benin, Options de Financement, Prêt Auto',
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.bj',
                financePageDescOg:
                    "Explorez un financement automobile sans tracas avec Auto24.bj - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywordsOg:
                    'Financement de Voiture, Auto Crédit, AUTO24 Benin, Options de Financement, Prêt Auto',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.bj',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.bj. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Benin, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.bj',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.bj. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Benin, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.bj",
                blogPageDesc:
                    "Visitez Auto24.bj pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywords:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Benin, Astuces Voiture',
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.bj",
                blogPageDescOg:
                    "Visitez Auto24.bj pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywordsOg:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Benin, Astuces Voiture',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24, Benin',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Benin en Benin. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre au Bénin | Obtenez le meilleur prix sur AUTO24 Bénin',
                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre au Bénin | Obtenez le meilleur prix sur AUTO24 Bénin',
                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} au Bénin | Obtenez le meilleur prix sur AUTO24 Bénin',
                carsUnderPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.bj. Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie au Bénin."
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.ma:
            return {
                homePageTitle:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDesc:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                homePageTitleOg:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDescOg:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                buyPageTitle:
                    "Voitures à vendre au Maroc | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ma",
                buyPageDesc:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywords:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                buyPageTitleOg:
                    "Voitures à vendre au Maroc | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ma",
                buyPageDescOg:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywordsOg:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ma",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.ma! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywords:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ma",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.ma! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywordsOg:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                aboutPageTitle:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDesc:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywords:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                aboutPageTitleOg:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDescOg:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywordsOg:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ma',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.ma - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ma',
                financePageDescOg:
                    "Découvrez des options de financement flexibles pour votre voiture d'occasion avec AUTO24.ma . Simplifiez l'achat de voitures dans tout le Maroc.",
                financePageKeywordsOg:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ma',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ma. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ma',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ma. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ma",
                blogPageDesc:
                    "Visitez Auto24.ma pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywords:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ma",
                blogPageDescOg:
                    "Visitez Auto24.ma pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywordsOg:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} DH avec AUTO24, Maroc',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Maroc. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',

                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',

                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',
                carsUnderPageDesc:
                    "Découvrez une large sélection de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.ma. Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc."
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.ci:
            return {
                homePageTitle:
                    "AUTO24.ci - Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire | Achetez et Vendez Localement",
                homePageDesc:
                    "À la recherche d'une voiture d'occasion certifiée à Abidjan, Côte d'Ivoire? AUTO24.ci est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Abidjan, Vendre Voiture Côte d'Ivoire, Marché Automobile, AUTO24 Côte d'Ivoire, Voitures de Qualité, Vente Auto Côte d'Ivoire",
                homePageTitleOg:
                    "AUTO24.ci - Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire | Achetez et Vendez Localement",
                homePageDescOg:
                    "À la recherche d'une voiture d'occasion certifiée à Abidjan, Côte d'Ivoire? AUTO24.ci est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Abidjan, Vendre Voiture Côte d'Ivoire, Marché Automobile, AUTO24 Côte d'Ivoire, Voitures de Qualité, Vente Auto Côte d'Ivoire",
                buyPageTitle:
                    "Voitures à vendre au Côte d'Ivoire | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ci",
                buyPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées sur AUTO24.ci . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Côte d'Ivoire.",
                buyPageKeywords:
                    "Acheter Voitures Abidjan, Voitures d'Occasion Certifiées, AUTO24 Côte d'Ivoire, Shopping de Voitures, Voitures Abordables Côte d'Ivoire",
                buyPageTitleOg:
                    "Voitures à vendre au Côte d'Ivoire | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ci",
                buyPageDescOg:
                    "Parcourez une large sélection de voitures d'occasion certifiées sur AUTO24.ci . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Côte d'Ivoire.",
                buyPageKeywordsOg:
                    "Acheter Voitures Abidjan, Voitures d'Occasion Certifiées, AUTO24 Côte d'Ivoire, Shopping de Voitures, Voitures Abordables Côte d'Ivoire",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ci",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.ci ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywords:
                    "Vendre Voiture Abidjan, Vente de Voitures d'Occasion Côte d'Ivoire, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Côte d'Ivoire",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ci",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.ci ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywordsOg:
                    "Vendre Voiture Abidjan, Vente de Voitures d'Occasion Côte d'Ivoire, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Côte d'Ivoire",
                aboutPageTitle:
                    "À Propos de  AUTO24.ci - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire",
                aboutPageDesc:
                    "Découvrez AUTO24.ci , le leader en matière de voitures d'occasion certifiées à Abidjan, Côte d'Ivoire. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywords:
                    "À Propos de AUTO24 Côte d'Ivoire, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Abidjan",
                aboutPageTitleOg:
                    "À Propos de  AUTO24.ci - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire",
                aboutPageDescOg:
                    "Découvrez AUTO24.ci , le leader en matière de voitures d'occasion certifiées à Abidjan, Côte d'Ivoire. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywordsOg:
                    "À Propos de AUTO24 Côte d'Ivoire, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Abidjan",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ci',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.ci - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    "Financement de Voiture, Auto Crédit, AUTO24 Côte d'Ivoire, Options de Financement, Prêt Auto",
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ci',
                financePageDescOg:
                    "Explorez un financement automobile sans tracas avec Auto24.ci - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywordsOg:
                    "Financement de Voiture, Auto Crédit, AUTO24 Côte d'Ivoire, Options de Financement, Prêt Auto",
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ci',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ci. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Côte d'Ivoire, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ci',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ci. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Côte d'Ivoire, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ci",
                blogPageDesc:
                    "Visitez Auto24.ci pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywords:
                    "Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Côte d'Ivoire, Astuces Voiture",
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ci",
                blogPageDescOg:
                    "Visitez Auto24.ci pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywordsOg:
                    "Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Côte d'Ivoire, Astuces Voiture",
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24, Abidjan, Côte d’Ivoire',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Abidjan en Côte d’Ivoire. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    "Voitures {{ BRAND }} à vendre en Côte d'Ivoire | Obtenez le meilleur prix sur AUTO24 Côte d'Ivoire",

                bodyTypePageTitle:
                    "Voitures {{ BODY_TYPE }} à vendre en Côte d'Ivoire | Obtenez le meilleur prix sur AUTO24 Côte d'Ivoire",

                carsUnderPageTitle:
                    "Achetez des voitures à moins de {{ PRICE }} en Côte d'Ivoire | Obtenez le meilleur prix sur AUTO24 Côte d'Ivoire",
                carsUnderPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.ci. Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Côte d'Ivoire."
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.rw:
            return {
                homePageTitle:
                    "AUTO24.rw - Voitures d'occasion certifiées à Kigali, Rwanda | Achetez et vendez localement",
                homePageDesc:
                    "Votre marché local à Kigali pour l'achat et la vente de voitures d'occasion certifiées. Profitez d'évaluations gratuites, de transactions rapides, et de voitures de qualité.",
                homePageKeywords:
                    "Voitures d'occasion certifiées, Acheter des voitures à Kigali, Vendre des voitures au Rwanda, AUTO24 Rwanda, Voitures de qualité à Kigali, Marché automobile au Rwanda",
                homePageTitleOg:
                    "AUTO24.rw - Voitures d'occasion certifiées à Kigali, Rwanda | Achetez et vendez localement",
                homePageDescOg:
                    "Votre marché local à Kigali pour l'achat et la vente de voitures d'occasion certifiées. Profitez d'évaluations gratuites, de transactions rapides, et de voitures de qualité.",
                homePageKeywordsOg:
                    "Voitures d'occasion certifiées, Acheter des voitures à Kigali, Vendre des voitures au Rwanda, AUTO24 Rwanda, Voitures de qualité à Kigali, Marché automobile au Rwanda",
                buyPageTitle:
                    "Voitures à vendre au Kigali | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.rw",
                buyPageDesc:
                    "Parcourez une large gamme de voitures d'occasion certifiées sur AUTO24.rw . Simplifiez votre recherche pour la voiture idéale à Kigali.",
                buyPageKeywords:
                    "Acheter des voitures au Rwanda, Voitures d'occasion certifiées, AUTO24 Rwanda, Shopping de voiture, Voitures abordables à Kigali",
                buyPageTitleOg:
                    "Voitures à vendre au Kigali | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.rw",
                buyPageDescOg:
                    "Parcourez une large gamme de voitures d'occasion certifiées sur AUTO24.rw . Simplifiez votre recherche pour la voiture idéale à Kigali.",
                buyPageKeywordsOg:
                    "Acheter des voitures au Rwanda, Voitures d'occasion certifiées, AUTO24 Rwanda, Shopping de voiture, Voitures abordables à Kigali",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.rw",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.rw ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywords:
                    "Vendre une voiture au Rwanda, Ventes de voitures d'occasion à Kigali, Ventes de voitures rapides, AUTO24 Vendez votre voiture, Évaluation de voiture à Kigali",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.rw",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.rw ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywordsOg:
                    "Vendre une voiture au Rwanda, Ventes de voitures d'occasion à Kigali, Ventes de voitures rapides, AUTO24 Vendez votre voiture, Évaluation de voiture à Kigali",
                aboutPageTitle:
                    "À propos d'AUTO24.rw - Votre marché de voitures d'occasion de confiance à Kigali",
                aboutPageDesc:
                    "Découvrez AUTO24.rw , le principal marché de voitures d'occasion certifiées à Kigali. Nous offrons des voitures de qualité et une expérience d'achat et de vente transparente.",
                aboutPageKeywords:
                    "À propos d'AUTO24 Rwanda, Marché de voitures d'occasion certifiées, Ventes de voitures de confiance, Concessionnaire au Rwanda",
                aboutPageTitleOg:
                    "À propos d'AUTO24.rw - Votre marché de voitures d'occasion de confiance à Kigali",
                aboutPageDescOg:
                    "Découvrez AUTO24.rw , le principal marché de voitures d'occasion certifiées à Kigali. Nous offrons des voitures de qualité et une expérience d'achat et de vente transparente.",
                aboutPageKeywordsOg:
                    "À propos d'AUTO24 Rwanda, Marché de voitures d'occasion certifiées, Ventes de voitures de confiance, Concessionnaire au Rwanda",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.rw',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.rw - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    'Financement de voiture au Rwanda, AUTO24 Financement, Prêts de voiture abordables, Financement de voiture à Kigali',
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.rw',
                financePageDescOg:
                    "Explorez un financement automobile sans tracas avec Auto24.rw - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywordsOg:
                    'Financement de voiture au Rwanda, AUTO24 Financement, Prêts de voiture abordables, Financement de voiture à Kigali',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.rw',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.rw. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    'Assurance voiture au Rwanda, AUTO24 Assurance, Assurance véhicule à Kigali, Assurance abordable',
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.rw',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.rw. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    'Assurance voiture au Rwanda, AUTO24 Assurance, Assurance véhicule à Kigali, Assurance abordable',
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.rw",
                blogPageDesc:
                    "Visitez Auto24.rw pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywords:
                    "Blog d'AUTO24, Conseils de voiture au Rwanda, Actualités automobile au Rwanda, Perspectives sur les voitures d'occasion, Marché de la voiture au Rwanda",
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.rw",
                blogPageDescOg:
                    "Visitez Auto24.rw pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywordsOg:
                    "Blog d'AUTO24, Conseils de voiture au Rwanda, Actualités automobile au Rwanda, Perspectives sur les voitures d'occasion, Marché de la voiture au Rwanda",
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24,Rwanda',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Rwanda. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre au Rwanda | Obtenez le meilleur prix sur AUTO24 Rwanda',

                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre au Rwanda | Obtenez le meilleur prix sur AUTO24 Rwanda',

                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} au Rwanda | Obtenez le meilleur prix sur AUTO24 Rwanda',
                carsUnderPageDesc:
                    "Parcourez une large gamme de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.rw. Simplifiez votre recherche pour la voiture idéale à Kigali."
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.sn:
            return {
                homePageTitle:
                    "Voitures d'Occasion Certifiées à Dakar, Sénégal | Achetez et Vendez Localement",
                // "Voitures d'occasion certifiées en Senegal avec AUTO24",
                homePageDesc: `Vous cherchez une voiture d'occasion certifiée à Dakar, Sénégal? AUTO24.sn est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Nous offrons des évaluations gratuites, des ventes rapides et une certification complète.`,
                // 'Le prix de l’occasion avec le service du neuf ! Achetez des voitures d’occasion reconditionnées et certifiées par nos experts avec assurance et garantie.',
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.sn",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.ma ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                buyPageTitle:
                    "Voitures à vendre au Senegal | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.sn",
                buyPageDesc:
                    'Les meilleurs prix de voitures d’occasion à Senegal avec 100 points d’inspection, 5 jours satisfait ou remboursé, 6 mois de garantie et plus.',
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.sn',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.sn - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                aboutPageTitle:
                    'AUTO24, une nouvelle façon d’acheter et vendre des voitures en Afrique',
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.sn",
                blogPageDesc:
                    "Visitez Auto24.sn pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.sn',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.sn. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24, Senegal',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Senegal. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre au Sénégal | Obtenez le meilleur prix sur AUTO24 Sénégal',

                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre au Sénégal | Obtenez le meilleur prix sur AUTO24 Sénégal',

                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} au Sénégal | Obtenez le meilleur prix sur AUTO24 Sénégal',
                carsUnderPageDesc:
                    'Les meilleurs prix de voitures d’occasion à moins de {{ PRICE }} au Sénégal, avec 100 points d’inspection, 5 jours satisfait ou remboursé, 6 mois de garantie et plus.'
            };
        default:
            return {
                homePageTitle:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDesc:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                homePageTitleOg:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDescOg:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                buyPageTitle:
                    "Voitures à vendre au Côte d'Ivoire | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ci",
                buyPageDesc:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywords:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                buyPageTitleOg:
                    "Voitures à vendre au Côte d'Ivoire | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ci",
                buyPageDescOg:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywordsOg:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ma",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.ma! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywords:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ma",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.ma! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywordsOg:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                aboutPageTitle:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDesc:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywords:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                aboutPageTitleOg:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDescOg:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywordsOg:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ma',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.ma - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ma',
                financePageDescOg:
                    "Explorez un financement automobile sans tracas avec Auto24.ma - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywordsOg:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ma',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ma. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ma',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ma. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ma",
                blogPageDesc:
                    "Visitez Auto24.ma pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywords:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ma",
                blogPageDescOg:
                    "Visitez Auto24.ma pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywordsOg:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} DH avec AUTO24',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}}. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',
                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',
                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',
                carsUnderPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.bj. Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie au Bénin."
            };
    }
};

export default getMetaFrench;
