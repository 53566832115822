import { useState } from 'react';

import { Form, message } from 'antd';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { EnvelopeSimple, SpinnerGap } from 'phosphor-react';

import { useForm } from 'antd/lib/form/Form';
import { $FIXME } from '../../constants';
import { request } from '../../helpers/request';
import ButtonComponent from '../ui/ButtonComponent';
import TextBox from '../ui/TextBox';

interface Props {
    handleActiveModal: (id: number) => void;
    setToken: $FIXME;
    setEmail: $FIXME;
    handleCancel: $FIXME;
    setfromForgetPassword: $FIXME;
}

const ResetPasswordForm = ({ handleActiveModal, handleCancel }: Props) => {
    const [form] = useForm();

    const [formData, setFormData] = useState({
        email: ''
    } as any);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleChange = (key: any, value: any) => {
        setFormData((formData: any) => ({ ...formData, [key]: value }));
    };

    const handleSubmit = () => {
        setLoading(true);
        request
            .getForgetPasswordLink(formData)
            .then((res: AxiosResponse) => {
                message.success(res.data.msg);
                handleCancel();
            })
            .catch((err) => {
                form.setFields([
                    {
                        name: 'email',
                        errors: [err.response.data.msg]
                    }
                ]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleFieldChange = () => {
        const formErrors = form.getFieldsError();
        const buttonDisabledCheck = formErrors.filter(
            (user) => user.errors.length > 0
        );
        setButtonDisabled(buttonDisabledCheck.length > 0);
    };
    const { t } = useTranslation();

    return (
        <div className="reset-form-wrapper">
            <span className="reset-text">
                {t('enterEmailToGetResetLinkInfo')}
            </span>
            <Form
                onFinish={handleSubmit}
                form={form}
                onFieldsChange={handleFieldChange}
                className="reset-ant-form"
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: ''
                        }
                    ]}
                    className={'user-input-box margin overflow-visible'}
                >
                    <TextBox
                        name="email"
                        placeholder={t('enteremailAddress')}
                        type="email"
                        onChange={(data) => handleChange('email', data)}
                        prefix={<EnvelopeSimple weight="bold" />}
                        value={formData.email}
                    />
                </Form.Item>

                <Form.Item>
                    <ButtonComponent
                        type="primary"
                        className={`sign-in-button text-uppercase ${
                            loading ? 'loading-btn' : ''
                        }`}
                        htmlType="submit"
                        disabled={loading || buttonDisabled}
                    >
                        {loading ? (
                            <>
                                <SpinnerGap
                                    size={32}
                                    weight="bold"
                                    className="rotate"
                                />
                                {t('sending')}
                            </>
                        ) : (
                            <>{t('sendResetLink')}</>
                        )}
                    </ButtonComponent>
                </Form.Item>
            </Form>
            <div className="sign-in-redirect">
                {t('returnTo')}{' '}
                <span onClick={() => handleActiveModal(1)}>{t('signIn')}</span>
            </div>
        </div>
    );
};

export default ResetPasswordForm;
