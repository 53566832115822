import { useTranslation } from 'react-i18next';

export const useTranslatedOptions = () => {
    const { t } = useTranslation();

    const fuelTypes = [
        { label: t('carburant'), value: 'carburant', _id: 6 },
        { label: t('diesel'), value: 'diesel', _id: 4 },
        { label: t('electric'), value: 'ev', _id: 1 },
        { label: t('hybrid'), value: 'hybrid', _id: 2 },
        { label: t('petrol'), value: 'petrol', _id: 5 },
        { label: t('phev'), value: 'phev', _id: 3 },
        { label: t('other'), value: 'other', _id: 7 }
    ];

    const transmissionTypes = [
        { label: t('automatic'), value: 'automatic', _id: 2 },
        { label: t('manual'), value: 'manual', _id: 1 },
    ];

    return { fuelTypes, transmissionTypes };
};
